import { useState, useEffect } from "react";
import "./CreateCollectionModal.css";
import closeIcon from "../../icons/close.svg";
import closeIconDark from "../../icons/closeWhite.svg";
import {
	CreateCollection,
	CreateCollectionRequest,
} from "../../utils/backend/Collection/CreateCollection";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setShouldBlockKeyboardNavigation } from "../../store/slice/navigationSlice";

interface NoCollectionModalProps {
	close: Function;
	created: Function;
}

const CreateCollectionModal = ({ close, created }: NoCollectionModalProps) => {
	const [name, setName] = useState<string>("");
	const [error, setError] = useState<string>("");
	const isOpen = useAppSelector(
		(state) => state.navigation.shouldBlockKeyboardNavigation
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setShouldBlockKeyboardNavigation(true));
	}, [isOpen]);

	const handleCreateNewCollection = async (): Promise<string | undefined> => {
		const controller = new AbortController();
		const signal = controller.signal;
		const request: CreateCollectionRequest = {
			name: name,
			signal: signal,
		};

		try {
			const result = await CreateCollection(request);
			if (result.error.length > 0 || !result.collectionId) {
				setError(
					"Something unexpected happened while creating collection, try to reload page or contact us."
				);
				console.error(result.error);
				return;
			} else {
				created();
			}
			return result.collectionId;
		} catch (e) {
			setError(
				"Something unexpected happened while creating collection, try to reload page or contact us."
			);
		}
	};
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			<div className={`${lightMode} no-collections-modal-container`}>
				<div
					className={`${lightMode} no-collection-modal-dimmer`}
					onClick={() => close()}></div>
				<div className={`${lightMode} no-collections-modal`}>
					<div className={`${lightMode} no-collections-modal-header`}>
						New collection{" "}
						<img
							className={`${lightMode} no-collections-modal-close-icon`}
							src={lightMode === "light" ? closeIcon : closeIconDark}
							onClick={() => close()}
						/>
					</div>
					<div className={`${lightMode} no-collections-modal-input-container`}>
						<label className={`${lightMode} no-collection-modal-label`}>
							Name{" "}
						</label>
						<input
							type="text"
							id="name"
							placeholder="Enter your collection name"
							className={`${lightMode} no-collections-modal-input`}
							value={name}
							onChange={(e) => setName(e?.target.value)}
						/>
						{error.length > 0 && (
							<p className={`${lightMode} no-collections-modal-error`}>
								{error}
							</p>
						)}
					</div>
					<div className={`${lightMode} no-collections-modal-button-container`}>
						<button
							className={`${lightMode} no-collections-modal-button-white`}
							onClick={() => close()}>
							Cancel
						</button>
						<button
							className={`${lightMode} no-collections-modal-button-black`}
							onClick={handleCreateNewCollection}>
							Create
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateCollectionModal;
