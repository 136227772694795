import { queryData } from "../../../utils/types/queryData";
import { Flow } from "../../../utils/types/flow";
import {
  FetchFlowsByTags,
  FetchFlowsByTagsRequest,
} from "../../../utils/backend/Flow/FetchFlowsByTags";

interface fetchFlowsInput {
  signal: AbortSignal;
  queryState: queryData;
  activeFilters: string[];
  seed: number;

  setError(err: string): void;

  setFlows: React.Dispatch<React.SetStateAction<Flow[]>>;

  setIsLastPage(isLastPage: boolean): void;
}

export async function fetchFlows({
  activeFilters,
  queryState,
  signal,
  setError,
  setFlows,
  setIsLastPage,
  seed,
}: fetchFlowsInput) {
  try {
    await getFlowsByTags({
      queryState,
      signal,
      setFlows,
      activeFilters,
      setError,
      setIsLastPage,
      seed,
    });
  } catch (e) {
    console.error(e);
    setError(
      "Something unexpected happened, try to reload page or contact us."
    );
  }
}

interface fetchFlowsByTagsInput {
  signal: AbortSignal;
  queryState: queryData;
  activeFilters: string[];
  seed: number;

  setError(err: string): void;

  setFlows: React.Dispatch<React.SetStateAction<Flow[]>>;

  setIsLastPage(isLastPage: boolean): void;
}

async function getFlowsByTags({
  setFlows,
  queryState,
  signal,
  activeFilters,
  setError,
  setIsLastPage,
  seed,
}: fetchFlowsByTagsInput) {
  const req: FetchFlowsByTagsRequest = {
    tags: activeFilters,
    page: queryState.page,
    limit: queryState.limit,
    seed: seed,
    shouldBeRandom: true,
    signal,
  };
  try {
    const result = await FetchFlowsByTags(req);

    if (result.response.length === 0) {
      setIsLastPage(true);
    }

    if (result.error.length > 0) {
      if (result.error === "The user aborted a request.") return;
      setError(
        "Something unexpected happened, try to reload page or contact us."
      );
      console.error(result.error);
      return;
    }
    setFlows((prev) => {
      return [
        ...prev,
        ...result.response.filter((flow) => {
          return !prev.some((prevFlow) => prevFlow.id === flow.id);
        }),
      ];
    });
  } catch (err) {
    console.error(err);
  }
}
