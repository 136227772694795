import { Fetch, FetchInput, Method } from "../../Fetch";
import { Workspace } from "../../types/workspace";
export interface WorkspaceByIdRequest {
  id: String;
  signal?: AbortSignal;
}

export interface WorkspaceByIdResponse {
  response: Workspace;
  error: string;
}

export async function WorkspaceById(
  request: WorkspaceByIdRequest
): Promise<WorkspaceByIdResponse> {
  const WorkspaceByIdURL = `/me/workspace?workspaceId=${request.id}`;

  const empty = {
    id: "",
    image: "",
    industry: "",
    name: "",
    website: "",
    members: [],
  };
  try {
    const input: FetchInput = {
      method: Method.Get,
      url: WorkspaceByIdURL,
      signal: request.signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          response: response.result.result,
          error: "",
        };
      }

      return {
        response: empty,
        error: "Server side error",
      };
    } else {
      return {
        response: empty,
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      response: empty,
      error: "error",
    };
  }
}
