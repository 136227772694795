import { useAppSelector } from "../../../store/hooks";
import {
	UpdateCollection,
	UpdateCollectionRequest,
} from "../../../utils/backend/Collection/UpdateCollection";
import { Collection } from "../../../utils/types/collection";
import { useState } from "react";

interface EditCollectionProps {
	collection: Collection;
	closed: boolean;
	setClosed(newValue: boolean): void;
	onUpdateSuccessfully(newCollection: Collection): void;
}

const EditCollection = ({
	closed,
	setClosed,
	collection,
	onUpdateSuccessfully,
}: EditCollectionProps) => {
	const [name, setName] = useState<string>(collection.name);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const saveEdit = async () => {
		const req: UpdateCollectionRequest = {
			id: collection.id,
			name: name,
		};
		try {
			const result = await UpdateCollection(req);
			if (result.error.length > 0) {
				// TODO: add error message to user screen
				console.error(result.error);
				return;
			}
			onUpdateSuccessfully({
				...collection,
				name: name,
			});
			setClosed(true);
		} catch (e) {
			// TODO: add error message to user screen
			console.error(e);
		}
	};

	if (closed) return null;

	return (
		<div className={`${lightMode} edit-container hide-edit-container`}>
			<div className={`${lightMode} edit-collection`}>
				<h1>Edit collection</h1>
				<h3>
					Allow people to join your board with the link below. Anyone who joins
					with this link will have the Member role..
				</h3>
				<h2>Collection name</h2>
				<input
					type="text"
					placeholder="Enter your collection name"
					className={`${lightMode} new-name`}
					value={collection.name}
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
				<div className={`${lightMode} edit-btns`}>
					<button
						className={`${lightMode} cancel-edit`}
						onClick={() => {
							setClosed(true);
						}}>
						Cancel
					</button>
					<button className={`${lightMode} save-edit`} onClick={saveEdit}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
};
export default EditCollection;
