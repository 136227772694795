import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SetupProfileRequest } from "../../utils/backend/Profile Passwords/FetchSetupProfile";

interface state {
  setupData: SetupProfileRequest;
}

const initialState: state = {
  setupData: JSON.parse(localStorage.getItem("setupData") ?? "{}"),
};

export const setupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    setSetupData: (state, action: PayloadAction<SetupProfileRequest>) => {
      localStorage.setItem("setupData", JSON.stringify(action.payload));
      state.setupData = action.payload;
    },
  },
});

export const { setSetupData } = setupSlice.actions;

export default setupSlice.reducer;
