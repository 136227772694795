import { Collection } from "../../../utils/types/collection";
import { GetAllCollections } from "../../../utils/backend/Collection/GetAllCollections";
import { Screen } from "../../../utils/types/screen";
import {
  FetchScreensByTags,
  FetchScreensByTagsRequest,
} from "../../../utils/backend/Screen/FetchScreensByTags";
import { queryData } from "../../../utils/types/queryData";

interface getAllCollectionsInput {
  setError: (err: string) => void;
  setCollections: (data: Collection[]) => void;
}

export async function getAllCollections({
  setCollections,
  setError,
}: getAllCollectionsInput) {
  const controller = new AbortController();
  const signal = controller.signal;
  try {
    const result = await GetAllCollections(signal);
    if (result.error.length > 0) {
      setError(result.error);
      return;
    }

    setCollections(result.response);
  } catch (e) {
    setError(
      "Something unexpected happened, try to reload page or contact us."
    );
    console.error(e);
  }
}

export async function getScreensByTagList(
  tagIdList: string[],
  setScreens: React.Dispatch<React.SetStateAction<Screen[]>>,
  setError: (msg: string) => void,
  queryState: queryData
): Promise<void> {
  try {
    const fetchScreensByTagsRequest: FetchScreensByTagsRequest = {
      tags: tagIdList,
      page: queryState.page,
      limit: queryState.limit,
      seedValue: 0,
      shouldBeRandom: false,
    };
    const fetchScreensByTagsResponse = await FetchScreensByTags(
      fetchScreensByTagsRequest
    );
    if (fetchScreensByTagsResponse.error.length > 0) {
      if (fetchScreensByTagsResponse.error === "The user aborted a request.") {
        throw new Error("The user aborted a request");
      }
      setError("Unable to get data, try again or contact us");
      return;
    }

    if (queryState.page === 0) {
      setScreens(fetchScreensByTagsResponse.response);
    } else {
      setScreens((prev) => {
        return [...prev, ...fetchScreensByTagsResponse.response];
      });
    }
  } catch (err: any) {
    if (err.message === "The user aborted a request") {
      throw new Error("The user aborted a request");
    }
    console.error(err);
    setError("Unable to get data, try again or contact us");
  }
}
