import Joi from "joi";

export interface Project {
  name?: string | null;
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

const ProjectSchema = Joi.object({
  name: Joi.string().allow(null, "").optional(),
  id: Joi.string().required().uuid(),
}).unknown();

const ProjectSchemaArray = Joi.array().items(ProjectSchema);

export async function toProjects(data: any[]): Promise<Project[]> {
  const validationResult = await ProjectSchemaArray.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return validationResult.value.map((data) => {
    return Object.assign({}, data) as Project;
  });
}
