import Joi from "joi";

export interface Tag {
	id: string;
	tagTypeId: string;
	description?: string | null;
	name: string;
	imageS3URL?: string | null;
	imageData?: string | null;
	noOfScreens?: number | null;
	noOfFlows?: number | null;
	tagList: Tag[];
	website?: string | null;
}

const tagSchema = Joi.object({
	id: Joi.string().required().uuid(),
	tagTypeId: Joi.string().required().uuid(),
	name: Joi.string().required(),
	description: Joi.string().allow(null, "").optional(),
	presignedURL: Joi.string().allow("", null).optional(),
	noOfScreens: Joi.number().allow(null).optional(),
	tagList: Joi.array()
		.items(Joi.link("#tagSchema"))
		.min(0)
		.optional()
		.allow(null),
})
	.id("tagSchema")
	.unknown();

export const tagListScheme = Joi.array().items(tagSchema);

export async function toTag(data: any): Promise<Tag> {
	const validationResult = await tagSchema.validate(data);
	if (validationResult.error) {
		throw new Error(validationResult.error.message);
	}

	return Object.assign({}, data) as Tag;
}

export async function toTags(data: unknown): Promise<Tag[]> {
	const validationResult = await tagListScheme.validate(data);
	if (validationResult.error) {
		throw new Error(validationResult.error.message);
	}

	return validationResult.value.map((data) => {
		return Object.assign({}, data) as Tag;
	});
}
