import "./TestDriveScreen.css";
import Formula from "../../icons/Formula1.svg";
import Close from "../../icons/close.svg";
import { useAppSelector } from "../../store/hooks";

interface TestDriveScreenProps {
	onContinue: () => void;
}

const TestDriveScreen = ({ onContinue }: TestDriveScreenProps) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<div className={`${lightMode} test-drive-container`}>
			<div className={`${lightMode} test-drive-icons`}>
				<img className={`${lightMode} test-drive-car`} src={Formula} />
				<img
					className={`${lightMode} test-drive-cross`}
					src={Close}
					onClick={onContinue}
					style={{
						cursor: "pointer",
					}}
				/>
			</div>
			<div className={`${lightMode} test-drive-header`}>
				You’re on a test drive
			</div>
			<div className={`${lightMode} test-drive-text`}>
				Welcome to the beta version of UXMaps. We’re in the process of
				fine-tuning the app, so if you come across any imperfections, please
				don’t hesitate to let us know. During this phase, your feedback means
				the world to us!
				<br /> <br />
				Best of luck in boosting your UX productivity.
				<br />
				<b className={`${lightMode} text-bolded`}>UXMaps Team</b>
			</div>
			<button className={`${lightMode} drive-test-btn`} onClick={onContinue}>
				Continue
			</button>
		</div>
	);
};

export default TestDriveScreen;
