import { useEffect, useState } from "react";
import "./ProfileSettings.css";
import userIcon from "../../icons/user.svg";
import { GetUser } from "../../utils/backend/Profile/GetUser";
import {
	UpdateUser,
	UpdateUserRequest,
} from "../../utils/backend/Profile/UpdateUser";
import { Profile as ProfileType } from "../../utils/types/profile";
import Sidebar from "../SettingsSideBar/Sidebar";
import { SubmitHandler, useForm } from "react-hook-form";
import { UpdatePassword } from "../../utils/backend/Profile/UpdatePassword";
import { User } from "../../utils/types/user";
import {
	getImageFromCacheOrFromURL,
	imageDataToBase64String,
} from "../../utils/image";
import { setUser } from "../../store/slice/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setImageInLocalStorage } from "../../utils/imagesCache";
import { asyncNotification } from "../../store/slice/notificationSlice";
import ShowIconLight from "../../icons/Show.svg";
import HideIconLight from "../../icons/Hide.svg";
import ShowIconDark from "../../icons/ShowDark.svg";
import HideIconDark from "../../icons/HideDark.svg";
import Loader from "../Loader/Loader";

interface passwordInfoType {
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
}

const passwordLength = 8;

const ProfileSettings = () => {
	const [profile, setProfile] = useState<ProfileType>();
	const dispatch = useAppDispatch();
	const [isLoadingUpdateProfile, setIsLoadingUpdateProfile] = useState(false);
	const [isPasswordVisible, setisPasswordVisible] = useState({
		oldPassword: false,
		newPassword: false,
		confirmPassword: false,
	});
	const lightMode = useAppSelector((state) => state.navigation.lightMode);

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
		reset,
		watch,
		setError,
	} = useForm<passwordInfoType>({
		defaultValues: {
			newPassword: "",
			confirmNewPassword: "",
			oldPassword: "",
		},
	});

	const ShowIcon = ShowIconDark;
	const HideIcon = HideIconDark;

	const newPasswordFieldValue = watch("newPassword");

	useEffect(() => {
		setIsLoadingUpdateProfile(true)
		GetUser()
			.then(async (data) => {
				if (data.error) {
					setError("root", {
						message: data.error,
					});
					return;
				}

				if (!data.user) {
					setError("root", {
						message: data.error,
					});
					return;
				}
				const user: User = data.user;

				if (user.image) {
					const img = await getImageFromCacheOrFromURL(user.image, user.id);
					if (img) {
						user.imageData = img;
					}
				}

				setProfile(user);
				setIsLoadingUpdateProfile(false)
			})
			.catch((e) => {
				setIsLoadingUpdateProfile(false)
				console.error(e);
			});


	}, [setError]);

	if (!profile) return null;

	const loadFile = (event: any) => {
		const image = document.getElementById("output") as HTMLImageElement;
		if (image != null) {
			image.src = URL.createObjectURL(event.target.files[0]);
			setProfile((prevState) => {
				if (!prevState) return;
				return { ...prevState, imageData: image.src };
			});
		}
	};

	const saveChanges = async () => {
		setIsLoadingUpdateProfile(true);
		let imageBase64: string | undefined = undefined;
		if (profile.imageData) {
			imageBase64 = await imageDataToBase64String(profile.imageData);
		}
		const req: UpdateUserRequest = {
			fullName: profile.fullName,
			id: profile.id,
			email: profile.email,
			image: imageBase64,
		};
		UpdateUser(req)
			.then(() => {
				if (profile.imageData)
					setImageInLocalStorage(profile.id, profile.imageData);
				dispatch(setUser(profile));
				showUpdateBtn("Profile updated.");
			})
			.catch((e) => {
				console.error(e);
				setError("root", { message: "" });
			})
			.finally(() => {
				setIsLoadingUpdateProfile(false);
			});
	};

	const showUpdateBtn = (message: string) => {
		dispatch(asyncNotification({ notification: message, isActive: true }));
	};

	const onSubmit: SubmitHandler<passwordInfoType> = async (data) => {
		const controller = new AbortController();
		const signal = controller.signal;
		try {
			const result = await UpdatePassword({
				newPassword: data.newPassword,
				signal,
				oldPassword: data.oldPassword,
			});

			if (result.error) {
				if (result.error === "Unauthorized") {
					setError("root", {
						type: "manual",
						message: "Unable to update password, incorrect current password.",
					});

					return;
				}
				setError("root", {
					type: "manual",
					message: "Unable to update password, try again or contact us.",
				});
			}
			showUpdateBtn("Password updated.");

			reset();
		} catch (e) {
			setError("root", {
				type: "manual",
				message: "Unable to update password, try again or contact us.",
			});
			console.error(e);
		}
	};


	if(isLoadingUpdateProfile){
		return <Loader />
	}

	return (
		<div className={`${lightMode} profile-settings`}>
			<Sidebar />
			{isLoadingUpdateProfile || !profile ? <Loader /> : <div
				style={{
					marginLeft: "20px",
					display: "grid",
					gap: "25px",
					justifyItems: "center",
				}}>
				<div className={`${lightMode} customize-profile-section section`}>
					<div
						className={`${lightMode} intro`}
						style={{ paddingTop: "10px", paddingBottom: "10px" }}>
						<h2>Profile</h2>
						<h3>Manage your UXMaps profile</h3>
					</div>
					<h2 className={`${lightMode} settings-header-photo`}>
						Profile photo
					</h2>
					<div className={`${lightMode} img-details`}>
						<img
							id="output"
							width="40px"
							alt="user-icon"
							src={profile.imageData || userIcon}
						/>
						<input
							type="file"
							accept="image/*"
							name="image"
							id="file"
							hidden
							onChange={(e) => loadFile(e)}
						/>
						<label htmlFor="file" className={`${lightMode} change-img-button`}>
							Change
						</label>
					</div>
					<div className={`${lightMode} inputs-container`}>
						<div className={`${lightMode} one-input`}>
							<label
								htmlFor="name"
								className={`${lightMode} label-class-profile`}>
								Full name
							</label>
							<input
								type="text"
								id="name"
								placeholder="Omnius"
								className={`${lightMode} profile-name-input`}
								onChange={(e) => {
									setProfile((prev) => {
										if (!prev) return;
										return {
											...prev,
											fullName: e.target.value,
										};
									});
								}}
								defaultValue={profile.fullName ?? ""}
							/>
						</div>

						<div className={`${lightMode} one-input`}>
							<label
								htmlFor="email"
								className={`${lightMode} label-class-profile`}>
								Email
							</label>
							<input
								type="email"
								id="email"
								placeholder="Omnius"
								className={`${lightMode} profile-email-input`}
								disabled
								defaultValue={profile.email}
							/>
						</div>
					</div>

					<div className={`${lightMode} save-section-dash`} />
					<div className={`${lightMode} save-button-container`}>
						<button
							className={`${lightMode} save-button`}
							onClick={saveChanges}
							disabled={isLoadingUpdateProfile}>
							{isLoadingUpdateProfile ? "Loading..." : "Update"}
						</button>
					</div>
				</div>

				<div className={`${lightMode} customize-password-section section`}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={`${lightMode} intro`}>
							<h2>Security</h2>
							<h3>Update your password</h3>
						</div>

						<div className={`${lightMode} inputs-container`}>
							<div className={`${lightMode} one-input`}>
								<label
									htmlFor="current-password"
									className={`${lightMode} label-class-profile`}>
									Current password
								</label>
								<div>
									<input
										type={isPasswordVisible.oldPassword ? "text" : "password"}
										autoComplete="on"
										id="current-password"
										placeholder="Current password"
										className={`${lightMode} current-password-input`}
										{...register("oldPassword", {
											required: true,
											validate: (value) => {
												if (value.length < passwordLength) {
													return `Must be at least ${passwordLength} characters long.`;
												}
											},
										})}
									/>
									<img
										src={isPasswordVisible.oldPassword ? HideIcon : ShowIcon}
										onClick={() =>
											setisPasswordVisible({
												oldPassword: !isPasswordVisible.oldPassword,
												newPassword: isPasswordVisible.newPassword,
												confirmPassword: isPasswordVisible.confirmPassword,
											})
										}
										className={`${lightMode} show-hide-image`}
									/>
								</div>
								{errors.oldPassword && (
									<div
										style={{
											color: "red",
											fontSize: "12px",
											marginTop: "5px",
										}}>
										{errors.oldPassword &&
										errors.oldPassword.message &&
										errors.oldPassword.message?.length > 0
											? errors.oldPassword.message
											: errors.oldPassword &&
											  errors.oldPassword.type === "required"
											? "Required"
											: null}
									</div>
								)}
							</div>

							<div className={`${lightMode} one-input`}>
								<label
									htmlFor="new-password"
									className={`${lightMode} label-class-profile`}>
									New password
								</label>
								<div>
									<input
										type={isPasswordVisible.newPassword ? "text" : "password"}
										autoComplete="on"
										id="new-password"
										placeholder="New password"
										className={`${lightMode} current-password-input`}
										{...register("newPassword", {
											required: true,
											validate: (value) => {
												if (value.length < passwordLength) {
													return `Must be at least ${passwordLength} long.`;
												}
											},
										})}
									/>
									<img
										src={isPasswordVisible.newPassword ? HideIcon : ShowIcon}
										onClick={() =>
											setisPasswordVisible({
												oldPassword: isPasswordVisible.oldPassword,
												newPassword: !isPasswordVisible.newPassword,
												confirmPassword: isPasswordVisible.confirmPassword,
											})
										}
										className={`${lightMode} show-hide-image`}
									/>
								</div>
								{errors.newPassword && (
									<div
										style={{
											color: "red",
											fontSize: "12px",
											marginTop: "5px",
										}}>
										{errors.newPassword &&
										errors.newPassword.message &&
										errors.newPassword.message?.length > 0
											? errors.newPassword.message
											: errors.newPassword &&
											  errors.newPassword.type === "required"
											? "Required"
											: null}
									</div>
								)}
							</div>

							<div className={`${lightMode} one-input`}>
								<label
									htmlFor="conf-new-password"
									className={`${lightMode} label-class-profile`}>
									Confirm new password
								</label>
								<div>
									<input
										type={
											isPasswordVisible.confirmPassword ? "text" : "password"
										}
										autoComplete="on"
										id="conf-new-password"
										placeholder="Confirm new password"
										className={`${lightMode} current-password-input`}
										{...register("confirmNewPassword", {
											required: true,
											validate: (value) => {
												if (value.length < passwordLength) {
													return `Must be at least ${passwordLength} long.`;
												}
												if (value !== newPasswordFieldValue) {
													return `Passwords are not matching`;
												}
											},
										})}
									/>
									<img
										src={
											isPasswordVisible.confirmPassword ? HideIcon : ShowIcon
										}
										onClick={() =>
											setisPasswordVisible({
												oldPassword: isPasswordVisible.oldPassword,
												newPassword: isPasswordVisible.newPassword,
												confirmPassword: !isPasswordVisible.confirmPassword,
											})
										}
										className={`${lightMode} show-hide-image`}
									/>
								</div>
								{errors.confirmNewPassword && (
									<div
										style={{
											color: "red",
											fontSize: "12px",
											marginTop: "5px",
										}}>
										{errors.confirmNewPassword &&
										errors.confirmNewPassword.message &&
										errors.confirmNewPassword.message?.length > 0
											? errors.confirmNewPassword.message
											: errors.confirmNewPassword &&
											  errors.confirmNewPassword.type === "required"
											? "Required"
											: null}
									</div>
								)}
								{errors.root && (
									<div
										style={{
											color: "red",
											fontSize: "12px",
											marginTop: "5px",
										}}>
										{errors.root.message}
									</div>
								)}
							</div>
						</div>
						<div className={`${lightMode} save-section-dash`} />
						<div className={`${lightMode} save-button-container`}>
							<button
								className={`${lightMode} save-button`}
								type="submit"
								disabled={isSubmitting}
								style={{ marginBottom: "10px" }}>
								{isSubmitting ? "Loading..." : "Save"}
							</button>
						</div>
					</form>
				</div>

				{/*
        <div className={`${lightMode} my-boards-section section`}>
          <div className={`${lightMode} top-sect`}>
            <div className={`${lightMode} intro`}>
              <h2>My boards</h2>
              <h3>?????????????????????</h3>
            </div>
            <button className={`${lightMode} new-board-btn`}>
              <img src={plus} alt="plus-icon" /> New board
            </button>
          </div>
          <div className={`${lightMode} boards`}>
            <div className={`${lightMode} board`}>
              <div className={`${lightMode} board-left`}>
                <img src={board} alt="board-icon" />
                <div className={`${lightMode} one-board-info`}>
                  <h3>Omnius</h3>
                  <h4>Admin</h4>
                </div>
              </div>
            </div>
            <div className={`${lightMode} board`}>
              <div className={`${lightMode} board-left`}>
                <img src={board} alt="board-icon" />
                <div className={`${lightMode} one-board-info`}>
                  <h3>Omnius</h3>
                  <h4>Admin</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${lightMode} danger-zone-section section`}>
          <div className={`${lightMode} intro`}>
            <h2>Danger zone</h2>
            <h3>Delete boards</h3>
          </div>
          <div className={`${lightMode} top-sect top-sect2`}>
            <div className={`${lightMode} sect-left`}>
              <img src={deleteBoard} alt="delete-board-icon" />
              <div className={`${lightMode} intro intro2`}>
                <h2>Delete account</h2>
                <h3>There’s no going back. Deleting a account is permanent.</h3>
              </div>
            </div>

            <button className={`${lightMode} delete-acc-btn`}>Delete account</button>
          </div>
        </div>
        */}
			</div>}
		</div>
	);
};
export default ProfileSettings;
