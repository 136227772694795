import { Fetch, FetchInput, Method } from "../../Fetch";
import { TagType, toTagTypes } from "../../types/tagType";

export interface FetchAllTagTypesResponse {
  response: TagType[];
  error: string;
}

export async function FetchAllTagTypes(
  signal?: AbortSignal
): Promise<FetchAllTagTypesResponse> {
  const FetchAllTagTypesURL = "/me/tag_type/all";

  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchAllTagTypesURL,
      signal: signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          response: await toTagTypes(response.result.result),
          error: "",
        };
      }

      return {
        response: [],
        error: "Server side error",
      };
    } else {
      return {
        response: [],
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      response: [],
      error: "error",
    };
  }
}
