import { Fetch, FetchInput, Method } from "../../Fetch";

interface SetupNewPasswordInput {
  email: string;
  newPassword: string;
  token: string;
}

export interface SetupNewPasswordResponse {
  error: string;
  isOk: boolean;
}

export const SetupNewPassword = async ({
  newPassword,
  email,
  token,
}: SetupNewPasswordInput): Promise<SetupNewPasswordResponse> => {
  const url = "/public/user/set_new_password";
  const requestBody = JSON.stringify({
    email,
    token,
    newPassword,
  });
  try {
    const input: FetchInput = {
      method: Method.Post,
      url: url,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          isOk: response.isOk,
          error: "",
        };
      }

      return {
        isOk: false,
        error: "Server side error",
      };
    } else {
      return {
        isOk: false,
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      isOk: false,
      error: "error",
    };
  }
};
