import { useEffect, useState } from "react";
import SignInSwitcher from "./SignInSwitcher";
import SignUp from "../SignUp/SignUp";
import SignIn from "./SignIn";
import Logo from "../../icons/favicon.png";
import { useAppSelector } from "../../store/hooks";
import MultiLoginsMessage from "./MultiLoginsMessage";
import { useNavigate, useSearchParams } from "react-router-dom";

const SignPage = () => {
	const [switcherState, setSwitcherState] = useState("log-in");
	const [searchParams] = useSearchParams();
	const [displayMsg, setDisplayMsg] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (searchParams.get("loggedOut") === "true") {
			setDisplayMsg(true);

			if (switcherState === "sign-up") setSwitcherState("log-in");
		} else {
			setDisplayMsg(false);
		}
	}, [searchParams]);

	const lightMode = useAppSelector((state) => state.navigation.lightMode);

	return (
		<div className={`${lightMode} sign-page-container`}>
			{displayMsg && switcherState !== "sign-up" && <MultiLoginsMessage />}
			<img
				src={Logo}
				className={`${lightMode} uxmaps-logo sign-page`}
				alt={"uxmaps-logo"}></img>
			<SignInSwitcher
				setSwitcher={(switchState) => setSwitcherState(switchState)}
				switchState={switcherState}
			/>
			{switcherState === "sign-up" ? (
				<SignUp setSwitcher={(switchState) => setSwitcherState(switchState)} />
			) : (
				<SignIn />
			)}
		</div>
	);
};

export default SignPage;
