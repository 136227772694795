import React, { useCallback, useEffect, useState } from "react";
import "./SignIn.css";
import Logo from "../../icons/favicon.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetUser } from "../../utils/backend/Profile/GetUser";
import { SubmitHandler, useForm } from "react-hook-form";
import { FetchSignIn } from "../../utils/backend/SignIn SignUp/FetchSignIn";
import { isValidEmail } from "../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setProjects, setWorkspaces } from "../../store/slice/workspacesSlice";
import { FetchAllTagTypesWithTags } from "../../utils/backend/TagType/AllWithTags";
import { setAllTagTypesWithTags } from "../../store/slice/tagSlice";
import ShowIcon from "../../icons/Show.svg";
import HideIcon from "../../icons/Hide.svg";
import { setRememberMeAction } from "../../store/slice/authSlice";
import MultiLoginsMessage from "./MultiLoginsMessage";

interface signInData {
	email: string;
	password: string;
}

function SignIn() {
	const [isPasswordVisible, setisPasswordVisible] = useState(false);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [rememberPassword, setRememberPassword] = useState<boolean>(true);

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
		setError,
		getValues,
	} = useForm<signInData>();

	useEffect(() => {
		if (searchParams.get("loggedOut") === "true") {
		}
	}, [searchParams, setError]);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const currentUserToken = localStorage.getItem("token");
		if (!currentUserToken) {
			return;
		}
		const handleGetUser = async function () {
			const result = await GetUser();
			if (result.error.length > 0) {
				setError("root", {
					message:
						"Something unexpected happened, try to reload page or contact us.",
				});
				console.error(result.error);
				return;
			}

			if (result.user) {
				navigate("/Navigation/Screens");
			}
		};

		handleGetUser().catch((e) => {
			setError("root", {
				message:
					"Something unexpected happened, try to reload page or contact us.",
			});
			console.error(e);
		});
	}, [navigate, setError]);

	const handleGetAllDataForDataInitialization = async (): Promise<boolean> => {
		try {
			const result = await FetchAllTagTypesWithTags();
			if (result.error) {
				setError("root", {
					message: "Unable to sign you in, please try again or contact us.",
				});
				console.error(result.error);
				return false;
			}

			dispatch(setAllTagTypesWithTags(result.response));
			dispatch(setProjects([]));
			dispatch(setWorkspaces([]));
			return true;
		} catch (e) {
			setError("root", {
				message: "Unable to sign you in, please try again or contact us.",
			});
			console.error(e);
			return false;
		}
	};
	const onSubmit: SubmitHandler<signInData> = useCallback(
		async (data) => {
			if (rememberPassword) {
				localStorage.setItem("remember", getValues("password"));
			} else if (!rememberPassword && localStorage.getItem("remember")) {
				localStorage.removeItem("remember");
			}
			try {
				const response = await FetchSignIn({
					Email: data.email,
					Password: data.password,
				});

				if (response.token) {
					localStorage.setItem("token", JSON.stringify(response));
					if (await handleGetAllDataForDataInitialization()) {
						navigate("/Navigation/Screens");
					} else {
						localStorage.removeItem("token");
					}

					return;
				}

				if (response.error) {
					if (response.error === "Unauthorized") {
						setError("root", {
							message: "Invalid email or password",
						});
						return;
					}

					setError("root", {
						message:
							"Something unexpected happened, try to reload page or contact us.",
					});
				}
			} catch (e) {
				setError("root", {
					message: "Invalid email or password",
				});
				console.error(e);
			}
		},

		[navigate, setError, rememberPassword]
	);

	useEffect(() => {
		function handleLogIn(ev: KeyboardEvent) {
			if (ev.key === "Enter") {
				handleSubmit(onSubmit);
			}
		}

		window.addEventListener("keypress", handleLogIn);

		return () => {
			window.removeEventListener("keypress", handleLogIn);
		};
	}, [handleSubmit, onSubmit]);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<div className={`${lightMode} sign-page`}>
			<div className={`${lightMode} sign-container`}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={`${lightMode} details`}>
						<label htmlFor="email" className={`${lightMode} label`}>
							Email
						</label>
						<input
							type="email"
							id="email"
							className={`${lightMode} email sign-in-input`}
							style={
								!errors.email ? { marginBottom: "20px" } : { marginBottom: "0" }
							}
							placeholder="Enter your email"
							{...register("email", { required: true, validate: isValidEmail })}
						/>
						{errors.email && (
							<div
								style={{
									color: "red",
									fontSize: "12px",
									marginTop: "5px",
									marginBottom: "20px",
									fontWeight: 500,
								}}>
								{errors.email.message && errors.email.message?.length > 0
									? errors.email.message
									: errors.email && errors.email.type === "required"
									? "Required"
									: errors.email && errors.email.type === "validate"
									? "Not valid email"
									: null}
							</div>
						)}
						<label
							htmlFor="password"
							className={`${lightMode} label pass-label`}>
							Password
						</label>
						<div>
							<input
								autoComplete="on"
								type={isPasswordVisible ? "text" : "password"}
								id="password"
								className={`${lightMode} log-password sign-in-input`}
								placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
								{...register("password", {
									required: true,
									value:
										localStorage.getItem("remember") !== undefined
											? localStorage.getItem("remember") || undefined
											: getValues("password")
											? getValues("password")
											: undefined,
								})}
							/>
							<img
								src={isPasswordVisible ? HideIcon : ShowIcon}
								onClick={() => setisPasswordVisible(!isPasswordVisible)}
								className={`${lightMode} show-hide-image-signin`}
							/>
						</div>
						{errors.password && (
							<div
								style={{
									color: "red",
									fontSize: "12px",
									marginTop: "5px",
									fontWeight: 500,
								}}>
								Required
							</div>
						)}
					</div>

					{errors.root && errors.root.message && (
						<h2 className={`${lightMode} log-error`}>
							{errors.root.message ? errors.root.message : null}
						</h2>
					)}

					<div>
						<button
							className={`${lightMode} sign-in-btn`}
							disabled={isSubmitting}
							type="submit"
							style={{ marginTop: "24px" }}>
							{isSubmitting ? "Loading..." : "Sign in"}
						</button>
						{/*

            <button className={`${lightMode} sign-in-google-btn`}>
              <img src={GoogleIcon} className={`${lightMode} google-icon`} alt={"google"} />
              Sign in with Google
            </button>
            */}

						<div className={`${lightMode} under-passwordd`}>
							<div className={`${lightMode} remember-password-container`}>
								<input
									checked={rememberPassword}
									className={`${lightMode} remember-password-checkbox`}
									onChange={() => setRememberPassword((prev) => !prev)}
									type="checkbox"
								/>
								<h2 className={`${lightMode} remember-password-title`}>
									Remember password
								</h2>
							</div>
							<h2
								className={`${lightMode} forgot-pass`}
								onClick={() => {
									navigate("/SignIn/ForgotPassword");
								}}>
								Forgot password?
							</h2>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default SignIn;
