import { Fetch, FetchInput, Method } from "../../Fetch";

export interface UpdateUserRequest {
  id: string;
  fullName?: string | null;
  image: any;
  email: string;
  signal?: AbortSignal;
}

export interface UpdateUserResponse {
  error: string;
}

export async function UpdateUser(
  request: UpdateUserRequest
): Promise<UpdateUserResponse> {
  const UpdateUserURL = "/me/profile";
  const requestBody = JSON.stringify({
    id: request.id,
    fullName: request.fullName,
    image: request.image,
    email: request.email,
  });
  try {
    const input: FetchInput = {
      method: Method.Put,
      url: UpdateUserURL,
      signal: request.signal,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          error: "",
        };
      }

      return {
        error: "Server side error",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
    };
  }
}
