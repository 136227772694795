import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

interface StyledDynamicIslandProps {
	isOpen: boolean;
	widthToReduce: number;
}

interface StyledSearchSpanProps {
	isSearching: boolean;
}

interface StyledCompanyElementProps {
	focused: boolean;
}

interface StyledSearchElementProps {
	focused: boolean;
}

export const StyledBackImage = styled.img``;

export const StyledBackButtonContainer = styled.button`
	position: fixed;
	height: 36px;
	width: 36px;
	left: -48px;
	color: black;
	border-radius: 20px;
	border: 0.8px solid;
	border-image-source: linear-gradient(
		180deg,
		#ffffff 0%,
		rgba(227, 227, 227, 0) 100%
	);
	background: rgba(246, 247, 249, 0.9);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	backdropfilter: "blur(10px)";
`;

export const StyledDynamicIsland = styled.div<StyledDynamicIslandProps>`
	align-items: ${({ isOpen }) => (isOpen ? "flex-start" : "center")};
	border-radius: 22px;
	background-color: rgba(1, 1, 1, 0.8);
	backdrop-filter: blur(10px);
	box-shadow: 0px 10px 20.886507034301758px 0px rgba(0, 0, 0, 0.04),
		0px 0px 13.924337387084961px 0px rgba(0, 0, 0, 0.15),
		0.3481084406375885px 0.3481084406375885px 0.696216881275177px 0px
			rgba(120, 121, 122, 1) inset,
		-0.3481084406375885px -0.3481084406375885px 0.696216881275177px 0px
			rgba(58, 58, 58, 1) inset;
	color: #fff;
	backgroundcolor: rgba(1, 1, 1, 0.8);
	position: fixed;
	backdropfilter: blur(10px);
	width: 96px;
	width: fit-content;
	font-size: 12px;
	line-height: 24px;
	display: grid;
	cursor: pointer;
	z-index: 1000;
	${({ isOpen }) =>
		isOpen &&
		css`
			grid-template-rows: 75px 36px 40px;
		`}
`;
interface StyledDynamicIslandTopContentProps {
	isOpen: boolean;
	isSearching: boolean;
}
export const StyledDynamicIslandTopContent = styled.div<StyledDynamicIslandTopContentProps>`
	align-items: ${({ isOpen, isSearching }) =>
		isOpen || isSearching ? "flex-start" : "center"};
	justify-content: center;
	flex-direction: ${({ isSearching }) => (isSearching ? "column" : "row")};
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-evenly;
`;

export const StyledSpan = styled.span`
	display: inline-flex;
	gap: 7px;
	padding: 0 5px 0 5px;
	width: fit-content;
	color: rgb(255, 255, 255);
	justify-content: space-around;
	-webkit-box-align: center;
	align-items: center;
	font-size: 12px;
	&:hover {
		background: rgba(158, 163, 174, 0.2);
		border-radius: 33px;
		height: min-content;
	}
`;

export const StyledHotkey = styled.span`
	height: 15px;
	background: #66708540;
	align-self: initial;
	text-align: center;
	padding: 0 5px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	border-radius: 2px;
	align-items: center;
`;

export const StyledSearchInput = styled.input`
	background-color: inherit;
	border: 0;
	font-size: 12px;
	margin: 0;
	height: 36px;
	color: #fff;
	padding-left: 6px;
	&:focus {
		outline: none;
	}
	::placeholder,
	::-webkit-input-placeholder {
		color: #858585;
	}
	:-ms-input-placeholder {
		color: #858585;
	}
`;

export const StyledSearchSpan = styled.span<StyledSearchSpanProps>`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	padding: 0 12px 0 20px;
	padding: ${({ isSearching }) =>
		isSearching ? "0 5px 0 20px" : "0 12px 0 20px"};
	width: 94%;
	color: #fff;
	margin: 0;
	border-bottom: ${({ isSearching }) =>
		isSearching ? "0.5px solid #54545899" : ""};
`;

export const StyledDivWhenSearching = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: flex-start;
	width: 94%;
	padding-left: 10px;
	font-size: 11px;
	font-family: Inter;
	margin-top: 5px;
`;

export const StyledElementsSpan = styled.span`
	display: inline;
	font-size: 12px;
`;

export const StyledElementsContainer = styled.div`
	margin-top: 8px;
	display: inline-flex;
	gap: 12px;
`;

export const StyledElementIcons = styled(FontAwesomeIcon)`
	font-size: 17px;
`;

export const StyledElementsLink = styled(Link)`
	display: inline-grid;
	justify-items: center;
	width: 82px;
	height: 92px;
	border-radius: 7px;
	background: rgba(128, 128, 128, 0.3);
	background-blend-mode: luminosity;
	text-align: center;
	border-radius: 8px;
	color: white;
	align-items: center;
	min-height: 90px;
	border-top: 0.6px solid #8080804d;
	border-left: 0.6px solid #8080804d;

	&:hover {
		outline: 1px solid #808080;
	}
`;

export const StyledImageHolder = styled.span`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 44px;
	height: 44px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	color: var(--text-primary, rgba(255, 255, 255, 0.96));
	text-align: center;
	font-family: SF Pro;
	font-size: 19px;
	font-style: normal;
	font-weight: 510;
	line-height: 22px;
	background: #666767;
	border-radius: 50%;
`;

export const StyledElementsHeader = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	padding-top: 5px;
`;

export const StyledSettingsLink = styled(Link)`
	display: inline-grid;
	justify-items: center;
	height: 93px;
	width: 126px;
	background-color: inherit;
	text-align: center;
	border-radius: 8px;
	background: rgba(128, 128, 128, 0.3);
	color: white;
	align-items: end;

	&:hover {
		outline: 1px solid #808080;
	}
`;

export const StyledSearchContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1px;
`;

export const StyledSearchHeader = styled.div`
	font-family: Inter;
	font-size: 10px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #fff;
	padding-left: 10px;
`;

export const StyledSearchElement = styled.div<StyledSearchElementProps>`
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
	color: #ffffff;
	padding-left: 10px;
	color: rgb(255, 255, 255);
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 7px;
	background-color: ${({ focused }) => (focused ? "#5E5E5E2E" : "")};

	&:hover {
		background-color: #5e5e5e2e;
	}
`;
export const StyledSearchCommands = styled.div`
	position: absolute;
	height: 20px;
	bottom: 5px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	right: 15px;
`;

export const StyledSearchElementSubDiv = styled.div`
	font-family: Inter;
	font-size: 10px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: rgb(150 150 150);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: elipsis;
`;

export const StyledSearchCompanyImage = styled.img`
	width: 28px;
	height: 28px;
	border-radius: 5px;
`;

export const StyledCompanyElement = styled.span<StyledCompanyElementProps>`
	margin-top: 0.3rem;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
	color: #ffffff;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	border-radius: 7px;
	background-color: ${({ focused }) => (focused ? "#5E5E5E2E" : "")};

	&:hover {
		background-color: #5e5e5e2e;
	}
`;

export const StyledSeeMoreDesigins = styled.i`
	color: #007aff;
	font-size: 12px;
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	height: auto !important;
	width: auto !important;
	padding-left: 10px;
	&:hover {
		color: rgb(24, 133, 253);
	}
`;
export const StyledSeeMoreIndustries = styled.i`
	margin-top: 1px;
	color: #007aff;
	font-size: 12px;
	font-family: Inter;
	padding-left: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	height: auto !important;
	width: auto !important;
	&:hover {
		color: rgb(24, 133, 253);
	}
`;

export const StyledSettingsImageHolder = styled.span`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 44px;
	height: 44px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	color: var(--text-primary, rgba(255, 255, 255, 0.96));
	text-align: center;
	font-family: SF Pro;
	font-size: 19px;
	font-style: normal;
	font-weight: 510;
	line-height: 22px;
	background: #666767;
	border-radius: 50%;
	margin-top: 10px;
`;
