import { Fetch, FetchInput, Method } from "../../Fetch";
import { Tag, toTags } from "../../types/tag";

export interface FetchTagsResponse {
  response: Tag[];
  error: string;
}

export async function FetchTags(
  signal?: AbortSignal
): Promise<FetchTagsResponse> {
  const FetchTagsURL = `/me/tag/search?searchParam=`;

  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchTagsURL,
      signal: signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          response: await toTags(response.result.result),
          error: "",
        };
      }

      return {
        response: [],
        error: "Server side error",
      };
    } else {
      return {
        response: [],
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      response: [],
      error: "error",
    };
  }
}
