import PlusCircle from "../../icons/PlusCircle.svg";
import PlusCircleDark from "../../icons/PlusCircleDark.svg";
import close from "../../icons/close.svg";
import closeDark from "../../icons/closeWhite.svg";
import { useState } from "react";
import "./PreviewScreenDropdown.css";
import { Collection } from "../../utils/types/collection";
import {
	CreateCollection,
	CreateCollectionRequest,
} from "../../utils/backend/Collection/CreateCollection";
import {
	AddToCollection,
	AddToCollectionRequest,
} from "../../utils/backend/Collection/AddToCollection";
import { asyncNotification } from "../../store/slice/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface PreviewScreenProps {
	collectionList: Collection[];
	screenId: string;
	setNewCollectionList: (collections: Collection[]) => void;
	closeCollectionDropdown: Function;
}

const PreviewScreenDropdown = ({
	collectionList,
	screenId,
	setNewCollectionList,
	closeCollectionDropdown,
}: PreviewScreenProps) => {
	const dispatch = useAppDispatch();
	const [collectionName, setCollectionName] = useState("");
	const [isCreatingCollection, setIsCreatingCollection] = useState(false);

	const createNewCollection = () => {
		const req: CreateCollectionRequest = {
			name: collectionName,
		};
		CreateCollection(req).then((data) => {
			if (!data.collectionId) return;
			dispatch(
				asyncNotification({
					notification: "New collection is created and screen saved to it.",
					isActive: true,
				})
			);
			const collectionId = data.collectionId;
			const req: AddToCollectionRequest = {
				contentIdList: [screenId],
				collectionId: collectionId,
			};
			AddToCollection(req)
				.then(() => {
					const newCollectionList = [
						...collectionList,
						{
							name: collectionName,
							id: data.collectionId ?? "",
							contentList: [
								{
									id: screenId,
									tagList: [],
									collectionIdList: [],
								},
							],
						},
					];
					setNewCollectionList(newCollectionList);
				})
				.catch((err) => console.error(err));
		});
	};

	const addToCollection = (collectionId: string) => {
		if (
			collectionList
				.find((coll) => coll.id === collectionId)
				?.contentList.find((screen) => screen.id === screenId)
		) {
			dispatch(
				asyncNotification({
					notification: "Saved to the collection.",
					isActive: true,
				})
			);
			closeCollectionDropdown();
			return;
		}

		const req: AddToCollectionRequest = {
			contentIdList: [screenId],
			collectionId: collectionId,
		};
		AddToCollection(req)
			.then(() => {
				closeCollectionDropdown();
				dispatch(
					asyncNotification({
						notification: "Saved to the collection.",
						isActive: true,
					})
				);
			})
			.catch((err) => console.error(err));
	};
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			<div className={`${lightMode} preview-screen-dropdown-container`}>
				<div className={`${lightMode} preview-screen-dropdown-header`}>
					{collectionList.length === 0 ? "New collection" : "Collections"}
					{!isCreatingCollection ? (
						<img
							className={`${lightMode} plus-circle-icon`}
							onClick={() => setIsCreatingCollection(true)}
							src={lightMode === "light" ? PlusCircle : PlusCircleDark}
						/>
					) : (
						<img
							onClick={() => closeCollectionDropdown()}
							className={`${lightMode} close-circle-icon`}
							src={lightMode === "light" ? close : closeDark}
						/>
					)}
				</div>
				<div className={`${lightMode} preview-screen-dropdown-collections`}>
					{isCreatingCollection && (
						<>
							<div className={`${lightMode} create-new-colletion-container`}>
								<input
									className={`${lightMode} preview-screen-dropdown-input`}
									placeholder="Enter collection name"
									type="text"
									value={collectionName}
									onChange={(e) => setCollectionName(e.target.value)}
								/>{" "}
								<button
									onClick={() => createNewCollection()}
									className={`${lightMode} preview-screen-dropdown-create-collection`}>
									Create collection
								</button>
							</div>
						</>
					)}

					{collectionList.length > 0 && !isCreatingCollection && (
						<>
							{collectionList.map((coll) => {
								return (
									<div
										key={coll.id}
										onClick={() => addToCollection(coll.id)}
										className={`${lightMode} preview-screen-collection-menu-item`}>
										{coll.name}
									</div>
								);
							})}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default PreviewScreenDropdown;
