import { FetchTagType, FetchTagTypeRequest } from "../TagType/FetchTagType";
import { FetchTagsByTagType } from "../Tag/FetchTagsByTagType";
import { Tag } from "../../types/tag";

export enum TagTypeType {
  TagTypeCompany = "Company",
  TagTypeDesignElements = "Design elements",
  TagTypeIndustry = "Industry",
}

export interface CompanyTagsResponse {
  tags: Tag[];
  error: string;
}

export async function TagByTagType(
  tagTypeType: TagTypeType,
  signal?: AbortSignal
): Promise<CompanyTagsResponse> {
  const FetchTagTypeReq: FetchTagTypeRequest = {
    tagType: tagTypeType.toString(),
    signal,
  };

  try {
    const tagTypeResult = await FetchTagType(FetchTagTypeReq);
    if (tagTypeResult.error.length > 0) {
      return {
        tags: [],
        error: tagTypeResult.error,
      };
    }

    const companyTagType = tagTypeResult.response;
    if (!companyTagType) {
      return {
        tags: [],
        error: "no company tag types found",
      };
    }
    const companiesTags = await FetchTagsByTagType({
      tagType: companyTagType.id,
      signal: signal,
    });

    if (companiesTags.error.length > 0) {
      return {
        tags: [],
        error: companiesTags.error,
      };
    }

    return {
      tags: companiesTags.response,
      error: "",
    };
  } catch (err) {
    console.error(err);
    return {
      tags: [],
      error: "error",
    };
  }
}
