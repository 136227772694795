import { Fetch, FetchInput, Method } from "../../Fetch";

export interface VerificationRequest {
  email: string;
  token: string;
  password?: string;
  signal?: AbortSignal;
}

export interface VerificationResponse {
  error: string;
  isOk: boolean;
}

export async function FetchVerification(
  request: VerificationRequest
): Promise<VerificationResponse> {
  const VerificationURL = "/public/user/verify";
  const requestBody = JSON.stringify(request);
  try {
    const input: FetchInput = {
      method: Method.Post,
      url: VerificationURL,
      signal: request.signal,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          isOk: response.isOk,
          error: "",
        };
      }

      return {
        isOk: false,
        error: "Server side error",
      };
    } else {
      if (result.error?.code === 409) {
        return {
          isOk: false,
          error: "Already verified",
        };
      }
      return {
        isOk: false,
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      isOk: false,
      error: "error",
    };
  }
}
