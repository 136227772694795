import "./BoardSettings.css";
import { useEffect, useState } from "react";
import {
	UpdateWorkspace,
	UpdateWorkspaceRequest,
} from "../../utils/backend/Workspace/UpdateWorkspace";
import {
	WorkspaceById,
	WorkspaceByIdRequest,
} from "../../utils/backend/Workspace/WorkspaceById";
import { Workspace } from "../../utils/types/workspace";
import Sidebar from "../SettingsSideBar/Sidebar";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
	setSelectedBoard,
	setWorkspaces,
} from "../../store/slice/workspacesSlice";
import {
	getImageFromCacheOrFromURL,
	imageDataToBase64String,
} from "../../utils/image";
import userIcon from "../../icons/user.svg";
import { setImageInLocalStorage } from "../../utils/imagesCache";
import { asyncNotification } from "../../store/slice/notificationSlice";
import ErrorPage from "../ErrorPage/ErrorPage";
import InvitePeople from "./InvitePeople";
import Team from "./Team/Team";
import Loader from "../Loader/Loader";

const BoardSettings = () => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const boardId = useAppSelector((state) => state.navigation.boardId);
	const [workspace, setWorkspace] = useState<Workspace>();
	const [error, setError] = useState<string>();
	const dispatch = useAppDispatch();
	const allBoards = useAppSelector((state) => state.boards.workspaces);
	const selectedBoard = useAppSelector((state) => state.boards.selectedBoard);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		const init = async function () {
			try {
				setIsLoading(true)
				const req: WorkspaceByIdRequest = {
					id: boardId,
					signal,
				};
				const result = await WorkspaceById(req);
				if (result.error.length > 0) {
					setError(
						"Something unexpected happened, try to reload page or contact us."
					);
					console.error(result.error);
					return;
				}

				if (result.response.image) {
					const img = await getImageFromCacheOrFromURL(
						result.response.image,
						result.response.id
					);
					if (img) {
						result.response.imageData = img;
					}
				}

				setWorkspace(result.response);
				setIsLoading(false)
			} catch (e) {
				setIsLoading(false)
				setError(
					"Something unexpected happened, try to reload page or contact us."
				);
				console.error(e);
			}
		};

		init().catch((e) => {
			setError(
				"Something unexpected happened, try to reload page or contact us."
			);
			console.error(e);
		});
	}, [boardId]);

	const showUpdateBtn = () => {
		dispatch(
			asyncNotification({ notification: "Board updated.", isActive: true })
		);
	};

	if (!workspace) {
		return null;
	}

	const saveChanges = async () => {
		setIsLoading(true);
		let imageBase64: string | undefined = undefined;
		if (workspace.imageData) {
			imageBase64 = await imageDataToBase64String(workspace.imageData);
		}

		const UpdateWorkspaceReq: UpdateWorkspaceRequest = {
			id: workspace.id,
			name: workspace.name,
			image: imageBase64,
			website: workspace.website,
		};

		try {
			const result = await UpdateWorkspace(UpdateWorkspaceReq);
			if (result.error.length > 0) {
				setError(
					"Something unexpected happened, try to reload page or contact us."
				);
				console.error(result.error);
				return;
			}
			showUpdateBtn();

			dispatch(
				setWorkspaces(
					allBoards.map((b) => {
						if (b.id !== workspace.id) return b;
						return {
							...b,
							name: workspace.name,
							imageData: workspace.imageData,
							website: workspace.website,
						};
					})
				)
			);

			if (selectedBoard && selectedBoard.id === workspace.id) {
				dispatch(
					setSelectedBoard({
						...selectedBoard,
						name: workspace.name,
						imageData: workspace.imageData,
						website: workspace.website,
					})
				);
			}

			if (workspace.imageData)
				setImageInLocalStorage(workspace.id, workspace.imageData);
		} catch (e) {
			setError(
				"Something unexpected happened, try to reload page or contact us."
			);
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	const loadFile = async (event: any) => {
		const image = document.getElementById("output") as HTMLImageElement;
		const newOmg = URL.createObjectURL(event.target.files[0]);
		image.src = newOmg;
		setWorkspace((prevState) => {
			if (!prevState) return prevState;
			return { ...prevState, imageData: newOmg };
		});
	};

	if (error && error.length > 0) {
		return <ErrorPage />;
	}

	if(isLoading) return <Loader />
	return (
		<>
		<div className={`${lightMode} board-settings`}>
			<Sidebar />
			<div style={{ marginLeft: "20px", display: "grid", gap: "25px" }}>
				<div className={`${lightMode} customize-board-section`}>
					<div className={`${lightMode} intro`}>
						<h2>Customize board</h2>
						<h3>Customize how you appear to your teammates.</h3>
					</div>
					<h2 className={`${lightMode} settings-header-photo`}>Board photo</h2>
					<div className={`${lightMode} img-details`}>
						<img
							id="output"
							width="40px"
							alt="user-icon"
							src={workspace.imageData ?? userIcon}
						/>
						<input
							type="file"
							accept="image/*"
							name="image"
							id="file"
							hidden
							onChange={(e) => loadFile(e)}
						/>
						<label htmlFor="file" className={`${lightMode} change-img-button`}>
							Change
						</label>
					</div>
					<label htmlFor="name" className={`${lightMode} label-class`}>
						Name
					</label>
					<input
						type="text"
						id="name"
						placeholder="Omnius"
						className={`${lightMode} board-name-input`}
						onChange={(e) => {
							setWorkspace((prev) => {
								if (!prev) return undefined;
								return {
									...prev,
									name: e.target.value,
								};
							});
						}}
						value={workspace.name}
					/>
					<h4 className={`${lightMode} just-another-random-div`}>
						Names can be up to 32 characters
					</h4>
					<label htmlFor="url" className={`${lightMode} label-class`}>
						URL slug
					</label>
					<div className={`${lightMode} website-input-container`}>
						<h3 className={`${lightMode} left-part`}>uxmaps.app/</h3>
						<input
							type="text"
							id="url"
							className={`${lightMode} right-part`}
							placeholder="omnius2022"
							onChange={(e) => {
								setWorkspace((prev) => {
									if (!prev) return undefined;
									return {
										...prev,
										website: e.target.value,
									};
								});
							}}
							defaultValue={workspace.website}
						/>
					</div>
					<div className={`${lightMode} save-section-dash`}></div>
					<div className={`${lightMode} save-button-container`}>
						<button
							className={`${lightMode} save-button`}
							onClick={saveChanges}
							disabled={isLoading}
							style={{ marginBottom: "10px" }}>
							{!isLoading ? "Save" : "Loading..."}
						</button>
					</div>
				</div>

				{/* <InvitePeople boardId={workspace.id} />
				{workspace.members && <Team members={workspace.members} />} */}
			</div>
		</div>
		</>
	);
};
export default BoardSettings;
