import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActiveFilters } from "../../store/slice/filtersSlice";

import { useEffect, useState } from "react";
import { TagTypeWithTags } from "../../utils/types/tagType";

interface SignSwitcherProps {
	setSwitcher: (switchState: string) => void;
	switchState: string;
}

const SignInSwitcher = ({ setSwitcher, switchState }: SignSwitcherProps) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);

	return (
		<div className={`${lightMode} switcher-container-sign-page`}>
			<button
				id="sign-up"
				onClick={() => setSwitcher("sign-up")}
				className={
					switchState === "sign-up"
						? "switcher-all-screens-sign-page active"
						: "switcher-all-screens-sign-page"
				}>
				Sign Up
			</button>
			<button
				id="log-in"
				onClick={() => setSwitcher("log-in")}
				className={
					switchState === "log-in"
						? "switcher-all-screens-sign-page active"
						: "switcher-all-screens-sign-page"
				}>
				Log In
			</button>
		</div>
	);
};

export default SignInSwitcher;
