import Joi from "joi";
import { Tag, tagListScheme } from "./tag";

export interface TagType {
  id: string;
  description?: string | null;
  name: string;
}

export interface TagTypeWithTags {
  tagType: TagType;
  tagList: Tag[];
}

const tagTypeSchema = Joi.object({
  id: Joi.string().required().uuid(),
  name: Joi.string().required(),
  description: Joi.string().allow(null, "").optional(),
}).unknown();

const tagTypeWithTagsSchema = Joi.object({
  tagType: tagTypeSchema,
  tagList: tagListScheme,
}).unknown();

const tagTypeWithTagsListSchema = Joi.array().items(tagTypeWithTagsSchema);

export const tagTypeListScheme = Joi.array().items(tagTypeSchema);

export async function toTagType(data: any): Promise<TagType> {
  const validationResult = await tagTypeSchema.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return Object.assign({}, data) as TagType;
}

export async function toTagTypes(data: unknown): Promise<TagType[]> {
  const validationResult = await tagTypeListScheme.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return validationResult.value.map((data) => {
    return Object.assign({}, data) as TagType;
  });
}

export async function toTagTypesWithTags(
  data: unknown
): Promise<TagTypeWithTags[]> {
  const validationResult = await tagTypeWithTagsListSchema.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return validationResult.value.map((data) => {
    return Object.assign({}, data) as TagTypeWithTags;
  });
}
