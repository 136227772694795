import { Fetch, FetchInput, Method } from "../../Fetch";

export interface UpdateWorkspaceRequest {
  id: string;
  name?: string;
  image?: string;
  website?: string;
  signal?: AbortSignal;
}

export interface UpdateWorkspaceResponse {
  error: string;
}

export async function UpdateWorkspace(
  request: UpdateWorkspaceRequest
): Promise<UpdateWorkspaceResponse> {
  const UpdateWorkspaceURL = "/me/workspace";
  const requestBody = JSON.stringify({
    id: request.id,
    name: request.name,
    image: request.image,
    website: request.website,
  });

  try {
    const input: FetchInput = {
      method: Method.Put,
      url: UpdateWorkspaceURL,
      signal: request.signal,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;
      if (response) {
        return {
          error: "",
        };
      }

      return {
        error: "Server side error",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
    };
  }
}
