import { Fetch, FetchInput, Method } from "../../Fetch";

interface AddScreenViewInput {
  screenId: string;
}

export async function AddScreenView(input: AddScreenViewInput) {
  const url = "/me/screen/view";

  const request: FetchInput = {
    method: Method.Post,
    url: url,
    body: JSON.stringify(input),
  };

  // No need for better error handling, since we will call it asynchronously and if it fails no big deal
  try {
    const result = await Fetch(request);

    if (!result.isOk) {
      console.error(result.error);
    }
  } catch (e) {
    console.error(e);
  }
}
