import { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import warningIcon from "../../icons/WarningIcon.svg";

const MultiLoginsMessage = () => {
	const env = process.env.NODE_ENV;
	const [switcherState, setSwitcherState] = useState(
		env !== "development" ? "log-in" : "sign-up"
	);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);

	return (
		<div className={`${lightMode} sign-in-multi-log-msg-container`}>
			<img src={warningIcon} />
			<p>
				You’ve been logged out because this{" "}
				<b>account is used on more than one device or browser.</b> To avoid
				this, log in with one device or browser at a time.
			</p>
		</div>
	);
};

export default MultiLoginsMessage;
