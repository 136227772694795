import { Screen } from "../../../utils/types/screen";
import {
  FetchScreensByTagListGroup,
  FetchScreensByTagListGroupRequest,
} from "../../../utils/backend/Screen/FetchScreensByTagListGroup";

export const getCompaniesDataForDisplay = async (
  companyIdList: string[],
  signal?: AbortSignal
): Promise<Map<string, Screen[]>> => {
  const req: FetchScreensByTagListGroupRequest = {
    signal: signal,
    tags: companyIdList,
    varsNumber: 4,
  };

  const result = await FetchScreensByTagListGroup(req);
  if (result.error.length > 0) {
    throw new Error(result.error);
  }

  return result.response;
};
