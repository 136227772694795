import Joi from "joi";

export interface Member {
  id: string;
  fullName: string;
  imageS3URL?: string | null;
  email: string;
  role: string[];
}

export const memberSchema = Joi.object({
  id: Joi.string().required().uuid(),
  fullName: Joi.string().required(),
  email: Joi.string().required(),
  imageS3URL: Joi.string().allow("", null).optional(),
  role: Joi.array().required(),
}).unknown();

export const memberListSchema = Joi.array().items(memberSchema);

export async function toMemberArray(data: any[]): Promise<Member[]> {
  const validationResult = await memberListSchema.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return validationResult.value.map((d) => {
    return Object.assign({}, d) as Member;
  });
}
