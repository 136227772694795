import { useEffect, useRef } from "react";

export function usePrevious<T>(state: T): T | undefined {
  const prevStateRef = useRef<T>();

  useEffect(() => {
    prevStateRef.current = state;
  }, [state]);

  return prevStateRef.current;
}

export function arraysHaveSameElements(arr1: any[], arr2: any[]): boolean {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return set1.size === set2.size && arr1.every((item) => set2.has(item));
}
