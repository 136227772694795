import { Fetch, FetchInput, Method } from "../../Fetch";
import { Project, toProjects } from "../../types/project";

export interface GetAllProjectsResponse {
  response: Project[];
  error: string;
}

export async function GetAllProjects(
  signal?: AbortSignal
): Promise<GetAllProjectsResponse> {
  const getAllProjectsUrl = "/me/project/all";
  try {
    const input: FetchInput = {
      method: Method.Get,
      url: getAllProjectsUrl,
      signal: signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          response: await toProjects(response.result.result),
          error: "",
        };
      }

      return {
        response: [],
        error: "Server side error",
      };
    } else {
      return {
        response: [],
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      response: [],
      error: "error",
    };
  }
}
