import React, { ChangeEvent, useEffect, useState } from "react";
import "./SignUp.css";
import Logo from "../../icons/favicon.png";
import GoogleIcon from "../../icons/google.svg";
import { Link, useNavigate } from "react-router-dom";
import {
	FetchSignUp,
	RegisterRequest,
} from "../../utils/backend/SignIn SignUp/FetchSignUp";
import { GetUser } from "../../utils/backend/Profile/GetUser";
import { useAppSelector } from "../../store/hooks";
import ShowIcon from "../../icons/Show.svg";
import HideIcon from "../../icons/Hide.svg";

interface SignSwitcherProps {
	setSwitcher: (switchState: string) => void;
}

function SignUp({ setSwitcher }: SignSwitcherProps) {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [isLongEnough, setIsLongEnough] = useState<boolean>(false);
	const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
	const [error, setError] = useState<string | undefined>();
	const checkbox = document.querySelector(
		".checkbox-agree"
	) as HTMLElement | null;

	const [isPasswordVisible, setisPasswordVisible] = useState({
		repeat: false,
		pwd: false,
	});

	const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};
	const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};
	const handleConfirmPasswordChange = (
		event: ChangeEvent<HTMLInputElement>
	) => {
		setConfirmPassword(event.target.value);
	};

	useEffect(() => {
		setIsEmailValid(true);
	}, [email]);

	useEffect(() => {
		const currentUserToken = localStorage.getItem("token");
		if (!currentUserToken) {
			return;
		}
		const handleGetUser = async function () {
			const result = await GetUser();
			if (result.error.length > 0) {
				setError(
					"Something unexpected happened, try to reload page or contact us."
				);
				console.error(result.error);
				return;
			}

			if (result.user) {
				navigate("/Navigation/Screens");
			}
		};
		try {
			handleGetUser();
		} catch (e) {
			setError(
				"Something unexpected happened, try to reload page or contact us."
			);
			console.error(e);
		}
	}, [navigate]);

	useEffect(() => {
		setDoPasswordsMatch(true);
		setIsLongEnough(password.length > 7);
	}, [password]);

	useEffect(() => {
		setDoPasswordsMatch(password === confirmPassword);
	}, [password, confirmPassword]);

	const checkPassword = () => {
		return !!(password && password === confirmPassword);
	};

	const lengthCheck = () => {
		let len = password.length;
		if (password) {
			if (len < 8) {
				return `Password must contain at least 8 characters`;
			} else {
				return "";
			}
		}
	};

	// const checkIsChecked = () => {
	// 	if (checkbox != null) {
	// 		if (!isChecked) {
	// 			checkbox.style.boxShadow = "0 0 0 1px #FFCCCB";
	// 		}
	// 	}
	// };

	// useEffect(() => {
	// 	if (checkbox != null) {
	// 		if (isChecked) {
	// 			checkbox.style.boxShadow = "none";
	// 		}
	// 	}
	// }, [isChecked]);

	const checkBeforeSignUp = () => {
		if (isLongEnough && checkPassword()) {
			signUp();
		}

		if (!checkPassword()) {
			setDoPasswordsMatch(false);
		}
	};

	const signUp = () => {
		const regReq: RegisterRequest = {
			Email: email,
			Password: password,
		};

		FetchSignUp(regReq)
			.then((response) => response)
			.then((data) => {
				if (data.userId) {
					setIsEmailValid(true);
					navigate(`/verification?email=${email}`);
				} else {
					setIsEmailValid(false);
				}
			})
			.catch((err) => {
				if ((err = "409")) {
					setIsEmailValid(false);
				} else {
					console.error(err);
				}
			});
	};

	const handleCheck = () => {
		setIsChecked((prevState) => !prevState);
	};
	const lightMode = useAppSelector((state) => state.navigation.lightMode);

	return (
		<div className={`${lightMode} sign-page`}>
			<div className={`${lightMode} sign-container`}>
				<div className={`${lightMode} form`}>
					<div style={{ marginBottom: "20px" }}>
						<label htmlFor="email" className={`${lightMode} label`}>
							Email
						</label>
						<input
							type="email"
							id="email"
							className={`${lightMode} email sign-in-input`}
							onChange={handleEmailChange}
							placeholder="Enter your email"
						/>

						{!isEmailValid && (
							<h2 className={`${lightMode} error`}>
								This email is already in use
							</h2>
						)}
					</div>
					<label htmlFor="password" className={`${lightMode} label pass-label`}>
						Password
					</label>
					<div style={{ marginBottom: "20px" }}>
						<input
							type={isPasswordVisible.pwd ? "text" : "password"}
							id="password"
							className={`${lightMode} password sign-in-input`}
							onChange={handlePasswordChange}
							placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
						/>
						<img
							src={isPasswordVisible.pwd ? HideIcon : ShowIcon}
							onClick={() =>
								setisPasswordVisible({
									...isPasswordVisible,
									pwd: !isPasswordVisible.pwd,
								})
							}
							className={`${lightMode} show-hide-image-signin sign-up-pwd-icon`}
						/>
						{password && (
							<h2 className={`${lightMode} error`}> {lengthCheck()}</h2>
						)}
					</div>

					<label
						htmlFor="confirm-password"
						className={`${lightMode} label pass-label`}>
						Password confirmation
					</label>
					<div>
						<input
							type={isPasswordVisible.repeat ? "text" : "password"}
							id="confirm-password"
							className={`${lightMode} password sign-in-input`}
							onChange={handleConfirmPasswordChange}
							placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
						/>
						<img
							src={isPasswordVisible.repeat ? HideIcon : ShowIcon}
							onClick={() =>
								setisPasswordVisible({
									...isPasswordVisible,
									repeat: !isPasswordVisible.repeat,
								})
							}
							className={`${lightMode} show-hide-image-signin sign-up-pwd-icon`}
						/>
					</div>

					{!doPasswordsMatch && (
						<h2 className={`${lightMode} error match-error`}>
							Passwords do not match
						</h2>
					)}
					{/*<div className={`${lightMode} under-password`}>
						<input
							type="checkbox"
							className={`${lightMode} checkbox-agree`}
							id="sign-in-agreement"
							checked={isChecked}
							onChange={handleCheck}
						/>
						<label
							className={`${lightMode} sign-up-agreement`}
							htmlFor="sign-in-agreement">
							By sign up you agree to UXMaps{" "}
							<a href="uxmaps.co/tos" target="_blank">
								Terms of Service
							</a>{" "}
							and{" "}
							<a href="uxmaps.co/privacy-policy" target="_blank">
								Privacy Policy
							</a>
						</label>
				</div>*/}
				</div>

				<div className={`${lightMode} sign-up-buttons`}>
					<button
						className={`${lightMode} sign-btn up`}
						onClick={checkBeforeSignUp}>
						Sign up
					</button>
					{/* <button className={`${lightMode} sign-btn google`}>
						<img src={GoogleIcon} className={`${lightMode} google-icon`}></img>
						Sign up with Google
					</button> */}
				</div>

				<div className={`${lightMode} already-acc`}>
					<h2>Already have an account?</h2>
					<Link
						className={`${lightMode} already-acc-link`}
						to="/SignIn"
						onClick={() => setSwitcher("log-in")}>
						Sign in
					</Link>
				</div>
			</div>
		</div>
	);
}

export default SignUp;
