import Joi from "joi";
import { Screen, screenListSchema } from "./screen";

export interface Collection {
  id: string;
  name: string;
  contentList: Screen[];
}

const collectionSchema = Joi.object({
  id: Joi.string().required().uuid(),
  name: Joi.string().required(),
  contentList: screenListSchema,
}).unknown();

const collectionListSchema = Joi.array().items(collectionSchema);

export async function toCollectionArray(data: any[]): Promise<Collection[]> {
  const validationResult = await collectionListSchema.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return validationResult.value.map((d) => {
    return Object.assign({}, d) as Collection;
  });
}

export async function toCollection(data: any): Promise<Collection> {
  const validationResult = await collectionSchema.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return Object.assign({}, data) as Collection;
}
