import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../utils/types/user";

const initialState: User = {
	fullName: "",
	id: "",
	image: "",
	email: "",
	website: "",
	rememberMe: false,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) => {
			state.fullName = action.payload.fullName;
			state.email = action.payload.email;
			state.id = action.payload.id;
			state.image = action.payload.image;
			state.imageData = action.payload.imageData;
			state.website = action.payload.website;
		},
		removeUser: (state) => {
			state.fullName = "";
			state.email = "";
			state.id = "";
			state.image = "";
			state.imageData = "";
			state.website = "";
			state.rememberMe = false;
		},
		setRememberMeAction: (state, action: PayloadAction<boolean>) => {
			state = { ...state, rememberMe: action.payload };
		},
	},
});

export const { setUser, removeUser, setRememberMeAction } = authSlice.actions;

export default authSlice.reducer;
