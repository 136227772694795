import styled, { css, keyframes } from "styled-components";

interface StyledGridDivProps {
	areFiltersOpen: boolean;
	innerWidth: number;
}
interface StyledFiltersContainerProps {
	isScrolled: number;
	displaysFrom: string;
	displayFilters: boolean;
	lightMode: string;
}
interface StyledItemProps {
	screenWidth: number;
}

interface StyledScreenImageProps {
	innerWidth: number;
	areFiltersOpen: boolean;
}

export const StyledGridDiv = styled.div<StyledGridDivProps>`
	width: ${({ areFiltersOpen }) => (areFiltersOpen ? "calc(100% - 275px) " : "")};
	}
	height: fit-content;
	display: grid;
	gap: 20px;
	list-style: none;
	justify-content: start;
	padding: 0 0 0 32px;

	@media only screen and (min-width: 2150px)  {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(4,calc(${innerWidth - 442}px / 5))`
				: `repeat(5,calc(${innerWidth - 164}px / 6))`};
	}

	@media only screen and (min-width: 1725px) and (max-width: 2150px) {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(4,calc(${innerWidth - 422}px / 4))`
				: `repeat(5,calc(${innerWidth - 144}px / 5))`};
	}

	@media only screen and (min-width: 1470px) and (max-width: 1725px) {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(3,calc(${innerWidth - 402}px / 3))`
				: `repeat(4,calc(${innerWidth - 124}px / 4))`};
	}

	@media only screen and (min-width: 1298px) and (max-width: 1470px) {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(2,calc(${innerWidth - 382}px / 2))`
				: `repeat(3,calc(${innerWidth - 104}px / 3))`};
	}

	@media only screen and (min-width: 875px) and (max-width: 1298px) {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(1	,calc(${innerWidth - 362}px	 ))`
				: `repeat(2,calc(${innerWidth - 84}px / 2))`};
	}

	@media only screen and (max-width: 875px)  {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(1	,calc(${innerWidth - 362}px))`
				: `repeat(1,calc(${innerWidth - 64}px))`};
	}



`;

export const StyledFlowTabDiv = styled.div`
	width: calc(100vw - 32px);
	overflow-x: auto;
	overflow-y: hidden;
	column-gap: 20px;
	display: flex;

	&::-webkit-scrollbar-track {
		display: none;
		// -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: #fff;
	}

	&::-webkit-scrollbar {
		display: none;
		width: 10px;
		height: 4px;
		background-color: #f8f8f8;
	}

	&::-webkit-scrollbar-thumb {
		display: none;
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
		background-color: #f0f2f5;
	}

	& > *:last-child {
		margin-right: 48px;
	}
`;

// export const StyledFiltersContainer = styled.div<StyledFiltersContainerProps>`
// 	border: 1px solid #eaecf0;
// 	height: 825px;
// 	border-radius: 10px;
// 	width: 275px;
// 	position: sticky;
// 	top: 32px;
// 	margin-top: ${({ displaysFrom }) =>
// 		displaysFrom === "source" ? "14px" : "0px"};

// 	right: 32px;
// 	background: white;
// 	margin-left: auto;
// 	animation: ${({ displayFilters }) =>
// 		displayFilters === true
// 			? "myAnim 0.5s ease 0s 1 normal forwards;"
// 			: "myAnimOut 0.5s ease-in 0s 1 normal forwards;"};
// 	animation: myAnim 0.5s ease 0s 1 normal forwards;

// 	animation: myAnimOut 0.5s ease-in 0s 1 normal forwards;
// 	@keyframes myAnim {
// 		0% {
// 			opacity: 0;
// 			transform: translateX(50px);
// 		}

// 		100% {
// 			opacity: 1;
// 			transform: translateX(0);
// 		}
// 	}

// 	@keyframes myAnimOut {
// 		0% {
// 			opacity: 1;
// 			transform: translateX(0);
// 		}

// 		100% {
// 			opacity: 0;
// 			transform: translateX(50px);
// 		}
// 	}
// `;

const entryAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

const exitAnimation = keyframes`
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(50px);
    }
`;

export const StyledFiltersContainer = styled.div<StyledFiltersContainerProps>`
	border: 1px solid #eaecf0;
	height: 85vh;
	border-radius: 10px;
	width: 275px;
	position: sticky;
	top: 32px;
	margin-top: ${({ displaysFrom }) =>
		displaysFrom === "source" ? "14px" : "0px"};

	right: 32px;
	background: white;
	margin-left: auto;
	${(props) =>
		props.lightMode === "dark" &&
		`

    background-color: #171717;
	border: 1px solid #353535;
  `}
	animation: ${({ displayFilters }) => {
		return displayFilters
			? css`
					${entryAnimation} 0.5s ease 0s 1 normal forwards;
			  `
			: css`
					${exitAnimation} 0.5s ease-in 0s 1 normal forwards;
			  `;
	}};

	@media (min-height: 700px) and (max-height: 900px) {
		height: 90vh;
	}

	/* Height 900px and above */
	@media (min-height: 900px) {
		height: 95vh;
	}
`;

export const StyledItem = styled.div<StyledItemProps>`
	width: 100%;
	height: ${({ screenWidth }) =>
		`calc(${screenWidth / (16 / 9) / 3 - 184}px)`} !important;
	border-radius: 10px;

	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	position: relative;

	&.flows {
		width: fit-content;
		padding: 14px 0px;
	}

	&.with-filters {
		width: 100%;
	}
`;

export const StyledScreenImage = styled.div<StyledScreenImageProps>`
	width: 100%;
	min-width: 200px;
	border-radius: 8px 8px 0 0;
	box-sizing: border-box;
	box-shadow: 0px 5.17px 3.1px -2.07px rgba(0, 0, 0, 0.02);
	border: 1px solid #eaecf0;
	border-radius: 6px;
	background-size: contain;
	background-repeat: no-repeat;

	@media only screen and (min-width: 2150px) {
		height: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `calc(${innerWidth - 342}px / 5 / (16 / 9))`
				: `calc(${innerWidth - 164}px / 6 / (16 / 9))`};
	}

	@media only screen and (min-width: 1725px) and (max-width: 2150px) {
		height: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `calc(${innerWidth - 422}px / 4 / (16 / 9))`
				: `calc(${innerWidth - 144}px / 5 / (16 / 9))`};
	}

	@media only screen and (min-width: 1470px) and (max-width: 1725px) {
		height: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `calc(${innerWidth - 402}px / 3 / (16 / 9))`
				: `calc(${innerWidth - 124}px / 4 / (16 / 9))`};
	}

	@media only screen and (min-width: 1298px) and (max-width: 1470px) {
		height: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `calc(${innerWidth - 382}px / 2 / (16 / 9))`
				: `calc(${innerWidth - 104}px / 3 / (16 / 9))`};
	}

	@media only screen and (min-width: 875px) and (max-width: 1298px) {
		height: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `calc(${innerWidth - 362}px / (16 / 9))`
				: `calc(${innerWidth - 84}px / 2 / (16 / 9))`};
	}

	@media only screen and (max-width: 875px) {
		height: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `calc(${innerWidth - 322}px / (16 / 9))`
				: `calc(${innerWidth - 64}px / (16 / 9))`};
	}

	&:hover {
		border: 1px solid #d0d5dd;
	}

	&.flows {
		background-repeat: no-repeat;
		background-size: contain;
		width: calc(45vw - 128px);
		height: calc((45vw - 128px) / (16 / 9));
		max-height: calc((45vw - 128px) / (16 / 9));
	}

	&.flow {
		border: 0;

		&:hover: {
			border: 0;
		}
	}
`;
