import "../../companies/CompanyInfo/CompanyInfo.css";
import Item from "../../companies/CompanyItem/CompanyItem";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Screen } from "../../../utils/types/screen";
import { Tag } from "../../../utils/types/tag";
import { PreviewScreen } from "../../common/previewScreen/PreviewScreen";
import { Collection } from "../../../utils/types/collection";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import NoResultsFoundPage from "../../NoResultsFoundPage/NoResultsFoundPage";
import { setIsPreviewScreenOpen } from "../../../store/slice/navigationSlice";
import Filters from "../../FiltersComponent/Filters";
import { StyledFlowTabDiv, StyledGridDiv } from "./ProductPage.styles";
import "./ProductPage.css";
import ProductPageHeader from "./ProductPageHeader";
import Loader from "../../Loader/Loader";
import ArrowRight from "../../../icons/arrow-right.svg";
import ArrowLeft from "../../../icons/arrow-left.svg";
import ArrowRightWhite from "../../../icons/arrow-right-white.svg";
import ArrowLeftWhite from "../../../icons/arrow-left-white.svg";
import {
	FetchFlowsByTags,
	FetchFlowsByTagsRequest,
} from "../../../utils/backend/Flow/FetchFlowsByTags";
import { Flow } from "../../../utils/types/flow";

interface flowsObject {
	[key: string]: Array<Screen>;
}

interface ProductPageDisplayProps {
	screens: Screen[];
	filteredScreens: Screen[];
	tags: Tag | undefined;
	flow: Flow | undefined;
	industryTags: Tag[];
	limit: number;
	page: number;
	setScreens: React.Dispatch<React.SetStateAction<Screen[]>>;
	selectingMode: boolean;
	collectionList: Collection[];
	setCollectionList: React.Dispatch<React.SetStateAction<Collection[]>>;
	isLoadingMore: boolean;
	shouldDisplayFilters: boolean;

	setSelectingMode(newValue: boolean): void;

	headerSearchCb(searchValue: string): void;

	onLoadMore(): void;

	headerSearch: string;
}

const ProductPageDisplay = ({
	screens,
	filteredScreens,
	tags,
	limit,
	page,
	flow,
	setScreens,
	industryTags,
	selectingMode,
	setSelectingMode,
	setCollectionList,
	collectionList,
	onLoadMore,
	isLoadingMore,
	shouldDisplayFilters,
	headerSearchCb,
	headerSearch,
}: ProductPageDisplayProps) => {
	const navigate = useNavigate();
	const [selectedScreenIds, setSelectedScreenIds] = useState<string[]>([]);
	const [sliderOpened, setSliderOpened] = useState(false);
	const [currentScreen, setCurrentScreen] = useState<number | undefined>();
	const [singleFlow, setSingleFlows] = useState<Array<Flow> | null>([]);
	const [openCollectionsDropdown, setOpenCollectionsDropdown] = useState(false);
	const [pagesToDisplay, setPagesToDisplay] = useState(page);
	const activeFilters = useAppSelector((state) => state.filters.activeFilters);
	const [screenSwitcher, setScreenSwitcher] = useState<boolean>(
		window.location.search.includes("flows=true") ||
			window.location.pathname.includes("/Navigation/Flows")
			? false
			: true
	);
	const companyTagId = useAppSelector(
		(state) => state.tags.tagTypes.find((e) => e.name === "Company")?.id
	);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const [flowsArrays, setFlowsArrays] = useState<Array<Screen>>();
	const allTagTypesWithTags = useAppSelector(
		(state) => state.tags.allTagTypesWithTags
	);
	const [innerWidth, setInnerWidth] = useState<number>(
		document.body.clientWidth
	);
	const companyTagTypeId = useAppSelector(
		(state) => state.tags.tagTypes.find((tag) => tag.name === "Company")?.id
	);
	const companies = useAppSelector((state) =>
		state.tags.allTagTypesWithTags.find((tag) => tag.tagType.name === "Company")
	);

	const handleResize = () => {
		setInnerWidth(document.body.clientWidth);
	};

	const areFiltersOpen = useAppSelector(
		(state) => state.filters.isFilterOpened
	);
	const [shouldStartAnimation, setShouldStartAnimation] = useState(false);

	let website: string | null = null;
	if (
		screens.length > 0 &&
		window.location.pathname.includes("/Navigation/Companies/CompanyInfo")
	) {
		const companyId = screens[0].tagList.find(
			(tag) => tag.tagTypeId === companyTagTypeId
		)?.id;
		website =
			companies?.tagList.find((comp) => comp.id === companyId)?.website || null;
	} else if (
		screens.length > 0 &&
		window.location.pathname.includes("/Navigation/Flows/FlowInfo")
	) {
		website =
			companies?.tagList.find((comp) =>
				screens[0].tagList.find((tag: Tag) => comp.name === tag.name)
			)?.website || null;
	}

	const [searchParams] = useSearchParams();
	const flowId =
		searchParams.get("design-el-id") ||
		searchParams.get("flow-id") ||
		searchParams.get("company-id");

	const [hoveredFlow, setHoveredFlow] = useState<number | null>(null);

	const loader = useRef(null);
	const hoveringFlows = useRef(null);

	useEffect(() => {
		setFlowsArrays([]);
	}, [screenSwitcher]);

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [isLoadingMore]);

	useEffect(() => {
		if (openCollectionsDropdown) {
			setSelectingMode(true);
		} else if (!openCollectionsDropdown && selectingMode) {
			setSelectingMode(true);
		}
	}, [openCollectionsDropdown, setOpenCollectionsDropdown]);

	const displayScreens = () => {
		if (
			(!isLoadingMore &&
				screens.length === 0 &&
				filteredScreens.length === 0) ||
			(filteredScreens.length === 0 && activeFilters.length > 0) ||
			(headerSearch !== "" && filteredScreens.length === 0)
		) {
			return <NoResultsFoundPage />;
		}

		const selectingScreens = (id: string) => {
			if (!selectedScreenIds.includes(id)) {
				setSelectedScreenIds((prev) => {
					return [...prev, id];
				});
				return;
			}

			setSelectedScreenIds(
				selectedScreenIds.filter((selectedId) => {
					return selectedId !== id;
				})
			);
		};
		const collectionChangedFunc = (
			screenId: string,
			collectionId: string,
			type: string
		) => {
			if (type === "add") {
				const update = screens;
				update.forEach((screen) => {
					if (screen.id === screenId) {
						screen.collectionIdList.push(collectionId);
					}
				});
				setScreens(update);
			} else if (type === "remove") {
				const update = screens;
				update.forEach((screen) => {
					if (screen.id === screenId) {
						screen.collectionIdList = screen.collectionIdList.filter(
							(id: string) => {
								return id !== collectionId;
							}
						);
					}
				});
				setScreens(update);
			}
		};

		if (filteredScreens.length > 0) {
			return filteredScreens.map((screen, index) => {
				let company = null;
				if (window.location.pathname.includes("/Design/Element")) {
					const companyId = screen.tagList.find(
						(tag) => tag.tagTypeId === companyTagTypeId
					)?.id;
					company = companies?.tagList.find((comp) => comp.id === companyId);
				}
				if (index < (pagesToDisplay + 1) * 36)
					return (
						<Item
							screen={screen}
							company={company}
							key={screen.id}
							name={screen.name}
							id={screen.id}
							selectingMode={selectingMode}
							image={screen.presignedURLResized ?? screen.presignedURL}
							saveDropdown
							selectingScreens={selectingScreens}
							hideCollDropdown={() => setOpenCollectionsDropdown(false)}
							displayCheckbox={selectingMode}
							collectionList={screen.collectionIdList}
							collectionChangedFunc={collectionChangedFunc}
							handleClick={() => {
								setSliderOpened(true);
								setCurrentScreen(index);
							}}
						/>
					);
			});
		} else {
			return screens.map((screen, index) => {
				let company = null;
				if (window.location.pathname.includes("/Design/Element")) {
					const companyId = screen.tagList.find(
						(tag) => tag.tagTypeId === companyTagTypeId
					)?.id;
					company = companies?.tagList.find((comp) => comp.id === companyId);
				}
				if (index < (pagesToDisplay + 1) * 36)
					return (
						<Item
							screen={screen}
							company={company}
							key={screen.id}
							name={screen.name}
							id={screen.id}
							selectingMode={selectingMode}
							image={screen.presignedURLResized ?? screen.presignedURL}
							saveDropdown
							selectingScreens={selectingScreens}
							hideCollDropdown={() => setOpenCollectionsDropdown(false)}
							displayCheckbox={selectingMode}
							collectionList={screen.collectionIdList}
							collectionChangedFunc={collectionChangedFunc}
							handleClick={() => {
								setSliderOpened(true);
								setCurrentScreen(index);
							}}
						/>
					);
			});
		}
	};

	const displayOneFlow = (flowsObject: Screen[]) => {
		const selectingScreens = (id: string) => {
			if (!selectedScreenIds.includes(id)) {
				setSelectedScreenIds((prev) => {
					return [...prev, id];
				});
				return;
			}

			setSelectedScreenIds(
				selectedScreenIds.filter((selectedId) => {
					return selectedId !== id;
				})
			);
		};
		const collectionChangedFunc = (
			screenId: string,
			collectionId: string,
			type: string
		) => {
			if (type === "add") {
				const update = screens;
				update.forEach((screen) => {
					if (screen.id === screenId) {
						screen.collectionIdList.push(collectionId);
					}
				});
				setScreens(update);
			} else if (type === "remove") {
				const update = screens;
				update.forEach((screen) => {
					if (screen.id === screenId) {
						screen.collectionIdList = screen.collectionIdList.filter(
							(id: string) => {
								return id !== collectionId;
							}
						);
					}
				});
				setScreens(update);
			}
		};

		return (
			<>
				{flowsObject.map((screen: Screen, index) => {
					let company = null;
					if (window.location.pathname.includes("/Design/Element")) {
						const companyId = screen.tagList.find(
							(tag) => tag.tagTypeId === companyTagTypeId
						)?.id;
						company = companies?.tagList.find((comp) => comp.id === companyId);
					}
					return (
						<Item
							screen={screen}
							company={company}
							key={screen.id}
							name={screen.name}
							id={screen.id}
							selectingMode={selectingMode}
							image={screen.presignedURLResized ?? screen.presignedURL}
							saveDropdown
							selectingScreens={selectingScreens}
							hideCollDropdown={() => setOpenCollectionsDropdown(false)}
							displayCheckbox={selectingMode}
							collectionList={screen.collectionIdList}
							collectionChangedFunc={collectionChangedFunc}
							onFlows={screenSwitcher}
							handleClick={() => {
								setSliderOpened(true);
								setCurrentScreen(index);
								setFlowsArrays(flowsObject);
							}}
						/>
					);
				})}
			</>
		);
	};

	const handleHorizantalScroll = (index: number, side: string) => {
		const e = document.getElementById(`flows-container-${index}`);
		if (e && side === "right")
			e.scrollTo({
				left: e?.scrollLeft + 450,
				behavior: "smooth",
			});
		if (e && side === "left")
			e.scrollTo({
				left: e?.scrollLeft - 450,
				behavior: "smooth",
			});
	};

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		async function getFlows() {
			if (flowId) {
				const req: FetchFlowsByTagsRequest = {
					tags: [flowId],
					page: 0,
					limit: -1,
					signal: signal,
					shouldBeRandom: false,
					seed: 0,
				};
				try {
					const data = await FetchFlowsByTags(req);
					if (data.response.length > 0) {
						setSingleFlows(data.response);
					} else {
						setSingleFlows(null);
					}
				} catch (error) {}
			} else return;
		}

		async function getFlowsForFlowsInfoPage() {
			const companyId = tags?.tagList.find(
				(e) => e.tagTypeId === companyTagId
			)?.id;

			if (flowId && companyId) {
				const req: FetchFlowsByTagsRequest = {
					tags: [companyId, flowId],
					page: 0,
					limit: -1,
					signal: signal,

					shouldBeRandom: false,
					seed: 0,
				};
				try {
					const data = await FetchFlowsByTags(req);
					if (data.response.length > 0) {
						setSingleFlows(data.response.filter((e) => e.id === tags.id));
					} else {
						setSingleFlows(null);
					}
				} catch (error) {}
			} else return;
		}

		if (
			singleFlow?.length === 0 &&
			singleFlow !== null &&
			!window.location.href.includes("/Flows/FlowInfo")
		) {
			getFlows();
		} else if (
			singleFlow?.length === 0 &&
			singleFlow !== null &&
			window.location.href.includes("/Flows/FlowInfo")
		) {
			getFlowsForFlowsInfoPage();
		}
	}, [flowId, singleFlow]);

	const displayFlows = () => {
		if (
			(!isLoadingMore &&
				screens.length === 0 &&
				filteredScreens.length === 0) ||
			(filteredScreens.length === 0 && activeFilters.length > 0) ||
			(headerSearch !== "" && filteredScreens.length === 0)
		) {
			return <NoResultsFoundPage />;
		}

		if (singleFlow === null) {
			return <NoResultsFoundPage />;
		}

		if (singleFlow !== null) {
			return singleFlow.map((fl, index) => {
				return (
					<>
						<div
							className={`${lightMode} flows-container`}
							onMouseEnter={() => setHoveredFlow(index)}
							onMouseLeave={() => setHoveredFlow(null)}>
							<div className={`${lightMode} gallery-flows`}>
								<div>
									<div className={`${lightMode} flow-tab-flow-name`}>
										{fl.name}
									</div>
									<div className={`${lightMode} flow-tab-screen-number`}>
										{fl.contentList.length} screens
									</div>
								</div>
								{fl.contentList.length > 2 && hoveredFlow === index && (
									<div className={`${lightMode} flow-tab-chavron`}>
										<div
											className={`${lightMode} flows-arrows-container`}
											onClick={() => {
												handleHorizantalScroll(index, "left");
											}}>
											{" "}
											<img
												className={`${lightMode} flows-arrows-img`}
												src={lightMode === "light" ? ArrowLeft : ArrowLeftWhite}
											/>
										</div>

										<div
											className={`${lightMode} flows-arrows-container`}
											onClick={() => {
												handleHorizantalScroll(index, "right");
											}}>
											<img
												className={`${lightMode} flows-arrows-img`}
												src={
													lightMode === "light" ? ArrowRight : ArrowRightWhite
												}
											/>
										</div>
									</div>
								)}
							</div>
							<StyledFlowTabDiv
								id={`flows-container-${index}`}
								ref={hoveringFlows}>
								{displayOneFlow(fl.contentList)}
							</StyledFlowTabDiv>
						</div>
					</>
				);
			});
		}
	};
	const loadMoreImages = async () => {
		setPagesToDisplay((prevPage) => prevPage + 1);
	};

	const handleObserver = (entities: IntersectionObserverEntry[]) => {
		const target = entities[0];
		if (target.isIntersecting) {
			loadMoreImages();
		}
	};

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "20px",
			threshold: 1.0,
		};
		const observer = new IntersectionObserver(handleObserver, options);
		if (loader.current) {
			observer.observe(loader.current);
		}

		return () => {
			if (loader.current) {
				observer.unobserve(loader.current);
			}
		};
	}, []);
	const previewScreenCloseHandler = () => {
		document.body.style.overflowY = "auto";
		setSliderOpened(false);
		document.body.style.overflowY = "auto";
	};
	const dispatch = useAppDispatch();

	return (
		<div className={`${lightMode} tags-page pagee`}>
			<PreviewScreen
				opened={sliderOpened}
				screenList={
					flowsArrays && flowsArrays?.length > 0
						? flowsArrays
						: filteredScreens.length > 0
						? filteredScreens
						: screens
				}
				handleClose={() => {
					dispatch(setIsPreviewScreenOpen(false));
					previewScreenCloseHandler();
				}}
				setCurrentScreenIndex={setCurrentScreen}
				currentScreenIndex={currentScreen}
				collectionList={collectionList}
				setCollectionList={setCollectionList}
				company={tags}
			/>
			<ProductPageHeader
				screens={screens}
				website={website}
				tags={tags}
				industryTags={industryTags}
				selectingMode={selectingMode}
				screensSwitcher={screenSwitcher}
				setSelectingMode={(newValue: boolean) => setSelectingMode(!newValue)}
				headerSearchCb={(searchValue: string) => headerSearchCb(searchValue)}
				screensSwitcherCb={(screens: boolean) => setScreenSwitcher(screens)}
			/>
			<div
				className={`${lightMode} grid-container`}
				id="grid-container"
				style={areFiltersOpen ? { display: "flex" } : {}}>
				{isLoadingMore && <Loader />}
				{screenSwitcher ? (
					<StyledGridDiv
						innerWidth={innerWidth}
						areFiltersOpen={
							window.location.pathname.includes("/Navigation/Flows/FlowInfo")
								? false
								: shouldDisplayFilters
						}>
						{displayScreens()}
					</StyledGridDiv>
				) : (
					<>
						<div>{displayFlows()}</div>
					</>
				)}

				{screenSwitcher && <Filters displaysFrom="product" screens={screens} />}
			</div>
			<div ref={loader} style={{ height: "10px" }}></div>
		</div>
	);
};
export default ProductPageDisplay;
