import Joi from "joi";
import { Screen, screenListSchema } from "./screen";
import defaultImage from "../../icons/image-loader.png";

export interface Company {
  id: string;
  name: string;
  image: string;
  description?: string;
  contentList: Screen[];
}

const companySchema = Joi.object({
  id: Joi.string().required().uuid(),
  name: Joi.string().required(),
  image: Joi.string().default(defaultImage).optional(),
  description: Joi.string().optional().allow("", null),
  contentList: screenListSchema,
}).unknown();

const companyListSchema = Joi.array().items(companySchema);

export async function toCompanyArray(data: any[]): Promise<Company[]> {
  const validationResult = await companyListSchema.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return validationResult.value.map((d) => {
    return Object.assign({}, d) as Company;
  });
}

export async function toCompany(data: any): Promise<Company> {
  const validationResult = await companySchema.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return Object.assign({}, data) as Company;
}
