import "../../companies/CompanyInfo/CompanyInfo.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Screen } from "../../../utils/types/screen";
import { Tag } from "../../../utils/types/tag";
import { Collection } from "../../../utils/types/collection";
import {
	getAllCollections,
	getScreensByTagList,
} from "../../companies/CompanyInfo/initializeData";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import ErrorPage from "../../ErrorPage/ErrorPage";
import Loader from "../../Loader/Loader";
import {
	arraysHaveSameElements,
	usePrevious,
} from "../../../utils/usePrevious";
import { validate as isValidUUID } from "uuid";

import { TagTypeType } from "../../../utils/backend/Company/TagByTagType";
import { AddTagsViews } from "../../../utils/backend/Tag/AddTagsViews";
import { getFlow } from "../../flows/FlowPage/initialize";
import { Flow } from "../../../utils/types/flow";
import { AddFlowView } from "../../../utils/backend/Flow/AddFlowView";

import "./ProductPage.css";
import { queryData } from "../../../utils/types/queryData";
import ProductPageDisplay from "./ProductPageDisplay";

const ProductPage = () => {
	const allTagTypesWithTags = useAppSelector(
		(state) => state.tags.allTagTypesWithTags
	);
	const projectId = useAppSelector((state) => state.navigation.projectId);
	const [error, setError] = useState<string>();
	const [searchParams] = useSearchParams();
	const productId =
		searchParams.get("company-id") ||
		searchParams.get("design-el-id") ||
		searchParams.get("flow-id");
	const [tags, setTags] = useState<Tag | Flow>();
	const [flow, setFlow] = useState<Flow>();
	const [industryTags, setIndustryTags] = useState<Tag[]>([]);
	const [screens, setScreens] = useState<Screen[]>([]);
	const [filteredScreens, setFilteredScreens] = useState<Screen[]>([]);
	const [queryState, setQueryState] = useState<queryData>({
		limit: 36,
		page: 0,
	});
	const [selectingMode, setSelectingMode] = useState<boolean>(false);
	const [collectionList, setCollectionList] = useState<Collection[]>([]);
	const [areCollectionsFetched, setAreCollectionsFetched] = useState(false);
	const activeFilters = useAppSelector((state) => state.filters.activeFilters);
	const [isLoadingMore, setIsLoadingMore] = useState(true);
	const shouldDisplayFilters: any = useAppSelector(
		(state) => state.filters.isFilterOpened
	);
	const [areScreensFetched, setAreScreensFetched] = useState<boolean>();

	const previousActiveFilters = usePrevious<string[]>(activeFilters);
	const previousQueryState = usePrevious<queryData>(queryState);
	const previousproductId = usePrevious<string | null>(productId);
	const [headerSearch, setHeaderSearch] = useState<string>("");
	useEffect(() => {
		if (previousproductId !== productId && screens.length > 0) {
			setScreens([]);
			setIsLoadingMore(true);
		}
	}, [productId, previousproductId]);

	const activeFiltersFiltration = () => {
		const copiedScreens = [...screens];
		const newFiltered = copiedScreens.filter((screen: Screen) =>
			screen.tagList.some((tag: Tag) => activeFilters.includes(tag.id))
		);

		setFilteredScreens(newFiltered);
	};

	useEffect(() => {
		if (activeFilters.length > 0 && headerSearch !== "") {
			const newFiltered = filteredScreens.filter((screen: Screen) =>
				screen.name?.toLowerCase().includes(headerSearch.toLowerCase())
			);
			setFilteredScreens(newFiltered);
		} else if (headerSearch !== "") {
			const newFiltered = screens.filter((screen: Screen) =>
				screen.name?.toLowerCase().includes(headerSearch.toLowerCase())
			);
			setFilteredScreens(newFiltered);
		} else {
			activeFiltersFiltration();
		}
	}, [headerSearch, activeFilters, previousActiveFilters]);

	useEffect(() => {
		if (
			productId &&
			isValidUUID(productId) &&
			window.location.pathname.includes("/Companies/CompanyInfo")
		) {
			AddTagsViews({ tagIdList: [productId] });
		} else if (
			productId &&
			isValidUUID(productId) &&
			window.location.pathname.includes("/Flows/FlowInfo")
		) {
			AddFlowView({
				flowId: productId,
			});
		} else {
			if (productId && isValidUUID(productId)) {
				AddTagsViews({ tagIdList: [productId] });
			}
		}
	}, [productId]);

	useEffect(() => {
		if (industryTags.length === 0) {
			setIndustryTags(
				allTagTypesWithTags.find((tt) => {
					return tt.tagType.name === TagTypeType.TagTypeIndustry;
				})?.tagList ?? []
			);
		}
		if (!productId) return;
		if (window.location.pathname.includes("/Companies/CompanyInfo"))
			setTags(
				(
					allTagTypesWithTags.find((tt) => {
						return tt.tagType.name === "Company";
					})?.tagList ?? []
				).find((c) => c.id === productId)
			);
		if (window.location.pathname.includes("/Design/Element")) {
			const tagType = allTagTypesWithTags.find(
				(tt) => tt.tagType.name === TagTypeType.TagTypeDesignElements
			);
			if (!tagType) return;
			const dElement = tagType.tagList.find((t) => t.id === productId);
			if (!dElement) {
				return;
			}

			setTags(dElement);
		}
	}, [allTagTypesWithTags, productId, industryTags.length]);

	useEffect(() => {
		if (!areCollectionsFetched) {
			getAllCollections({
				setError: setError,
				setCollections: setCollectionList,
			})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setAreCollectionsFetched(true);
				});
		}
	}, [areCollectionsFetched]);

	useEffect(() => {
		if (selectingMode) {
			const allCheckBoxes = document.querySelectorAll(".checkbox");
			allCheckBoxes.forEach((checkbox) => {
				checkbox.classList.remove("hide-checkbox");
			});
		}
	}, [screens, selectingMode]);

	useEffect(() => {
		if (!productId) {
			return;
		}

		if (window.location.pathname.includes("/Flows/FlowInfo")) {
			return;
		}

		if (
			!!previousQueryState &&
			queryState.page === previousQueryState.page &&
			queryState.limit === previousQueryState.limit &&
			!!previousActiveFilters &&
			arraysHaveSameElements(activeFilters, previousActiveFilters) &&
			!isLoadingMore &&
			!!previousproductId &&
			previousproductId === productId &&
			areScreensFetched
		) {
			return;
		}

		setAreScreensFetched(true);
		if (
			areScreensFetched &&
			previousActiveFilters === activeFilters &&
			!!previousQueryState &&
			queryState.page === previousQueryState.page &&
			queryState.limit === previousQueryState.limit &&
			productId === previousproductId
		)
			return;
		const tagIdList = [productId, ...activeFilters];
		if (screens.length === 0 || productId !== previousproductId) {
			setIsLoadingMore(true);
			getScreensByTagList(tagIdList, setScreens, setError, {
				page: queryState.page,
				limit: -1,
			})
				.then(() => {
					setIsLoadingMore(false);
				})
				.catch((err) => {
					if (err.message === "The user aborted a request") return;
					console.error(err);
					setAreScreensFetched(false);
					setError("Unable to get data, try again or contact us");
					setIsLoadingMore(false);
				});
		}

		if (previousActiveFilters !== activeFilters) {
			activeFiltersFiltration();
		}
	}, [
		activeFilters,
		productId,
		previousActiveFilters,
		previousQueryState,
		queryState.limit,
		queryState.page,
		previousproductId,
		headerSearch,
	]);

	useEffect(() => {
		if (flow) {
			setScreens(flow.contentList);
			let flows;
			allTagTypesWithTags.map((type) => {
				if (type.tagType.name === "Company") {
					flows = type.tagList.find(
						(company: Tag) =>
							company.id ===
							flow.tagList.find(
								(tag) =>
									tag.tagTypeId ===
									allTagTypesWithTags.find(
										(comp) => comp.tagType.name === "Company"
									)?.tagType.id
							)?.id
					);
				} else {
					return;
				}
			});

			setTags(flows);
		}
	}, [flow, setFlow]);

	useEffect(() => {
		if (
			!productId ||
			productId.length === 0 ||
			!window.location.pathname.includes("/Flows/FlowInfo")
		)
			return;

		const fetchFlow = async () => {
			if (!productId || productId.length === 0) return;
			await getFlow({
				flowId: productId,
				setFlow: setFlow,
				setError: setError,
			});
		};

		if (projectId) {
			setIsLoadingMore(true);
			fetchFlow()
				.catch((e) => {
					console.error(e);
					setError("Can't get data from server");
				})
				.finally(() => {
					setIsLoadingMore(false);
				});
		}
	}, [productId, projectId]);

	const loadMore = async () => {
		setIsLoadingMore(true); 
		setQueryState((prev) => {
			return {
				limit: prev.limit,
				page: prev.page + 1,
			};
		});
	};

	if (error) return null;

	if (isLoadingMore && screens.length === 0 ) {
		return <Loader />;
	}

	if (error) {
		return <ErrorPage />;
	}

	if (!tags && !flow) return null;

	return (
		<ProductPageDisplay
			screens={screens}
			flow={flow}
			filteredScreens={filteredScreens}
			tags={tags || flow}
			industryTags={industryTags}
			limit={queryState.limit}
			page={queryState.page}
			setScreens={setScreens}
			setSelectingMode={setSelectingMode}
			selectingMode={selectingMode}
			collectionList={collectionList}
			setCollectionList={setCollectionList}
			onLoadMore={loadMore}
			isLoadingMore={isLoadingMore}
			shouldDisplayFilters={shouldDisplayFilters}
			headerSearchCb={(searchValue: string) => setHeaderSearch(searchValue)}
			headerSearch={headerSearch}
		/>
	);
};

export default ProductPage;
