import { configureStore, AnyAction } from "@reduxjs/toolkit";
import tagSlice from "./slice/tagSlice";
import filtersSlice from "./slice/filtersSlice";
import navigationSlice from "./slice/navigationSlice";
import authSlice from "./slice/authSlice";
import boardsSlice from "./slice/workspacesSlice";
import setupSlice from "./slice/setupSlice";
import notificationSlice from "./slice/notificationSlice";

export const store = configureStore({
  reducer: {
    tags: tagSlice,
    filters: filtersSlice,
    navigation: navigationSlice,
    auth: authSlice,
    boards: boardsSlice,
    setup: setupSlice,
    notification: notificationSlice,
  },
});



export type Action = AnyAction;

//export const useDispatch = createDispatchHook()


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
