import styled, { css } from "styled-components";

interface StyledGridDivProps {
	areFiltersOpen: boolean;
	innerWidth: number;
}

export const StyledGridDivFlowPage = styled.div<StyledGridDivProps>`
width: ${({ areFiltersOpen }) =>
	areFiltersOpen ? "calc(100% - 280px) " : "100%"};
	}
	height: fit-content;
	display: grid;
	gap: 20px;
	list-style: none;
	justify-content: start;
	background-size: contain;
	background-repeat: no-repeat;

	@media only screen and (min-width: 2150px)  {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(4,calc(${innerWidth - 439}px / 5))`
				: `repeat(5,calc(${innerWidth - 164}px / 6))`};
	}

	@media only screen and (min-width: 1725px) and (max-width: 2150px) {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(4,calc(${innerWidth - 419}px / 4))`
				: `repeat(5,calc(${innerWidth - 144}px / 5))`};
	}

	@media only screen and (min-width: 1470px) and (max-width: 1725px) {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(3,calc(${innerWidth - 399}px / 3))`
				: `repeat(4,calc(${innerWidth - 124}px / 4))`};
	}

	@media only screen and (min-width: 1298px) and (max-width: 1470px) {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(2,calc(${innerWidth - 379}px / 2))`
				: `repeat(3,calc(${innerWidth - 104}px / 3))`};
	}

	@media only screen and (min-width: 875px) and (max-width: 1298px) {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(1	,calc(${innerWidth - 359}px ))`
				: `repeat(2,calc(${innerWidth - 84}px / 2))`};
	}

	@media only screen and (max-width: 875px)  {
		grid-template-columns: ${({ areFiltersOpen, innerWidth }) =>
			areFiltersOpen
				? `repeat(1	,calc(${innerWidth - 339}px))`
				: `repeat(1,calc(${innerWidth - 64}px))`};
	}


`;
