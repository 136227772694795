const DEFAULT_IMAGES_CACHE_TTL = 600000;

export function setImageInLocalStorage(id: string, imageData: string) {
  const now = new Date();
  const ttl = process.env.IMAGES_CACHE_TTL;
  let ttlNumber = parseInt(ttl || "");
  if (isNaN(ttlNumber)) {
    ttlNumber = DEFAULT_IMAGES_CACHE_TTL;
  }

  const item = {
    value: imageData,
    expiry: now.getTime() + ttlNumber,
  };
  localStorage.setItem(id, JSON.stringify(item));
}

export async function getImageFromLocalStorage(
  id: string
): Promise<string | undefined> {
  const itemStr = localStorage.getItem(id);

  if (!itemStr) {
    return;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(id);
    return;
  }

  try {
    const res = await fetch(item.value);
    if (!res.ok) {
      localStorage.removeItem(id);
      return;
    }
    return item.value;
  } catch (e) {
    localStorage.removeItem(id);
  }
}
