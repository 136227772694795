import { Fetch, FetchInput, Method } from "../../Fetch";

export interface RegisterRequest {
  Email: string;
  Password: string;
  signal?: AbortSignal;
}

export interface RegisterResponse {
  userId: string;
  error: string;
}

export async function FetchSignUp(
  request: RegisterRequest
): Promise<RegisterResponse> {
  const signInURL = "/public/user/register";
  const requestBody = JSON.stringify({
    email: request.Email,
    password: request.Password,
  });
  try {
    const input: FetchInput = {
      method: Method.Post,
      url: signInURL,
      signal: request.signal,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result.result;
      if (response) {
        return {
          userId: response,
          error: "",
        };
      }

      return {
        userId: "",
        error: "Server side error",
      };
    } else {
      return {
        userId: "",
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      userId: "",
      error: "error",
    };
  }
}
