import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActiveFilters } from "../../store/slice/filtersSlice";
import "./Switcher.css";
import { useEffect, useState } from "react";
import { TagTypeWithTags } from "../../utils/types/tagType";
import Check from "../../icons/check-mode.svg";
import CheckDark from "../../icons/check-mode-dark.svg";
import { Tag } from "../../utils/types/tag";

const Switcher = () => {
	const [activeButtonProduct, setactiveButtonProduct] = useState<Array<string>>(
		["AllScreens"]
	);
	const [selectAllScreens, setSelectAllScreens] = useState<boolean>(true);
	const [activeButtonLight, setactiveButtonLight] = useState("0");
	const appsAndWebsites = useAppSelector(
		(state: any) =>
			state.tags.allTagTypesWithTags.find(
				(tagType: TagTypeWithTags) => tagType.tagType.name === "Type"
			)?.tagList
	);

	const activeFilters = useAppSelector((state) => state.filters.activeFilters);
	const dispatch = useAppDispatch();
	const lightningMode: Array<Tag> | undefined = useAppSelector(
		(state) =>
			state.tags.allTagTypesWithTags.find(
				(tagType: TagTypeWithTags) => tagType.tagType.name === "Mode"
			)?.tagList
	);

	let modeLight: any = null;
	let darkMode: any = null;

	let appId: any = null;
	let websiteId: any = null;

	useEffect(() => {
		if (appsAndWebsites && appId === null && websiteId === null) {
			appId = appsAndWebsites.find((tag: any) => tag.name === "Apps").id;
			websiteId = appsAndWebsites.find((tag: any) => tag.name === "Website").id;
		}
	});

	useEffect(() => {
		if (lightningMode && (!darkMode || !modeLight)) {
			darkMode = lightningMode.find((mode) =>
				mode.name.toLowerCase().includes("dark")
			)?.id;
			modeLight = lightningMode.find((mode) =>
				mode.name.toLowerCase().includes("light")
			)?.id;
		}
	});

	useEffect(() => {
		if (
			activeFilters.find((el) => el === appId) ||
			activeFilters.find((el) => el === websiteId) ||
			activeFilters.find((el) => el === modeLight) ||
			activeFilters.find((el) => el === darkMode)
		) {
			setSelectAllScreens(false);
		} else {
			setSelectAllScreens(true);
		}
	}, [activeFilters]);

	useEffect(() => {

		if(darkMode && activeFilters.includes(darkMode)){
			setactiveButtonLight('dark')
		}
		if(modeLight && activeFilters.includes(modeLight)){
			setactiveButtonLight('light')
		}
		if(websiteId && activeFilters.includes(websiteId) && appId && activeFilters.includes(appId)){
			setactiveButtonProduct(['WebsiteScreens','ProductScreens'])
		}
		else if(websiteId && activeFilters.includes(websiteId)){
			setactiveButtonProduct([...activeButtonProduct, 'WebsiteScreens'])
		}
		else if(appId && activeFilters.includes(appId)){
			setactiveButtonProduct([...activeButtonProduct, 'ProductScreens'])
		}
		
	}, [activeFilters])

	const onClickHandler = (e: any) => {
		if (e.target.id === "AllScreens") {
			const newFilters = activeFilters.filter(
				(filter: any) =>
					filter != websiteId &&
					filter != appId &&
					filter != modeLight &&
					filter != darkMode
			);

			dispatch(setActiveFilters(newFilters));
			setactiveButtonLight("0");
			setactiveButtonProduct(["AllScreens"]);
		} else if (e.target.id === "ProductScreens") {
			if (activeButtonProduct.find((el) => el === "ProductScreens")) {
				setactiveButtonProduct(
					activeButtonProduct.filter((el) => el !== "ProductScreens")
				);
				dispatch(
					setActiveFilters([...activeFilters.filter((el) => el !== appId)])
				);
			} else {
				setactiveButtonProduct([...activeButtonProduct, e.target.id]);
				dispatch(setActiveFilters([...activeFilters, appId]));
			}
		} else if (e.target.id === "WebsiteScreens") {
			if (activeButtonProduct.find((el) => el === "WebsiteScreens")) {
				setactiveButtonProduct(
					activeButtonProduct.filter((el) => el !== "WebsiteScreens")
				);
				dispatch(
					setActiveFilters([...activeFilters.filter((el) => el !== websiteId)])
				);
			} else {
				setactiveButtonProduct([...activeButtonProduct, e.target.id]);
				dispatch(setActiveFilters([...activeFilters, websiteId]));
			}
		}
	};

	const onClickLightHandler = (e: any) => {
		if (activeButtonLight !== e.target.id) {
			if (e.target.id === "light") {
				const newFilters = activeFilters.filter(
					(filter) => filter !== darkMode
				);
				dispatch(setActiveFilters([...newFilters, modeLight]));
			} else if (e.target.id === "dark") {
				const newFilters = activeFilters.filter(
					(filter) => filter !== modeLight
				);
				dispatch(setActiveFilters([...newFilters, darkMode]));
			}

			setactiveButtonLight(e.target.id);
		} else {
			setactiveButtonLight("0");
			const newFilters = activeFilters.filter(
				(filter) => filter !== modeLight && filter !== darkMode
			);
			dispatch(setActiveFilters(newFilters));
		}
	};
	const lightMode = useAppSelector((state) => state.navigation.lightMode);

	return (
		<>
			<div className={`${lightMode} switcher-container`}>
				<button
					id="AllScreens"
					onClick={(e: any) => onClickHandler(e)}
					className={`${lightMode} ${
						selectAllScreens
							? "switcher-all-screens active"
							: "switcher-all-screens"
					}`}>
					{selectAllScreens && (
						<img
							className={`${lightMode} check-mode-img`}
							src={lightMode === "light" ? Check : CheckDark}
						/>
					)}
					All
				</button>
				<button
					id="ProductScreens"
					onClick={(e: any) => onClickHandler(e)}
					className={`${lightMode} ${
						activeButtonProduct.find((e) => e === "ProductScreens")
							? "switcher-all-screens active"
							: "switcher-all-screens"
					}`}>
					{activeButtonProduct.find((e) => e === "ProductScreens") && (
						<img
							className={`${lightMode} check-mode-img`}
							src={lightMode === "light" ? Check : CheckDark}
						/>
					)}
					Product
				</button>
				<button
					id="WebsiteScreens"
					onClick={(e: any) => onClickHandler(e)}
					className={`${lightMode} ${
						activeButtonProduct.find((e) => e === "WebsiteScreens")
							? "switcher-all-screens active"
							: "switcher-all-screens"
					}`}>
					{activeButtonProduct.find((e) => e === "WebsiteScreens") && (
						<img
							className={`${lightMode} check-mode-img`}
							src={lightMode === "light" ? Check : CheckDark}
						/>
					)}
					Website
				</button>
			</div>
			<div className={`${lightMode} switcher-container`}>
				<button
					id="light"
					onClick={(e: any) => onClickLightHandler(e)}
					className={`${lightMode}
					${
						activeButtonLight === "light"
							? "switcher-all-screens active"
							: "switcher-all-screens"
					}
				`}>
					{activeButtonLight === "light" && (
						<img
							className={`${lightMode} check-mode-img`}
							src={lightMode === "light" ? Check : CheckDark}
						/>
					)}
					Light Mode
				</button>
				<button
					id="dark"
					onClick={(e: any) => onClickLightHandler(e)}
					className={`${lightMode}
					${
						activeButtonLight === "dark"
							? "switcher-all-screens active"
							: "switcher-all-screens"
					}
				`}>
					{activeButtonLight === "dark" && (
						<img
							className={`${lightMode} check-mode-img`}
							src={lightMode === "light" ? Check : CheckDark}
						/>
					)}
					Dark Mode
				</button>
			</div>
		</>
	);
};

export default Switcher;
