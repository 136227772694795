import Logo from "../../icons/favicon.png";
import React, { useState } from "react";
import "./CreateNewPassword.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { isValidEmail } from "../../utils/helpers";
import { SetupNewPassword } from "../../utils/backend/SignIn SignUp/SetupNewPassword";
import { asyncNotification } from "../../store/slice/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ForgotPassword as FetchForgotPassword } from "../../utils/backend/SignIn SignUp/ForgotPassword";
import ShowIcon from "../../icons/Show.svg";
import HideIcon from "../../icons/Hide.svg";

interface createNewPasswordInput {
	email: string;
	token: string;
	newPassword: string;
	repeatPassword: string;
}
const CreateNewPassword = () => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [isPasswordVisible, setisPasswordVisible] = useState({
		password: false,
		confirmPassword: false,
	});

	const {
		handleSubmit,
		register,
		formState: { errors, isLoading },
		setError,
		watch,
		getValues,
	} = useForm<createNewPasswordInput>({
		defaultValues: {
			email: searchParams.get("email") ?? "",
			token: searchParams.get("code") ?? "",
		},
	});

	const newPasswordFieldValue = watch("newPassword");

	const dispatch = useAppDispatch();

	const onSubmit: SubmitHandler<createNewPasswordInput> = async (data) => {
		try {
			setError("root", {
				message: undefined,
			});
			const result = await SetupNewPassword({
				newPassword: data.newPassword,
				email: data.email,
				token: data.token,
			});
			if (result.isOk) {
				dispatch(
					asyncNotification({
						notification: "Password reset successfully.",
						isActive: true,
					})
				);
				navigate("/SignIn");
				return;
			}

			switch (result.error) {
				case "Not Found": {
					setError("root", {
						message: "User with given email doesn't exist",
					});
					break;
				}
				default: {
					setError("root", {
						message: "Unexpected error, try again or contact us",
					});
				}
			}
		} catch (e) {
			console.error(e);
			setError("root", {
				message: "Unexpected error, try again or contact us",
			});
		}
	};

	const resendEmail = async () => {
		const emailData = getValues().email;
		if (!emailData) {
			setError("email", {
				message: "Required",
			});
			return;
		}

		try {
			const result = await FetchForgotPassword(emailData);
			if (result.isOk) {
				dispatch(
					asyncNotification({
						notification: "Email sent successfully.",
						isActive: true,
					})
				);
				navigate("/SignIn/ResetPassword");
				return;
			}

			switch (result.error) {
				case "Not Found": {
					setError("root", {
						message: "User with given email doesn't exist",
					});
					break;
				}
				default: {
					setError("root", {
						message: "Unexpected error, try again or contact us",
					});
				}
			}
		} catch (err) {
			setError("root", {
				message: "Unexpected error, try again or contact us",
			});
			console.error(err);
		}
	};

	return (
		<div className={`${lightMode} create-new-password-page`}>
			<img src={Logo} className={`${lightMode} uxmaps-logo sign-page`}></img>
			<div className={`${lightMode} create-new-password`}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={`${lightMode} top`}>
						<h1 className={`${lightMode} create-new-password-title`}>
							Reset your password
						</h1>
						<h2 className={`${lightMode} wb wbb`}>
							Enter a new password to reset the password on your account.
						</h2>
					</div>

					<div>
						<label htmlFor="new-pass" className={`${lightMode} label`}>
							Email
						</label>
						<input
							type="email"
							id="email"
							className={`${lightMode} email sign-in-input`}
							style={errors.email ? {marginBottom: 0} : {marginBottom: '20px'}}
							placeholder="Enter your email"
							{...register("email", { required: true, validate: isValidEmail })}
						/>
						{errors.email && (
							<div style={{ color: "red", fontSize: "12px", marginTop: "5px", fontWeight: 500, marginBottom:'20px' }}>
								{errors.email.message && errors.email.message?.length > 0
									? errors.email.message
									: errors.email && errors.email.type === "required"
									? "Required"
									: errors.email && errors.email.type === "validate"
									? "Not valid email"
									: null}
							</div>
						)}
						<label htmlFor="new-pass" className={`${lightMode} label`}>
							Code
						</label>
						<input
							type="text"
							id="code"
							className={`${lightMode} new-pass sign-in-input`}
							style={errors.token ? {marginBottom: 0} : {marginBottom: '20px'}}
							{...register("token", { required: true })}
						/>
						{errors.token && (
							<div style={{ color: "red", fontSize: "12px", marginTop: "5px", fontWeight: 500, marginBottom:'20px' }}>
								{errors.token.message && errors.token.message.length > 0
									? errors.token.message
									: errors.token && errors.token.type === "required"
									? "Required"
									: null}
							</div>
						)}
						<label htmlFor="new-pass" className={`${lightMode} label`}>
							New password
						</label>
						<div>
							<input
								id="password"
								className={`${lightMode} sign-in-input`}
								style={errors.newPassword ? {marginBottom: 0} : {marginBottom: '20px'}}
								type={isPasswordVisible.password ? "text" : "password"}
								placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
								{...register("newPassword", {
									required: true,
									validate: (value) => {
										if (value.length < 8) {
											return `Must be at least ${8} characters long.`;
										}
									},
								})}
							/>
							<img
								src={isPasswordVisible.password ? HideIcon : ShowIcon}
								onClick={() =>
									setisPasswordVisible({
										password: !isPasswordVisible.password,
										confirmPassword: isPasswordVisible.confirmPassword,
									})
								}
								className={`${lightMode} show-hide-image-verification`}
							/>
						</div>
						{errors.newPassword && (
							<div style={{ color: "red", fontSize: "12px", marginTop: "5px", fontWeight: 500, marginBottom:'20px' }}>
								{errors.newPassword.message &&
								errors.newPassword.message?.length > 0
									? errors.newPassword.message
									: errors.newPassword && errors.newPassword.type === "required"
									? "Required"
									: errors.newPassword && errors.newPassword.type === "validate"
									? "Not valid password"
									: null}
							</div>
						)}
						<label htmlFor="confirm-new-pass" className={`${lightMode} label`}>
							Repeat new password
						</label>
						<div>
							<input
								id="password"
								className={`${lightMode} sign-in-input`}
								style={errors.repeatPassword ? {marginBottom: 0} : {marginBottom: '20px'}}
								type={isPasswordVisible.confirmPassword ? "text" : "password"}
								placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
								{...register("repeatPassword", {
									required: true,
									validate: (value) => {
										if (value.length < 8) {
											return `Must be at least ${8} characters long.`;
										}
										if (value !== newPasswordFieldValue) {
											return `Passwords are not matching`;
										}
									},
								})}
							/>
							<img
								src={isPasswordVisible.confirmPassword ? HideIcon : ShowIcon}
								onClick={() =>
									setisPasswordVisible({
										password: isPasswordVisible.password,
										confirmPassword: !isPasswordVisible.confirmPassword,
									})
								}
								className={`${lightMode} show-hide-image-verification`}
							/>
						</div>
						{errors.repeatPassword && (
							<div style={{ color: "red", fontSize: "12px", marginTop: "5px", fontWeight: 500, marginBottom:'20px' }}>
								{errors.repeatPassword.message &&
								errors.repeatPassword.message?.length > 0
									? errors.repeatPassword.message
									: errors.repeatPassword &&
									  errors.repeatPassword.type === "required"
									? "Required"
									: errors.repeatPassword &&
									  errors.repeatPassword.type === "validate"
									? "Not valid password"
									: null}
							</div>
						)}
						<button
							className={`${lightMode} create-new-password-btn`}
							type="submit"
							disabled={isLoading}>
							Reset password
						</button>
						<h2 className={`${lightMode} log-error`}>
							{errors.root && errors.root.message ? errors.root.message : null}
						</h2>
					</div>
				</form>
				<div className={`${lightMode} resend-email`}>
					<button
						className={`${lightMode} resend-email-btn`}
						onClick={resendEmail}>
						Resend forgot password email
					</button>
				</div>
			</div>
		</div>
	);
};
export default CreateNewPassword;
