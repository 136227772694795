import "./PreviewScreen.css";
import { Screen } from "../../../utils/types/screen";
import { Collection } from "../../../utils/types/collection";
import React, { useEffect, useRef, useState } from "react";
import { Tag } from "../../../utils/types/tag";
import vectors from "../../../icons/copyvectors.svg";
import download from "../../../icons/download.svg";
import vectorsWhite from "../../../icons/copyvectorsWhite.svg";
import downloadWhite from "../../../icons/downloadWhite.svg";
import closeIcon from "../../../icons/close.svg";
import closeIconWhite from "../../../icons/closeWhite.svg";
import PreviewScreenDropdown from "../../PreviewScreenDropdown/PreviewScreenDropdown";
import { asyncNotification } from "../../../store/slice/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setIsPreviewScreenOpen } from "../../../store/slice/navigationSlice";
import { AddScreenView } from "../../../utils/backend/Screen/AddScreenView";

interface PreviewScreenProps {
	screenList: Screen[];
	opened: boolean;
	currentScreenIndex?: number;
	collectionList: Collection[];
	setCollectionList: React.Dispatch<React.SetStateAction<Collection[]>>;
	company?: Tag;
	setCurrentScreenIndex: React.Dispatch<
		React.SetStateAction<number | undefined>
	>;

	handleClose(): void;
}

export const PreviewScreen = ({
	screenList,
	opened,
	handleClose,
	setCurrentScreenIndex,
	currentScreenIndex,
	collectionList,
	setCollectionList,
	company,
}: PreviewScreenProps) => {
	const [openCollectionsDropdown, setOpenCollectionsDropdown] = useState(false);
	const isPreviewOpen = useAppSelector(
		(state) => state.navigation.isPreviewScreenOpen
	);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	useEffect(() => {
		if (document.body.style.overflowY !== "hidden" && opened) {
			document.body.style.overflowY = "hidden";
		}
	});

	useEffect(() => {
		if (isPreviewOpen === false && opened) {
			dispatch(setIsPreviewScreenOpen(true));
		}
	}, [handleClose]);

	const ref = useRef(null);
	const dispatch = useAppDispatch();
	useOutsideAlerter(ref);

	function useOutsideAlerter(ref: any) {
		useEffect(() => {
			function handleClickOutside(event: any) {
				if (ref.current && !ref.current.contains(event.target)) {
					setOpenCollectionsDropdown(false);
				}
			}

			document.addEventListener("mousedown", handleClickOutside);
		}, [ref]);
	}

	useEffect(() => {
		const keyPressedHandler = (event: KeyboardEvent) => {
			switch (event.key) {
				case "Escape": {
					setOpenCollectionsDropdown(false);
					document.body.style.overflowY = "auto";
					dispatch(setIsPreviewScreenOpen(false));
					handleClose();
					break;
				}
				case "ArrowLeft": {
					if (screenList.length <= 1) return;
					setCurrentScreenIndex((previous) => {
						if (previous === undefined) return previous;
						return (previous - 1 + screenList.length) % screenList.length;
					});
					return;
				}
				case "ArrowRight": {
					if (screenList.length <= 1) return;
					setCurrentScreenIndex((previous) => {
						if (previous === undefined) return previous;
						return (previous + 1) % screenList.length;
					});
				}
			}
		};

		document.addEventListener("keydown", keyPressedHandler, false);

		return () => {
			document.removeEventListener("keydown", keyPressedHandler, false);
		};
	}, [handleClose, screenList.length, setCurrentScreenIndex]);

	useEffect(() => {
		if (currentScreenIndex !== undefined && opened) {
			AddScreenView({
				screenId: screenList[currentScreenIndex].id,
			});
		}
	}, [currentScreenIndex, opened, screenList]);

	if (currentScreenIndex === undefined || !opened) {
		return null;
	}

	const onNext = () => {
		if (currentScreenIndex === undefined) return;
		setCurrentScreenIndex((currentScreenIndex + 1) % screenList.length);
	};

	const onPrevious = () => {
		if (currentScreenIndex === undefined) return;
		setCurrentScreenIndex(
			(currentScreenIndex - 1 + screenList.length) % screenList.length
		);
	};

	async function copyImg(src: any) {
		const img = await fetch(src);
		const imgBlob = await img.blob();

		try {
			navigator.clipboard
				.write([
					new ClipboardItem({
						"image/png": imgBlob,
					}),
				])
				.then(() =>
					dispatch(
						asyncNotification({
							notification: "Copied to clipboard.",
							isActive: true,
						})
					)
				);
		} catch (error) {
			console.error(error);
		}
	}
	return (
		<div className={`${lightMode} container`}>
			<div
				className={`${lightMode} page-under`}
				onClick={() => {
					setOpenCollectionsDropdown(false);
					document.body.style.overflowY = "auto";
					dispatch(setIsPreviewScreenOpen(false));
					handleClose();
				}}
			/>
			<div className={`${lightMode} screen-info-page`}>
				<div className={`${lightMode} info-page`}>
					<div className={`${lightMode} background-header`}>
						<div onClick={() => {
								setOpenCollectionsDropdown(false);
								document.body.style.overflowY = "auto";
								dispatch(setIsPreviewScreenOpen(false));
								handleClose();
								}} 
								className={`${lightMode} image-container`}>
							<img
								className={`${lightMode} image-container image-header`}

								src={lightMode === "light" ? closeIcon : closeIconWhite}
							/>
						</div>
					</div>
					{screenList.length > 1 && (
						<div className={`${lightMode} carousel`}>
							<button
								onClick={() => {
									onPrevious();
								}}>
								<i className={`${lightMode} fa-solid fa-chevron-left`}></i>
							</button>
							<div className={`${lightMode} dash-carousel`}></div>
							<button
								onClick={() => {
									onNext();
								}}>
								<i className={`${lightMode} fa-solid fa-chevron-right`}></i>
							</button>
						</div>
					)}
					<div className={`${lightMode} header-info`}>
						<div className={`${lightMode} details-preview`}>
							<a
								href={`/Navigation/Companies/CompanyInfo?company-id=${company?.id}`}>
								<img
									className={`${lightMode} img`}
									src={company?.imageS3URL ?? ""}
								/>
							</a>
							<a
								href={`/Navigation/Companies/CompanyInfo?company-id=${company?.id}`}
								className={`${lightMode} preview-page-link-company`}>
								{company?.name}
							</a>
							<span className={`${lightMode} separator`}>/</span>{" "}
							<span className={`${lightMode} elements`}>
								{" "}
								{screenList[currentScreenIndex]?.name}
							</span>
						</div>
						<div className={`${lightMode} actionss page-preview `}>
							<a
								href={screenList[currentScreenIndex].presignedURL ?? undefined}
								download={screenList[currentScreenIndex].presignedURL}
								onClick={() =>
									dispatch(
										asyncNotification({
											notification: "Image is downloaded.",
											isActive: true,
										})
									)
								}>
								<button className={`${lightMode} save-btn-preview-screen`}>
									<img src={lightMode === "light" ? download : downloadWhite} />
								</button>
							</a>
							<button
								className={`${lightMode} save-btn-preview-screen`}
								onClick={async () => {
									copyImg(screenList[currentScreenIndex].presignedURL);
								}}>
								<img src={lightMode === "light" ? vectors : vectorsWhite} />
							</button>
							<div className={`${lightMode} dropdown-container-2`}>
								<div
									className={`${lightMode} head-2 button-2 company-save-btn-preview-screen page-preview`}
									onClick={() => {
										setOpenCollectionsDropdown((prev) => {
											return !prev;
										});
									}}
									style={
										openCollectionsDropdown && lightMode === "light"
											? { outline: "#EAECF0 solid 2px" }
											: openCollectionsDropdown && lightMode === "light"
											? { outline: "#818181 solid 2px" }
											: {}
									}>
									<i
										className={`${lightMode} fa-solid fa-plus`}
										style={{
											position: "inherit",
											color: lightMode === "dark" ? "white" : "inherit",
											width: "auto",
										}}></i>
									<h3 style={{ position: "inherit" }}>Save</h3>
								</div>
								{openCollectionsDropdown && (
									<>
										<div
											ref={ref}
											className={`${lightMode} preview-screen-collection-dropdown`}>
											<PreviewScreenDropdown
												collectionList={collectionList}
												screenId={screenList[currentScreenIndex].id}
												setNewCollectionList={(collections: Collection[]) => {
													setCollectionList(collections);
													setOpenCollectionsDropdown(false);
												}}
												closeCollectionDropdown={() =>
													setOpenCollectionsDropdown(false)
												}
											/>
										</div>
									</>
								)}
								{/* {openCollectionsDropdown && (
										<div className={`${lightMode} options-2-company options-2 page-preview`}>
											<CollectionsDropdown
												itemId={screenList[currentScreenIndex].id}
												selectedScreenIds={[screenList[currentScreenIndex].id]}
												type="multiple"
												collectionList={collectionList}
												addCollection={(coll: any) => addCollection(coll)}
												onBlur={() => {
													setOpenCollectionsDropdown(false);
												}}
												finished={() => finished()}
											/>
										</div>
									)} */}
							</div>
						</div>
					</div>
					<div className={`${lightMode} preview-screen-container`}>
						{/* <div
							className={`${lightMode} screen-info-img-container`}
							style={{
								backgroundImage: `url(${
									screenList[currentScreenIndex].presignedURL ?? undefined
								})`,
							}}> */}
						<div className={`${lightMode} screen-info-img-container`}>
							<img
								src={screenList[currentScreenIndex].presignedURL ?? undefined}
								className={`${lightMode} image`}
								alt="screen"
								id={screenList[currentScreenIndex].presignedURL ?? undefined}
								key={screenList[currentScreenIndex].presignedURL ?? undefined}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
