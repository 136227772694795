import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { closeNotification } from "../../store/slice/notificationSlice";
import closeIcon from "../../icons/close.svg";

const Notification = () => {
	const notification = useAppSelector((state) => state.notification);
	const dispatch = useAppDispatch();
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			{notification.notification.length > 0 && notification.isActive && (
				<div className={`${lightMode} notification-container`}>
					<div className={`${lightMode} update-toggle-container`}>
						<div className={`${lightMode} update-toggle`}></div>
					</div>
					{notification.notification}
					<div className={`${lightMode} notification-separator`}></div>
					<div
						onClick={() => {
							dispatch(closeNotification());
						}}>
						<img
							className={`${lightMode} notification-close-icon`}
							src={closeIcon}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default Notification;
