import NoResultsFound from "../../icons/NoResultsFound.svg";
import NoResultsFoundDark from "../../icons/NoResultsFoundDark.svg";
import { useAppSelector } from "../../store/hooks";
import "./NoResultsFoundPage.css";

const NoResultsFoundPage = () => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			<div className={`${lightMode} no-results-container`}>
				<div className={`${lightMode} no-results-img-container`}>
					<img
						style={{ height: "20px", width: "20px" }}
						src={lightMode === "light" ? NoResultsFound : NoResultsFoundDark}
					/>
				</div>
				<div className={`${lightMode} no-results-text`}>
					No results found here.
				</div>
				<div className={`${lightMode} no-results-subtext`}>
					Remove filters to show your items.
				</div>
			</div>
		</>
	);
};

export default NoResultsFoundPage;
