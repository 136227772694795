import { Fetch, FetchInput, Method } from "../../Fetch";
import { toWorkspaces, Workspace } from "../../types/workspace";

export interface FetchWorkspacesResponse {
  response: Workspace[];
  error: string;
}

export async function FetchWorkspaces(
  projectId: string,
  signal?: AbortSignal
): Promise<FetchWorkspacesResponse> {
  const FetchWorkspacesURL = `/me/workspace/all?projectId=${projectId}`;
  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchWorkspacesURL,
      signal: signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          response: await toWorkspaces(response.result.result),
          error: "",
        };
      }

      return {
        response: [],
        error: "Server side error",
      };
    } else {
      return {
        response: [],
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      response: [],
      error: "error",
    };
  }
}
