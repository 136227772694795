import { Fetch, FetchInput, Method } from "../../Fetch";

export interface UpdateCollectionRequest {
  id: string;
  name: string;
  signal?: AbortSignal;
}

export interface UpdateCollectionResponse {
  error: string;
}

export async function UpdateCollection(
  request: UpdateCollectionRequest
): Promise<UpdateCollectionResponse> {
  const UpdateCollectionURL = "/me/collection";
  const requestBody = JSON.stringify({
    id: request.id,
    name: request.name,
  });
  try {
    const input: FetchInput = {
      method: Method.Put,
      url: UpdateCollectionURL,
      signal: request.signal,
      body: requestBody,
    };
    const result = await Fetch(input);
    if (result.isOk) {
      const response = await result;
      if (response) {
        return {
          error: "",
        };
      }
      return {
        error: "Server side error",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
    };
  }
}
