import "./Navigation.css";
import Logo from "../../icons/favicon.png";

import {
	Outlet,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { GetUser } from "../../utils/backend/Profile/GetUser";
import { useEffect, useMemo, useRef, useState } from "react";
import { FetchWorkspaces } from "../../utils/backend/Workspace/FetchWorkspaces";
import upDown from "../../icons/up-down.svg";
import upDownDark from "../../icons/up-down-dark.svg";
import { GetAllProjects } from "../../utils/backend/Project/GetAllProjects";
import { DynamicIsland } from "../DynamicIsland";
import { FetchTags } from "../../utils/backend/Tag/FetchTags";
import { FetchAllTagTypes } from "../../utils/backend/TagType/FetchAllTagTypes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
	getInitialAllTagTypesWithTags,
	getTags,
	getTagsByType,
	getTagTypes,
	setAllTagTypesWithTags,
} from "../../store/slice/tagSlice";
import {
	historyPush,
	setBoardId,
	setLightMode,
	setProjectId,
} from "../../store/slice/navigationSlice";
import Avatar from "react-avatar";
import { removeUser, setUser } from "../../store/slice/authSlice";
import { Workspace } from "../../utils/types/workspace";
import {
	setProjects,
	setSelectedBoard,
	setSelectedProject,
	setWorkspaces,
} from "../../store/slice/workspacesSlice";
import { getImageFromCacheOrFromURL } from "../../utils/image";
import userIcon from "../../icons/user.svg";
import { setActiveFilters } from "../../store/slice/filtersSlice";
import DarkLogo from "../../icons/DarkLogo.svg";
import Loader from "../Loader/Loader";
import LightingModeSwitcher from "./LightingModeSwitcher";

const Navigation = () => {
	const [searchParams] = useSearchParams();
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const allBoards = useAppSelector((state) => state.boards.workspaces);
	const allProjects = useAppSelector((state) => state.boards.projects);
	const selectedBoard = useAppSelector((state) => state.boards.selectedBoard);
	const selectedProject = useAppSelector(
		(state) => state.boards.selectedProject
	);
	const [isAvatarOpen, setIsAvatarOpen] = useState(false);
	const navigate = useNavigate();
	const user = useAppSelector((state) => state.auth);
	const eTag = useAppSelector((state) => state.tags.tags);
	const allTagTypesWithTags = useAppSelector(
		(state) => state.tags.allTagTypesWithTags
	);
	const tagTypes = useAppSelector((state) => state.tags.tagTypes);
	const dispatch = useAppDispatch();
	const ref: any = useRef(null);
	const boardDropdownRef: any = useRef(null);
	const historyStore = useAppSelector((state) => state.navigation.history);
	const [boardDropdownOpen, setBoardDropdownOpen] = useState<boolean>(false);

	const isBrowserBackBtnActive = useAppSelector(
		(state) => state.navigation.browserBackButton
	);

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (
				boardDropdownRef.current &&
				!boardDropdownRef.current.contains(event.target as Node)
			) {
				setBoardDropdownOpen((prev) => !prev);
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	useEffect(() => {
		if (
			historyStore.length > 0 &&
			historyStore[historyStore.length - 1] === window.location.pathname
		) {
			return;
		} else {
			dispatch(setActiveFilters([]));
			dispatch(historyPush(window.location.pathname));
		}
	}, [navigate]);

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setIsAvatarOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	const handleLogOut = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("projectId");
		dispatch(removeUser());
		navigate("/SignIn");
	};

	useEffect(() => {
		if (allTagTypesWithTags.length === 0) {
			getInitialAllTagTypesWithTags().then((res) => {
				dispatch(setAllTagTypesWithTags(res));
			});
		}

		// reset tags every 5 minutes
		const interval = setInterval(() => {
			getInitialAllTagTypesWithTags().then((res) => {
				dispatch(setAllTagTypesWithTags(res));
			});
		}, 5 * 60 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [allTagTypesWithTags, dispatch]);

	useEffect(() => {
		if (user.id.length === 0) {
			GetUser()
				.then(async (data) => {
					if (!data.user) {
						navigate("/SignIn");
						return;
					}

					if (data.user.image) {
						data.user.imageData = await getImageFromCacheOrFromURL(
							data.user.image,
							data.user.id
						);
					}

					dispatch(setUser(data.user));
				})
				.catch((e) => {
					console.error(e);
				});
		}
	}, [dispatch, navigate, user.id.length]);

	const location = useLocation();

	useEffect(() => {
		const tagList = searchParams.get("tagList")?.split(",") || [];
		dispatch(setActiveFilters(tagList));
	}, [dispatch, location.pathname, searchParams]);

	useEffect(() => {
		if (allProjects.length > 0) return;
		GetAllProjects()
			.then((data) => {
				if (data.error.length > 0) {
					console.error(data.error);
					return;
				}

				if (data.response.length === 0) {
					navigate("/SetupProfile");
					return;
				}

				dispatch(setProjects(data.response));
				dispatch(setProjectId(data.response[0].id));
				localStorage.setItem("projectId", data.response[0].id);
				dispatch(setSelectedProject(data.response[0]));
			})
			.catch((e) => {
				console.error(e);
			});
	}, [allProjects.length, dispatch, navigate]);

	useEffect(() => {
		if (eTag.length < 1) {
			FetchTags().then((data) => {
				if (data.response) {
					dispatch(getTags(data.response));
				}
				if (data.error) {
					console.error(data.error);
				}
			});
		}
	}, [dispatch, eTag]);

	useEffect(() => {
		if (tagTypes.length < 1) {
			FetchAllTagTypes().then((data) => {
				if (data.response) {
					dispatch(getTagTypes(data.response));
				}
				if (data.error) {
					console.error(data.error);
				}
			});
		}
	}, [dispatch, tagTypes]);

	useEffect(() => {
		if (eTag.length > 0 && tagTypes.length > 0) {
			if (eTag.length > 0) {
				let tagByType: Array<any> = [];
				tagTypes.forEach((tagType: any) => {
					let filteredTags = eTag.filter(
						(tag: any) => tag.tagTypeId === tagType.id
					);
					let newTagByType = {
						type: tagType.name,
						tags: filteredTags,
					};
					tagByType.push(newTagByType);
				});
				dispatch(getTagsByType(tagByType));
			}
		}
	}, [dispatch, eTag, tagTypes]);

	useEffect(() => {
		if (!selectedProject) {
			return;
		}

		if (allBoards.length > 0) return;
		FetchWorkspaces(selectedProject.id)
			.then(async (data) => {
				for (const [index, w] of data.response.entries()) {
					if (!w.image) continue;
					const img = await getImageFromCacheOrFromURL(w.image, w.id);
					if (img) {
						data.response[index].imageData = img;
					}
				}

				dispatch(setWorkspaces(data.response));
				dispatch(setBoardId(data.response[0].id));
				dispatch(setSelectedBoard(data.response[0]));
			})
			.catch((e) => {
				console.error(e);
			});
	}, [allBoards.length, dispatch, selectedProject]);

	useEffect(() => {
		if (!selectedBoard) {
			return;
		}

		dispatch(setBoardId(selectedBoard.id));
	}, [dispatch, selectedBoard]);

	useEffect(() => {
		const allLinks = document.querySelectorAll(".sublink");
		const splittedUrl = window.location.href.split("/");
		allLinks.forEach((link) => {
			if (link.classList.contains("current-page")) {
				link.classList.remove("current-page");
			}

			for (let i = 0; i < splittedUrl.length; i++) {
				if (link.classList[0] === splittedUrl[i]) {
					link.classList.add("current-page");
				}
			}
		});
	}, []);

	window.addEventListener("keydown", function (e: any) {
		const searchElement = document.querySelector(".search") as HTMLInputElement;
		if (
			!(
				e.key === "a" ||
				(e.key === "A" && document.activeElement !== searchElement)
			)
		) {
			return;
		}

		const inputs = document.querySelectorAll("input");
		let isInInput = false;
		inputs.forEach((input) => {
			if (input.contains(e.target)) {
				isInInput = true;
			}
		});

		if (!isInInput) {
			e.preventDefault();
		}
	});
	const handleBoardClicked = (board: Workspace) => {
		setSelectedBoard(board);
		navigate(`/Navigation/BoardSettings/${board.id}`);
	};

	const pagesDropdown = document.querySelector(".pages-dropdown");
	const settingsDropdown = document.querySelector(".settings-dropdown");
	const navbar2 = document.querySelector(".navbar-2");
	const navbar1 = document.querySelector(".navbar-1");
	if (pagesDropdown) {
		pagesDropdown.addEventListener("click", () => {
			navbar1?.classList.toggle("hide");
			navbar2?.classList.toggle("hide");
		});
	}

	if (settingsDropdown) {
		settingsDropdown.addEventListener("click", () => {
			navbar2?.classList.toggle("hide");
			navbar1?.classList.toggle("hide");
		});
	}

	if (
		!selectedBoard ||
		!user ||
		allBoards.length === 0 ||
		allProjects.length === 0
	) {
		return null;
	}

	return (
		<div className={`base ${lightMode}`}>
			<div className={`${lightMode} header-container`}>
				<div className={`${lightMode} header`}>
					<div className={`${lightMode} left-side`}>
						<img
							src={lightMode === "light" ? Logo : DarkLogo}
							alt="uxmaps-logo"
							className={`${lightMode} uxmaps-logo`}
							onClick={() => navigate("/Navigation/Screens")}
						/>
						<div
							style={{ color: lightMode === "light" ? "#eaedf1" : "#3A3A3A" }}>
							/
						</div>
						<div className={`${lightMode} left-side-sub`}>
							<div
								className={`${lightMode} head-1`}
								onClick={() =>
									setBoardDropdownOpen(boardDropdownOpen ? false : true)
								}>
								{selectedBoard?.imageData ? (
									<img
										src={selectedBoard?.imageData}
										className={`${lightMode} current-board-image`}
										alt=""
									/>
								) : (
									<div className={`${lightMode} orange-circle`}></div>
								)}

								<div className={`${lightMode} current-board-name`}>
									{selectedBoard?.name}
								</div>
								<img
									src={upDown}
									className={`${lightMode} chevron-up-down`}
									alt=""
								/>
							</div>
							{boardDropdownOpen && (
								<div
									className={`${lightMode} options-1`}
									ref={boardDropdownRef}>
									<div className={`${lightMode} boards-header`}>
										<h4>Boards</h4>
									</div>
									<div className={`${lightMode} boards`}>
										{boardDropdownOpen &&
											allBoards.map((board) => {
												return (
													<button
														key={board.id}
														className={`${lightMode} board board-item`}
														onClick={() => {
															handleBoardClicked(board);
															setBoardDropdownOpen((prev) => !prev);
														}}>
														<img
															src={board.imageData ?? userIcon}
															alt="board-icon"
														/>
														<p className={`${lightMode} board-item-name`}>
															{" "}
															{board.name}
														</p>
													</button>
												);
											})}
									</div>
								</div>
							)}
						</div>
						<div className={`${lightMode} right-side-nav`}>
							<LightingModeSwitcher />
							{/* <button
								className={`${lightMode} tally-feedback-button`}
								data-tally-open="wbed71"
								data-tally-layout="modal"
								data-tally-width="650"
								data-tally-emoji-text="👋"
								data-tally-emoji-animation="wave"
								data-tally-auto-close="0">
								Feedback
							</button> */}
							{user.imageData ? (
								<img
									onClick={() => setIsAvatarOpen((prev) => !prev)}
									className={`${lightMode}
										${isAvatarOpen ? "avatar active image" : "avatar image"}
									`}
									src={user.imageData}
								/>
							) : (
								<Avatar
									name={
										user.id.length === 0 && !user.image
											? ""
											: user.fullName
											? user.fullName[0].toUpperCase()
											: user?.email[0].toUpperCase()
									}
									initials={
										user.id.length === 0 && !user.image
											? ""
											: user.fullName
											? user.fullName[0].toUpperCase()
											: user?.email[0].toUpperCase()
									}
									className={isAvatarOpen ? "avatar active ium" : "avatar"}
									maxInitials={1}
									textSizeRatio={2}
									color="#f2f4f7"
									size="32.6px"
									style={{ borderRadius: "16.8px" }}
									fgColor="rgba(71, 84, 103, 1)"
									src={user.imageData ?? ""}
									onClick={() => {
										setIsAvatarOpen((prev) => !prev);
									}}
								/>
							)}
							{isAvatarOpen && (
								<>
									<div
										className={`${lightMode} avatar-dropdown-menu`}
										ref={ref}>
										<div className={`${lightMode} avatar-dropdown-email`}>
											{user.email}
										</div>
										<div
											className={`${lightMode} avatar-dropdown-fields`}
											onClick={() => {
												navigate("/Navigation/Collections");
												setIsAvatarOpen(false);
											}}>
											Collections
										</div>
										<div
											className={`${lightMode} avatar-dropdown-fields`}
											onClick={() => {
												navigate("/Navigation/ProfileSettings");
												setIsAvatarOpen(false);
											}}>
											Settings
										</div>
										<div
											style={{ borderRadius: "0 0 8px 8px" }}
											className={`${lightMode} avatar-dropdown-fields`}
											data-tally-open="wbed71"
											data-tally-layout="modal"
											data-tally-width="650"
											data-tally-emoji-text="👋"
											data-tally-emoji-animation="wave"
											data-tally-auto-close="0">
											Feedback
										</div>
										<div
											style={{ borderRadius: "0 0 8px 8px" }}
											className={`${lightMode} avatar-dropdown-fields`}
											onClick={handleLogOut}>
											Log out
										</div>
									</div>
								</>
							)}
						</div>
					</div>
					<DynamicIsland />
				</div>
			</div>
			<div className={`${lightMode} page`}>
				<div className={`${lightMode} banner hide-banner`}></div>
				<Outlet />
			</div>
		</div>
	);
};
export default Navigation;
