import React, { useEffect, useState } from "react";
import Logo from "../../icons/favicon.png";
import { useNavigate } from "react-router-dom";
import "./SetupProfile.css";
import arrow from "../../icons/arrow-right-white.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSetupData } from "../../store/slice/setupSlice";
import TestDriveScreen from "../TestDriveScreen/TestDriveScreen";

const roleOptionList: string[] = [
	"Entrepreneur",
	"Marketer",
	"Copywriter",
	"Designer",
	"Developer",
	"Manager",
];

const industryOptionList: string[] = [
	"Ecommerce",
	"Agency",
	"Software",
	"Dropshipping",
	"Freelance",
];

function SetupProfile() {
	const [showTestDriveScreen, setShowTestDriveScreen] = useState(true);
	const navigate = useNavigate();
	const [role, setRole] = useState<string>();
	const [industry, setIndustry] = useState<string>();
	const user = useAppSelector((state) => state.auth);
	const [website, setWebsite] = useState<string>(user.website ?? "");
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (user.email === "") {
			navigate("/SignIn");
		}
	}, [navigate, user]);

	const roleOptions = () => {
		const result: JSX.Element[] = [];
		result.push(
			<option value="" className={`${lightMode} role`} disabled key={""}>
				Where do you work?
			</option>
		);
		result.push(
			...roleOptionList.map((r) => {
				return (
					<option key={r} value={r} className={`${lightMode} role`}>
						{r}
					</option>
				);
			})
		);

		return result;
	};

	const industryOptions = () => {
		const result: JSX.Element[] = [];
		result.push(
			<option value="" className={`${lightMode} role`} disabled key={"1"}>
				Select one
			</option>
		);
		result.push(
			...industryOptionList.map((r) => {
				return (
					<option key={r} value={r} className={`${lightMode} role`}>
						{r}
					</option>
				);
			})
		);

		return result;
	};

	if (showTestDriveScreen)
		return (
			<TestDriveScreen
				onContinue={() => {
					setShowTestDriveScreen(false);
				}}
			/>
		);

	return (
		<div className={`${lightMode} setup-profile-page`}>
			<img src={Logo} className={`${lightMode} uxmaps-logo sign-page`}></img>
			<div className={`${lightMode} setup-profile`}>
				<div className={`${lightMode} top`}>
					<h1 className={`${lightMode} setup-profile-title`}>
						Tell us about yourself
					</h1>
					<h2 className={`${lightMode} wb`}>
						Nothing required, but it helps your overall experience.
					</h2>
				</div>

				<div className={`${lightMode} form`}>
					<label htmlFor="role" className={`${lightMode} label pass-label`}>
						Role
					</label>
					<select
						name="role"
						value={role}
						onChange={(e) => {
							setRole(e.target.value);
						}}
						defaultValue={""}>
						{roleOptions()}
					</select>
					<label htmlFor="role" className={`${lightMode} label pass-label`}>
						Industry
					</label>
					<select
						name="industry"
						value={industry}
						onChange={(e) => {
							setIndustry(e.target.value);
						}}
						defaultValue={""}>
						{industryOptions()}
					</select>
				</div>
				<label htmlFor="website" className={`${lightMode} label pass-label`}>
					Website
				</label>
				<div className={`${lightMode} input-container`}>
					<h3 className={`${lightMode} www`}>www.</h3>
					<input
						type="text"
						id="website"
						className={`${lightMode} website`}
						onChange={(e) => {
							setWebsite(e.target.value);
						}}
						placeholder="example.com"
						value={website ?? ""}
					/>
				</div>
				<div className={`${lightMode} continue-skip`}>
					<button
						className={`${lightMode} continue-btn`}
						onClick={() => {
							dispatch(
								setSetupData({
									industry: industry,
									role: role,
									website: website,
								})
							);

							navigate("/SetupTeam");
						}}>
						Continue
						<img src={arrow} alt="arrow-right" />
					</button>
				</div>
			</div>
		</div>
	);
}

export default SetupProfile;
