import { Fetch, FetchInput, Method } from "../../Fetch";

export interface ForgotPasswordResponse {
  error: string;
  isOk: boolean;
}

export const ForgotPassword = async (
  email: string
): Promise<ForgotPasswordResponse> => {
  const url = "/public/user/forgot_password";
  const requestBody = JSON.stringify({
    email: email,
  });
  try {
    const input: FetchInput = {
      method: Method.Post,
      url: url,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      return {
        isOk: true,
        error: "",
      };
    } else {
      return {
        isOk: false,
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      isOk: false,
      error: "error",
    };
  }
};
