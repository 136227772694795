import React, { useEffect } from "react";
import "./App.css";
import Verification from "./components/Verification/Verification";
import SignIn from "./components/SignIn/SignIn";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import Companies from "./components/companies/Companies/Companies";
import CompanyInfo from "./components/companies/CompanyInfo/CompanyInfo";
import Flows from "./components/flows/Flows/Flows";
import Collections from "./components/collections/Collections/Collections";
import CollectionInfo from "./components/collections/CollectionInfo/CollectionInfo";
import BoardSettings from "./components/BoardSettings/BoardSetings";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import CreateNewPassword from "./components/CreateNewPassword/CreateNewPassword";
import Screens from "./components/screens/Screens/Screens";
import FlowPage from "./components/flows/FlowPage/FlowPage";
import DesignElements from "./components/design_elements/DesignElements/DesignElements";
import DesignElementPage from "./components/design_elements/DesignElementPage/DesignElementPage";
import ProfileSettings from "./components/ProfileSettings/ProfileSettings";
import SetupTeam from "./components/SetupTeam/SetupTeam";
import Notification from "./components/Navigation/Notification";
import SetupProfile from "./components/SetupProfile/SetupProfile";
import ProtectedRoute from "./utils/ProtectedRoute";
import ReactGA from "react-ga4";
import SourcePage from "./components/common/SourcePage/SourcePage";
import ProductPage from "./components/common/ProductPage/ProductPage";
import SignPage from "./components/SignIn/SignPage";

function App() {
	useEffect(() => {
		ReactGA.initialize(process.env.GA_MEASUREMENT_ID ?? "G-Q69NX5C5NM");
	}, []);

	const location = useLocation();

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: location.pathname,
			title: "Page View",
		});
	}, [location.pathname]);

	return (
		<div>
			<Routes>
				<Route path="/" index element={<SignPage />} />
				<Route path="/SetupProfile" element={<SetupProfile />} />
				<Route path="/SetupTeam" element={<SetupTeam />} />
				<Route path="/verification" element={<Verification />} />
				<Route path="/SignIn" element={<SignPage />} />
				<Route path="/SignIn/ForgotPassword" element={<ForgotPassword />} />
				<Route path="/SignIn/ResetPassword" element={<CreateNewPassword />} />
				<Route
					path="/Navigation"
					element={
						<ProtectedRoute>
							<Navigation />
						</ProtectedRoute>
					}>
					<Route
						index
						path="/Navigation/Flows"
						element={
							<ProtectedRoute>
								<Flows />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/Flows/FlowInfo"
						element={
							<ProtectedRoute>
								<ProductPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/Screens"
						element={
							<ProtectedRoute>
								<Screens />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/Companies"
						element={
							<ProtectedRoute>
								<SourcePage />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/Navigation/Companies/CompanyInfo"
						element={
							<ProtectedRoute>
								<ProductPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/Design"
						element={
							<ProtectedRoute>
								<SourcePage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/Design/Element"
						element={
							<ProtectedRoute>
								<ProductPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/BoardSettings"
						element={
							<ProtectedRoute>
								<BoardSettings />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/BoardSettings/:id"
						element={
							<ProtectedRoute>
								<BoardSettings />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/ProfileSettings"
						element={
							<ProtectedRoute>
								<ProfileSettings />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/Collections"
						element={
							<ProtectedRoute>
								<Collections />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/Navigation/Collections/CollectionInfo"
						element={
							<ProtectedRoute>
								<CollectionInfo />
							</ProtectedRoute>
						}
					/>
				</Route>
				{/*<Route path="/invitation" element={<ProtectedRoute></ProtectedRoute><AcceptInvitation />} /> */}
				<Route
					path="*"
					element={
						<ProtectedRoute>
							<Navigate to="/Navigation/Screens" />
						</ProtectedRoute>
					}
				/>
			</Routes>
			<Notification />
		</div>
	);
}

export default App;
