import { useEffect, useRef, useState } from "react";
import Screen from "../Screen/Screen";
import { Tag } from "../../../utils/types/tag";
import { Screen as ScreenType } from "../../../utils/types/screen";
import { queryData } from "../../../utils/types/queryData";
import { PreviewScreen } from "../../common/previewScreen/PreviewScreen";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchScreens } from "./initialize";
import {
	arraysHaveSameElements,
	usePrevious,
} from "../../../utils/usePrevious";
import ErrorPage from "../../ErrorPage/ErrorPage";
import Loader from "../../Loader/Loader";
import NoResultsFoundPage from "../../NoResultsFoundPage/NoResultsFoundPage";
import { getAllCollections } from "../../companies/CompanyInfo/initializeData";
import { Collection } from "../../../utils/types/collection";
import { setIsPreviewScreenOpen } from "../../../store/slice/navigationSlice";
import Filters from "../../FiltersComponent/Filters";
import { StyledGridDivFlowPage } from "../../flows/Flows/Flows.style";

const Screens = () => {
	const allTagTypesWithTags = useAppSelector(
		(state) => state.tags.allTagTypesWithTags
	);
	const [screens, setScreens] = useState<ScreenType[]>([]);
	const [companyTags, setCompanyTags] = useState<Tag[]>([]);
	const [queryState, setQueryState] = useState<queryData>({
		limit: 36,
		page: 0,
	});
	const [isLastPage, setIsLastPage] = useState<boolean>(false);
	const [error, setError] = useState<any>();
	const [sliderOpened, setSliderOpened] = useState(false);
	const [currentScreenIndex, setCurrentScreenIndex] = useState<
		number | undefined
	>();
	const activeFilters = useAppSelector((state) => state.filters.activeFilters);
	const shouldDisplayFilters: any = useAppSelector(
		(state) => state.filters.isFilterOpened
	);
	const [seed, setSeed] = useState(Math.random());

	const previousActiveFilters = usePrevious<string[]>(activeFilters);
	const previousQueryState = usePrevious<queryData>(queryState);
	const [isLoadingMore, setIsLoadingMore] = useState(true);
	const [areCollectionsFetched, setAreCollectionsFetched] = useState(false);
	const [collectionList, setCollectionList] = useState<Collection[]>([]);
	const dispatch = useAppDispatch();
	const [innerWidth, setInnerWidth] = useState<number>(
		document.documentElement.clientWidth
	);

	const handleResize = () => {
		setInnerWidth(document.documentElement.clientWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const loader = useRef(null);

	const fetchScreensByActiveFilter = async () => {
		const controller = new AbortController();
		const signal = controller.signal;

		const filterString: Array<string> = [];
		const industryTagList = allTagTypesWithTags.find(
			(tag) => tag.tagType.name === "Industry"
		)?.tagList;
		const companiesTagList = allTagTypesWithTags.find(
			(tag) => tag.tagType.name === "Company"
		)?.tagList;
		const query: queryData = {
			page: queryState.page,
			limit: 108,
		};

		activeFilters.map((filter) => {
			if (industryTagList?.find((tag) => tag.id === filter)) {
				companiesTagList?.map((company) => {
					if (company.tagList.find((t) => t.id === filter)) {
						filterString.push(company.id);
					}
				});
			} else {
				filterString.push(filter);
			}
		});

		await fetchScreens({
			queryState,
			setScreens,
			activeFilters: filterString,
			signal,
			setError,
			setIsLastPage,
			seedValue: seed,
		});
	};

	useEffect(() => {
		if (loader.current && screens.length > 0) {
			const options = {
				root: null,
				rootMargin: "20px",
				threshold: 1.0,
			};
			const observer = new IntersectionObserver(handleObserver, options);
			observer.observe(loader.current);

			return () => {
				if (loader && loader.current) {
					observer.unobserve(loader.current);
				}
			};
		}
	}, [screens]);

	const handleObserver = (entities: IntersectionObserverEntry[]) => {
		const target = entities[0];
		if (target.isIntersecting && screens.length > 0) {
			handleLoadMore();
		}
	};

	useEffect(() => {
		if (!areCollectionsFetched) {
			getAllCollections({
				setError: setError,
				setCollections: setCollectionList,
			}).catch((e) => {
				console.error(e);
				setError("Unable to get data. Try again or contact us");
			});
			setAreCollectionsFetched(true);
		}
	}, []);

	useEffect(() => {
		if (
			!!previousActiveFilters &&
			arraysHaveSameElements(previousActiveFilters, activeFilters)
		)
			return;

		if (activeFilters.length !== 0) {
			setQueryState({
				page: 0,
				limit: 36,
			});
			setSeed(Math.random());
		}
		setScreens([]);
	}, [activeFilters, previousActiveFilters]);

	useEffect(() => {
		if (
			!!previousQueryState &&
			queryState.page === previousQueryState.page &&
			queryState.limit === previousQueryState.limit &&
			!!previousActiveFilters &&
			arraysHaveSameElements(activeFilters, previousActiveFilters)
		)
			return;

		setIsLoadingMore(true);
		setError(undefined);
		if (
			screens.length === 0 &&
			activeFilters.length > 0 &&
			previousActiveFilters === undefined
		)
			return;

		if (
			(queryState.page + 1) * 36 >= screens.length ||
			(screens.length === 0 && activeFilters.length === 0)
		) {
			fetchScreensByActiveFilter()
				.catch((e) => {
					console.error(e);
					setError("Can't get data from server");
				})
				.finally(() => {
					setIsLoadingMore(false);
				});
		}
	}, [activeFilters, queryState, previousActiveFilters, previousQueryState]);

	useEffect(() => {
		setCompanyTags(
			allTagTypesWithTags.find((tt) => {
				return tt.tagType.name === "Company";
			})?.tagList ?? []
		);
	}, [allTagTypesWithTags]);

	const collectionChangedFunc = (
		screenId: string,
		collectionId: string,
		type: string
	) => {
		if (!screens) return;
		if (type === "add") {
			const update = screens;
			update.forEach((screen) => {
				if (screen.id === screenId) {
					screen.collectionIdList.push(collectionId);
				}
			});
			setScreens(update);
		} else if (type === "remove") {
			const update = screens;
			update.forEach((screen) => {
				if (screen.id === screenId) {
					screen.collectionIdList = screen.collectionIdList.filter(
						(id: string) => {
							return id !== collectionId;
						}
					);
				}
			});
			setScreens(update);
		}
	};

	const displayScreens = screens.map((screen: ScreenType, index: number) => {
		let companyName = "";
		let companyIcon = "";

		companyTags.forEach((company: Tag) => {
			const foundCompany = screen.tagList.find((t) => {
				return (
					t.id === company.id && company.noOfScreens && company.noOfScreens > 0
				);
			});
			if (foundCompany) {
				companyIcon = company.imageS3URL || "";
				companyName = company.name;
				return;
			}
		});
		if (index < (queryState.page + 1) * 36) {
			return (
				<Screen
					key={screen.id}
					screen={screen}
					companyName={companyName}
					companyIcon={companyIcon}
					collectionChangedFunc={collectionChangedFunc}
					onClicked={() => {
						setSliderOpened(true);
						setCurrentScreenIndex(index);
					}}
				/>
			);
		}
	});

	const handleLoadMore = async () => {
		setIsLoadingMore(true);

		if (isLastPage) return;
		setQueryState((prev) => {
			return {
				limit: prev.limit,
				page: prev.page + 1,
			};
		});
	};

	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<div className={`${lightMode} pagee`}>
			{screens.length > 0 && (
				<PreviewScreen
					screenList={screens}
					opened={sliderOpened}
					collectionList={collectionList}
					setCollectionList={setCollectionList}
					setCurrentScreenIndex={setCurrentScreenIndex}
					handleClose={() => {
						dispatch(setIsPreviewScreenOpen(false));
						document.body.style.overflowY = "auto";
						setSliderOpened(false);
					}}
					currentScreenIndex={currentScreenIndex}
					company={companyTags.find((company) =>
						screens[currentScreenIndex ?? 0].tagList.find(
							(tag: Tag) => tag.id === company.id
						)
					)}
				/>
			)}
			<div
				className={`${lightMode} grid-container`}
				id="grid-container"
				style={
					shouldDisplayFilters
						? { display: "flex", padding: "0 32px" }
						: { padding: "0 32px" }
				}>
				{screens.length === 0 && isLoadingMore ? (
					<Loader />
				) : screens.length === 0 && !isLoadingMore && !error ? (
					<NoResultsFoundPage />
				) : error && error.length > 0 ? (
					<ErrorPage />
				) : (
					<StyledGridDivFlowPage
						innerWidth={innerWidth}
						areFiltersOpen={shouldDisplayFilters}>
						{displayScreens}
					</StyledGridDivFlowPage>
				)}
				<Filters displaysFrom="product" />
			</div>
			<div ref={loader} style={{ height: "10px" }}></div>
		</div>
	);
};
export default Screens;
