import collectionImg from "../../../icons/CollectionImg.svg";
import "./Collection.css";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../icons/image-loader.png";
import { Collection as CollectionType } from "../../../utils/types/collection";
import { StyledSourceImageBackground } from "../../common/SourcePage/SourcePage.styles";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";

interface CollectionProps {
	collection: CollectionType;
}

const Collection = ({ collection }: CollectionProps) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const navigate = useNavigate();
	const [innerWidth, setInnerWidth] = useState<number>(
		document.documentElement.clientWidth
	);

	const handleResize = () => {
		setInnerWidth(document.documentElement.clientWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div
			className={`${lightMode} collection-item`}
			onClick={() => {
				navigate(
					`/Navigation/Collections/CollectionInfo?collection-id=${collection.id}`
				);
				window.scrollTo(0, 0);
			}}>
			<div className={`${lightMode} company-screens`}>
				<StyledSourceImageBackground
					innerWidth={innerWidth}
					areFiltersOpen={false}
					className={`${lightMode} four-image-background`}
					style={{
						backgroundImage: `url(${
							collection.contentList.length > 0 &&
							collection.contentList[0].presignedURLResized
								? collection.contentList[0].presignedURLResized
								: defaultImage
						})`,
					}}
				/>
				<StyledSourceImageBackground
					innerWidth={innerWidth}
					areFiltersOpen={false}
					className={`${lightMode} four-image-background`}
					style={{
						backgroundImage: `url(${
							collection.contentList.length > 1 &&
							collection.contentList[1].presignedURLResized
								? collection.contentList[1].presignedURLResized
								: defaultImage
						})`,
					}}
				/>

				<StyledSourceImageBackground
					innerWidth={innerWidth}
					areFiltersOpen={false}
					className={`${lightMode} four-image-background`}
					style={{
						backgroundImage: `url(${
							collection.contentList.length > 2 &&
							collection.contentList[2].presignedURLResized
								? collection.contentList[2].presignedURLResized
								: defaultImage
						})`,
					}}
				/>

				<StyledSourceImageBackground
					innerWidth={innerWidth}
					areFiltersOpen={false}
					className={`${lightMode} four-image-background`}
					style={{
						backgroundImage: `url(${
							collection.contentList.length > 3 &&
							collection.contentList[3].presignedURLResized
								? collection.contentList[3].presignedURLResized
								: defaultImage
						})`,
					}}
				/>
			</div>

			<div className={`${lightMode} collection-info`}>
				<img src={collectionImg} alt="" />
				<div className={`${lightMode} collection-details`}>
					<h2>{collection.name}</h2>
					<h3>{collection.contentList.length} screens</h3>
				</div>
			</div>
		</div>
	);
};
export default Collection;
