import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";

const ProtectedRoute = (props: any) => {
	const navigate = useNavigate();
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const checkUserToken = () => {
		const userToken = localStorage.getItem("token");
		if (!userToken || userToken === "undefined") {
			setIsLoggedIn(false);
			return navigate("/SignIn");
		}
		setIsLoggedIn(true);
	};

	useEffect(() => {
		checkUserToken();
	}, [isLoggedIn]);

	return <>{isLoggedIn ? props.children : null}</>;
};
export default ProtectedRoute;
