import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Workspace } from "../../utils/types/workspace";
import { Project } from "../../utils/types/project";

interface state {
  workspaces: Workspace[];
  projects: Project[];
  selectedBoard?: Workspace;
  selectedProject?: Project;
}

const initialState: state = {
  workspaces: [],
  projects: [],
};

export const boardsSlice = createSlice({
  name: "boards",
  initialState,
  reducers: {
    setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      state.workspaces = action.payload;
    },
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.projects = action.payload;
    },
    setSelectedProject: (state, action: PayloadAction<Project>) => {
      state.selectedProject = action.payload;
    },
    setSelectedBoard: (state, action: PayloadAction<Workspace>) => {
      state.selectedBoard = action.payload;
    },
  },
});

export const {
  setWorkspaces,
  setProjects,
  setSelectedProject,
  setSelectedBoard,
} = boardsSlice.actions;

export default boardsSlice.reducer;