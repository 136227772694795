import threeDots from "../../../icons/three-dots-black.svg";
import icon from "../../../icons/CollectionImg.svg";
import "./CollectionInfo.css";
import EditCollection from "./EditCollection";
import {
	TagByTagType,
	TagTypeType,
} from "../../../utils/backend/Company/TagByTagType";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GetCollectionById } from "../../../utils/backend/Collection/GetCollectionById";
import CollectionItem from "../CollectionItem/CollectionItem";
import { validate as isValidUUID } from "uuid";
import { Collection } from "../../../utils/types/collection";
import { Screen } from "../../../utils/types/screen";
import { PreviewScreen } from "../../common/previewScreen/PreviewScreen";
import { getAllCollections } from "./initializeData";
import { Tag } from "../../../utils/types/tag";
import MoreDropdown from "../../Dropdowns/MoreDropdown";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setIsPreviewScreenOpen } from "../../../store/slice/navigationSlice";
import ErrorPage from "../../ErrorPage/ErrorPage";
import Loader from "../../Loader/Loader";
import CompanyItem from "../../companies/CompanyItem/CompanyItem";
import ProductPageHeader from "../../common/ProductPage/ProductPageHeader";
import { StyledGridDiv } from "../../common/ProductPage/ProductPage.styles";
import NoResultsFoundPage from "../../NoResultsFoundPage/NoResultsFoundPage";

const CollectionInfo = () => {
	const [searchParams] = useSearchParams();
	const [invalidCollectionId, setInvalidCollectionId] = useState(false);
	const [error, setError] = useState("");
	const [collection, setCollection] = useState<Collection>();
	const [companyTags, setCompanyTags] = useState<Tag[]>([]);
	const [isFetched, setIsFetched] = useState(false);

	const [collectionList, setCollectionList] = useState<Collection[]>([]);

	useEffect(() => {
		const collectionId = searchParams.get("collection-id");
		if (!collectionId || !isValidUUID(collectionId)) {
			setInvalidCollectionId(true);
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;

		async function init(collectionId: string) {
			try {
				const res = await GetCollectionById({
					id: collectionId,
					signal: signal,
				});

				if (res.error.length > 0) {
					setError(
						"Something unexpected happened, try to reload page or contact us."
					);
					return;
				}

				if (!res.collection) {
					setError("Collection not found.");
					return;
				}

				setCollection(res.collection);
				setIsFetched(true);
				const data = await TagByTagType(TagTypeType.TagTypeCompany, signal);
				setCompanyTags(data.tags);
			} catch (e) {
				setError(
					"Something unexpected happened, try to reload page or contact us."
				);
			}
		}

		if (!isFetched) {
			init(collectionId);
		}

		return () => {
			controller.abort();
		};
	}, [searchParams]);

	if (invalidCollectionId) {
		return (
			<div>
				Looks like you entered or copied wrong URL, please check or contact us.{" "}
			</div>
		);
	}

	if (error.length > 0) {
		return <ErrorPage />;
	}

	if (!collection) {
		return <Loader />;
	}

	return (
		<DisplayCollectionInfo
			collection={collection}
			companyTags={companyTags ?? []}
			setCollection={setCollection}
			collectionList={collectionList}
			setCollectionList={setCollectionList}
			setError={setError}
		/>
	);
};

interface DisplayCollectionInfoProps {
	collection: Collection;
	companyTags: Tag[];
	collectionList: Collection[];
	setCollectionList: React.Dispatch<React.SetStateAction<Collection[]>>;
	setError: (err: string) => void;

	setCollection: (data: Collection) => void;
}

const DisplayCollectionInfo = ({
	collection,
	companyTags,
	setCollection,
	setCollectionList,
	collectionList,
	setError,
}: DisplayCollectionInfoProps) => {
	const navigate = useNavigate();
	const [sliderOpened, setSliderOpened] = useState(false);
	const [editCollectionOpened, setEditCollectionOpened] = useState(false);
	const [currentScreen, setCurrentScreen] = useState<number | undefined>();

	const collectionChangedFunc = (
		screenId: string,
		collectionId: string,
		type: string
	) => {
		if (type === "add") {
			const update = collection.contentList;
			update.forEach((screen) => {
				if (screen.id === screenId) {
					screen.collectionIdList.push(collectionId);
				}
			});
			setCollection({
				...collection,
				contentList: update,
			});
		} else if (type === "remove") {
			const update = collection.contentList;

			update.forEach((screen) => {
				if (screen.id === screenId) {
					screen.collectionIdList = screen.collectionIdList.filter(
						(id: string) => {
							return id !== collectionId;
						}
					);
				}
			});
			setCollection({
				...collection,
				contentList: update,
			});
		}
	};

	useEffect(() => {
		getAllCollections({
			setError: setError,
			setCollections: setCollectionList,
		});
	}, []);

	const displayCollectionItems = collection.contentList.map(
		(item: Screen, index: number) => {
			let companyName = "";
			let companyIcon = "";
			companyTags.forEach((company: any) => {
				item.tagList.forEach((tag: any) => {
					if (tag.id === company.id) {
						companyName = company.name;
						companyIcon = company.image;
					}
				});
			});

			return (
				<CompanyItem
					name={item.name}
					id={item.id}
					image={item.presignedURLResized ?? item.presignedURL}
					key={item.id}
					companyName={companyName}
					companyIcon={companyIcon}
					collectionList={item.collectionIdList}
					collectionChangedFunc={collectionChangedFunc}
					handleClick={() => {
						setSliderOpened(true);
						setCurrentScreen(index);
					}}
				/>
			);
		}
	);

	const displayScreens = () => {
		return collection.contentList.map((screen, index) => {
			let company = null;
			// if (window.location.pathname.includes("/Design/Element")) {
			// 	const companyId = screen.tagList.find(
			// 		(tag) => tag.tagTypeId === companyTagTypeId
			// 	)?.id;
			// 	company = companies?.tagList.find((comp) => comp.id === companyId);
			// }

			return (
				<CompanyItem
					name={screen.name}
					id={screen.id}
					image={screen.presignedURLResized ?? screen.presignedURL}
					key={screen.id}
					companyName={""}
					companyIcon={""}
					collectionList={screen.collectionIdList}
					collectionChangedFunc={collectionChangedFunc}
					handleClick={() => {
						setSliderOpened(true);
						setCurrentScreen(index);
					}}
				/>
			);
		});
	};

	const dispatch = useAppDispatch();
	const [openOptionsDropdown, setOpenOptionsDropdown] = useState(false);
	const [innerWidth, setInnerWidth] = useState<number>(
		document.body.clientWidth
	);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const handleResize = () => {
		setInnerWidth(document.body.clientWidth);
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className={`${lightMode} company-page pagee`}>
			<EditCollection
				closed={!editCollectionOpened}
				setClosed={setEditCollectionOpened}
				collection={collection}
				onUpdateSuccessfully={setCollection}
			/>
			{collection.contentList.length > 0 && (
				<PreviewScreen
					opened={sliderOpened}
					screenList={collection.contentList}
					handleClose={() => {
						setSliderOpened(false);
						dispatch(setIsPreviewScreenOpen(false));
					}}
					setCurrentScreenIndex={setCurrentScreen}
					currentScreenIndex={currentScreen}
					collectionList={collectionList}
					setCollectionList={setCollectionList}
					company={companyTags.find((company) =>
						collection.contentList[currentScreen ?? 0].tagList.find(
							(tag: Tag) => tag.id === company.id
						)
					)}
				/>
			)}
			<div className={`${lightMode} tags-info-header`}>
				<div className={`${lightMode} info`} style={{ marginBottom: "22px" }}>
					<img
						className={`${lightMode} info image`}
						style={{ border: 0 }}
						src={icon}
						alt=""
					/>
					<span className={`${lightMode} details`}>
						<h2 style={{ marginBottom: "2px" }}>
							{collection && collection.name}
						</h2>
						<h3>{collection.contentList.length} screens</h3>
					</span>

					<div className={`${lightMode} path`}>
						<h3
							className={`${lightMode} link-name first`}
							onClick={() => {
								navigate("/Navigation/Collections");
							}}>
							Collections{" "}
						</h3>
						<h3 className={`${lightMode} first`}> / </h3>
						<h3 className={`${lightMode} collection-name second`}>
							{" "}
							{collection.name}
						</h3>
					</div>
				</div>
			</div>
			<div className={`${lightMode} grid-container`} id="grid-container">
				<div className={`${lightMode} grid`}>
					{collection.contentList.length > 0 ? (
						<StyledGridDiv innerWidth={innerWidth} areFiltersOpen={false}>
							{displayScreens()}
						</StyledGridDiv>
					) : (
						<NoResultsFoundPage />
					)}
				</div>
			</div>
		</div>
	);
};
export default CollectionInfo;
