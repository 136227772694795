import "./Screen.css";
import { useEffect, useRef, useState } from "react";
import { Screen as ScreenType } from "../../../utils/types/screen";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { StyledScreenImage } from "../../common/ProductPage/ProductPage.styles";

interface ScreenProps {
	screen: ScreenType;
	companyIcon: string;
	companyName: string;
	collectionChangedFunc: (
		screenId: string,
		collectionId: string,
		type: string
	) => void;
	onClicked: () => void;
	selectingScreens?: (id: string) => void;
	selectingMode?: boolean;
}

const Screen = ({
	screen,
	companyIcon,
	companyName,
	collectionChangedFunc,
	onClicked,
	selectingScreens,
	selectingMode,
}: ScreenProps) => {
	const areFiltersOpen = useAppSelector(
		(state) => state.filters.isFilterOpened
	);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const checkboxRef: any = useRef();
	const dispatch = useAppDispatch();
	const [innerWidth, setInnerWidth] = useState<number>(
		document.documentElement.clientWidth
	);

	const handleResize = () => {
		setInnerWidth(document.documentElement.clientWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div
			className={`${lightMode} item-${screen.id} company item`}
			onClick={onClicked}>
			<input
				type="checkbox"
				ref={checkboxRef}
				className={`checkbox ${selectingMode === true ? "" : "hide-checkbox"}`}
				onClick={(e) => {
					e.stopPropagation();
					if (selectingScreens) {
						selectingScreens(screen.id);
					}
				}}
			/>
			<StyledScreenImage
				areFiltersOpen={areFiltersOpen}
				innerWidth={innerWidth}
				style={{
					backgroundImage: `url(${
						screen.presignedURLResized ?? screen.presignedURL
					})`,
				}}
				className={`${lightMode} screen-image`}
			/>
			<div className={`${lightMode} company-info`}>
				<img src={companyIcon} />
				<div className={`${lightMode} screen-text`}>
					<h3 className={`${lightMode} screen-name`}>{screen.name}</h3>
					<h3 className={`${lightMode} screen-company`}>{companyName}</h3>
				</div>
			</div>
		</div>
	);
};
export default Screen;
