import Joi from "joi";

export interface User {
	fullName?: string | null;
	website?: string | null;
	id: string;
	image?: string | null;
	imageData?: string;
	email: string;
	rememberMe?: boolean;
}

export async function toUser(data: any): Promise<User> {
	const validationResult = await UserSchema.validate(data);
	if (validationResult.error) {
		throw new Error(validationResult.error.message);
	}

	return Object.assign({}, data) as User;
}

const UserSchema = Joi.object({
	fullName: Joi.string().allow(null, "").optional(),
	id: Joi.string().required().uuid(),
	image: Joi.string().allow(null, "").optional(),
	email: Joi.string().required(),
	role: Joi.string().required(),
}).unknown();
