import { Fetch, FetchInput, Method } from "../../Fetch";

export interface ResendVerificationEmailRequest {
  email: string;
  signal?: AbortSignal;
}

export interface ResendVerificationEmailResponse {
  error: string;
  isOk: boolean;
}

export async function FetchResendVerificationEmail(
  request: ResendVerificationEmailRequest
): Promise<ResendVerificationEmailResponse> {
  const ResendVerificationEmailURL = "/public/user/resend_confirmation_email";
  const requestBody = JSON.stringify({
    email: request.email,
  });
  try {
    const input: FetchInput = {
      method: Method.Post,
      url: ResendVerificationEmailURL,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          isOk: response.isOk,
          error: "",
        };
      }

      return {
        isOk: false,
        error: "Server side error",
      };
    } else {
      return {
        isOk: false,
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      isOk: false,
      error: "error",
    };
  }
}
