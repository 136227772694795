import Collection from "../collection/Collection";
import "./Collections.css";
import { useEffect, useState } from "react";
import { GetAllCollections } from "../../../utils/backend/Collection/GetAllCollections";
import { Collection as CollectionType } from "../../../utils/types/collection";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Loader from "../../Loader/Loader";
import ErrorPage from "../../ErrorPage/ErrorPage";
import NoCollectionsFound from "../../NoCollections/NoCollectionsFound";
import { setShouldBlockKeyboardNavigation } from "../../../store/slice/navigationSlice";

const Collections = () => {
	const [collections, setCollections] = useState<CollectionType[]>([]);
	const [error, setError] = useState<string | undefined>("");
	const boardId = useAppSelector((state) => state.navigation.boardId);
	const disaptch = useAppDispatch();
	const [isNewCreated, setIsNewCreated] = useState<boolean>(false);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (boardId) {
			const init = function () {
				try {
					handleGetData();
				} catch (e) {
					setError(
						"Something unexpected happened, try to reload page or contact us."
					);
					console.error(e);
				}
			};

			const handleGetData = async function () {
				try {
					const result = await GetAllCollections(signal);
					if (result.error.length > 0) {
						setError(
							"Something unexpected happened, try to reload page or contact us."
						);
						console.error(result.error);
						setIsLoading(false);
						return;
					}

					setCollections(
						result.response.sort((a: CollectionType, b: CollectionType) => {
							if (a.name > b.name) return 1;
							return -1;
						})
					);

					disaptch(setShouldBlockKeyboardNavigation(false));
					setIsLoading(false);
				} catch (e) {
					setError(
						"Something unexpected happened, try to reload page or contact us."
					);
					console.error(e);
					setIsLoading(false);
				}
			};

			init();

			return () => {
				controller.abort();
			};
		}
	}, [boardId, isNewCreated, setIsNewCreated]);

	const createCollection = () => {
		setIsNewCreated(true);
		disaptch(setShouldBlockKeyboardNavigation(false));
	};

	if (error?.length === 0 && collections.length === 0 && !isLoading) {
		return <NoCollectionsFound createCollection={createCollection} />;
	}

	if (isLoading) {
		return <Loader />;
	}

	if (error && error.length > 0) {
		return <ErrorPage />;
	}

	return <CollectionsDisplay collections={collections} error={error} />;
};

interface CollectionListProps {
	collections: CollectionType[];
	error?: string;
}

const CollectionsDisplay = ({ error, collections }: CollectionListProps) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<div className={`${lightMode} companies`}>
			<div className={`${lightMode} companies-grid`}>
				{error
					? error
					: collections.map((collection) => (
							<Collection collection={collection} key={collection.id} />
					  ))}
			</div>
		</div>
	);
};

export default Collections;
