import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface Nav {
	projectId: String;
	boardId: string;
	isDynamicOpen: boolean;
	isFilterSideBarOpen: boolean;
	isPreviewScreenOpen: boolean;
	history: Array<string>;
	browserBackButton: boolean;
	shouldBlockKeyboardNavigation: boolean;
	lightMode: string;
	shouldDisableFilters: boolean;
}

// Define the initial state using that type
const initialState: Nav = {
	projectId: "",
	boardId: "",
	isDynamicOpen: false,
	isFilterSideBarOpen: false,
	isPreviewScreenOpen: false,
	history: [],
	browserBackButton: false,
	shouldBlockKeyboardNavigation: false,
	lightMode: localStorage.getItem("lightingMode") || "light",
	shouldDisableFilters: false,
};

export const navigationSlice = createSlice({
	name: "navigationSlice",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setLightMode: (state, action: PayloadAction<string>) => {
			state.lightMode = action.payload;
		},
		setBoardId: (state, action: PayloadAction<string>) => {
			state.boardId = action.payload;
		},
		setProjectId: (state, action: PayloadAction<String>) => {
			state.projectId = action.payload;
		},
		setDynamicIsOpen: (state, action: PayloadAction<boolean>) => {
			state.isDynamicOpen = action.payload;
		},
		setIsFilterSideBarOpen: (state, action: PayloadAction<boolean>) => {
			state.isFilterSideBarOpen = action.payload;
		},
		setIsPreviewScreenOpen: (state, action: PayloadAction<boolean>) => {
			state.isPreviewScreenOpen = action.payload;
		},
		historyPush: (state, action: PayloadAction<string>) => {
			state.history = [...state.history, action.payload];
		},
		historyPop: (state) => {
			state.history = state.history.slice(0, -1);
		},
		historyBackButtonHandle: (state) => {
			state.history =
				state.history[state.history.length - 1] === window.location.pathname
					? state.history.slice(0, -2)
					: [...state.history];
		},
		setShouldBlockKeyboardNavigation: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.shouldBlockKeyboardNavigation = action.payload;
		},
		setShouldDisableFilters: (state, action: PayloadAction<boolean>) => {
			state.shouldDisableFilters = action.payload;
		}
	},
});

export const {
	setBoardId,
	setProjectId,
	setDynamicIsOpen,
	setIsFilterSideBarOpen,
	setIsPreviewScreenOpen,
	historyPop,
	historyPush,
	historyBackButtonHandle,
	setShouldBlockKeyboardNavigation,
	setLightMode,
	setShouldDisableFilters
} = navigationSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default navigationSlice.reducer;
