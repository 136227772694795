import "./ErrorPage.css";
import Rotate from "../../icons/Rotate.svg";
import Cautions from "../../icons/Cautions.svg";
import CautionsDark from "../../icons/CautionsDark.svg";
import { useAppSelector } from "../../store/hooks";

const ErrorPage = () => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<div className={`${lightMode} error-page-container`}>
			<div className={`${lightMode} error-page-img-container`}>
				<img
					style={{ height: "20px", width: "20px" }}
					src={lightMode === "light" ? Cautions : CautionsDark}
				/>
			</div>
			<div className={`${lightMode} error-page-text`}>
				Oops! Something went wrong.
			</div>
			<div className={`${lightMode} error-page-subtext`}>
				Reloading UXMaps may fix this.
			</div>
			<button
				className={`${lightMode} error-page-button`}
				onClick={() => window.location.reload()}>
				<img src={Rotate} /> Reload
			</button>
		</div>
	);
};

export default ErrorPage;
