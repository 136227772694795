import { Fetch, FetchInput, Method } from "../../Fetch";
import { TagTypeWithTags, toTagTypesWithTags } from "../../types/tagType";

export interface FetchAllTagTypesResponse {
  response: TagTypeWithTags[];
  error: string;
}

export async function FetchAllTagTypesWithTags(
  signal?: AbortSignal
): Promise<FetchAllTagTypesResponse> {
  const FetchAllTagTypesURL = "/me/tag_type/all_with_tags";

  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchAllTagTypesURL,
      signal: signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          response: await toTagTypesWithTags(response.result),
          error: "",
        };
      }

      return {
        response: [],
        error: "Server side error",
      };
    } else {
      return {
        response: [],
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      response: [],
      error: "error",
    };
  }
}
