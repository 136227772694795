import { Fetch, FetchInput, Method } from "../../Fetch";

export interface SetupProfileRequest {
  role?: string;
  industry?: string;
  workspaceName?: string;
  website?: string;
  signal?: AbortSignal;
  workspaceImage?: string;
}

export interface SetupProfileResponse {
  error: string;
}

export async function FetchSetupProfile(
  request: SetupProfileRequest
): Promise<SetupProfileResponse> {
  const setupProfileURL = "/me/setup_profile";
  const requestBody = JSON.stringify({
    role: request.role,
    industry: request.industry,
    workspaceName: request.workspaceName,
    website: request.website,
    workspaceImage: request.workspaceImage,
  });
  try {
    const input: FetchInput = {
      method: Method.Post,
      url: setupProfileURL,
      signal: request.signal,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          error: "",
        };
      }

      return {
        error: "Server side error",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
    };
  }
}
