import { Fetch, FetchInput, Method } from "../../Fetch";
import { toUser, User } from "../../types/user";

export interface GetUserResponse {
  user?: User;
  error: string;
}

export async function GetUser(signal?: AbortSignal): Promise<GetUserResponse> {
  const GetUserURL = `/me/profile`;

  try {
    const input: FetchInput = {
      method: Method.Get,
      url: GetUserURL,
      signal: signal,
    };
    const result = await Fetch(input);
    if (result.isOk) {
      return {
        user: await toUser(result.result.result),
        error: "",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
    };
  }
}
