import LoaderArrow from "../../icons/LoaderArrow.svg";
import { useAppSelector } from "../../store/hooks";
import "./Loader.css";

const Loader = () => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			<center className="loader-center-comp">
				<div className={`${lightMode} loader-container`}>
					{/* <img className={`${lightMode} loader-logo-img`} src={LoaderArrow} />
				<br />
				<div id="progress-bar-container">
					<div className={`${lightMode} progress-bar-child progress`}></div>
					<div className={`${lightMode} progress-bar-child shrinker timelapse`}></div>
				</div> */}
					<div className={`${lightMode} spinner-loader`}>
						<iframe
							src="https://giphy.com/embed/fphXG8dDcRHVavls9o"
							width="22px"
							height="22px"
							frameBorder="0"
							className={`${lightMode} giphy-embed`}
							allowFullScreen></iframe>
					</div>
				</div>
			</center>
		</>
	);
};

export default Loader;
