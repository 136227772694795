import { store } from "../../../store/store";
import { Fetch, FetchInput, Method } from "../../Fetch";
import { Collection, toCollection } from "../../types/collection";

export interface GetCollectionByIdRequest {
  id: string;
  signal?: AbortSignal;
}

export interface GetCollectionByIdResponse {
  error: string;
  collection?: Collection;
}

export async function GetCollectionById(
  request: GetCollectionByIdRequest
): Promise<GetCollectionByIdResponse> {
  const state = store.getState()
  const boardId = state.navigation.boardId
  if (!boardId || boardId.length === 0) {
    return {
      error: "No board id selected",
    };
  }
  const GetCollectionByIdURL = `/me/collection?collectionId=${request.id}&boardId=${boardId}`;
  try {
    const input: FetchInput = {
      method: Method.Get,
      url: GetCollectionByIdURL,
      signal: request.signal,
    };
    const result = await Fetch(input);
    if (result.isOk) {
      const response = await result;
      if (response) {
        return {
          collection: await toCollection(response.result.result),
          error: "",
        };
      }
      return {
        error: "Server side error",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err: any) {
    return {
      error: err.message,
    };
  }
}
