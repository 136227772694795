import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NoResultsFound from "../../icons/NoResultsFound.svg";
import NoResultsFoundDark from "../../icons/NoResultsFoundDark.svg";
import "../NoResultsFoundPage/NoResultsFoundPage.css";
import search from "../../icons/SearchBlack.svg";
import plus from "../../icons/PlusWhite.svg";
import { boolean } from "joi";
import CreateCollectionModal from "./CreateCollectionModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface NoCollectionsFoundProps {
	createCollection: Function;
}

const NoCollectionsFound = ({ createCollection }: NoCollectionsFoundProps) => {
	const navigate = useNavigate();
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const isCreated = () => {
		setModalOpen(!modalOpen);
		createCollection();
	};
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			{modalOpen && (
				<CreateCollectionModal
					close={() => setModalOpen(!modalOpen)}
					created={isCreated}
				/>
			)}
			<div className={`${lightMode} no-results-container`}>
				<div className={`${lightMode} no-results-img-container`}>
					<img
						style={{ height: "20px", width: "20px" }}
						src={lightMode === "light" ? NoResultsFound : NoResultsFoundDark}
					/>
				</div>
				<div className={`${lightMode} no-results-text`}>No collections yet</div>
				<div className={`${lightMode} no-results-subtext`}>
					Explore the app and add your first examples to the collection.
				</div>
				<div className={`${lightMode} no-collection-btn-container`}>
					<button
						className={`${lightMode} no-collections-black`}
						onClick={(prevState) => setModalOpen(!modalOpen)}>
						<img src={plus} />
						Create collection
					</button>
					<button
						className={`${lightMode} no-collections-white`}
						onClick={() => navigate("/Navigation/Screens")}>
						<img src={search} />
						Explore examples
					</button>
				</div>
			</div>
		</>
	);
};

export default NoCollectionsFound;
