import { store } from "../../../store/store";
import { Fetch, FetchInput, Method } from "../../Fetch";
import { Screen, toScreenList } from "../../types/screen";

export interface FetchScreensByTagListGroupRequest {
  tags: string[];
  varsNumber: number;
  signal?: AbortSignal;
}

export interface FetchScreensByTagListGroupResponse {
  response: Map<string, Screen[]>;
  error: string;
}

export async function FetchScreensByTagListGroup(
  request: FetchScreensByTagListGroupRequest
): Promise<FetchScreensByTagListGroupResponse> {
  const state = store.getState()
  const projectId = state.navigation.projectId

  if (!projectId || projectId.length === 0) {
    return {
      response: new Map<string, Screen[]>(),
      error: "No project id selected",
    };
  }
  const FetchScreensByTagListURL = `/me/variations/group_by_tag_list?tagIdList=${request.tags}&noOfVariations=${request.varsNumber}&projectId=${projectId}`;

  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchScreensByTagListURL,
      signal: request.signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response && response.result && response.result.result) {
        const result = new Map<string, Screen[]>();

        for (const [key, value] of Object.entries(response.result.result)) {
          result.set(key, await toScreenList(value));
        }

        return {
          response: result,
          error: "",
        };
      }

      return {
        response: new Map<string, Screen[]>(),
        error: "Server side error",
      };
    } else {
      return {
        response: new Map<string, Screen[]>(),
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      response: new Map<string, Screen[]>(),
      error: "error",
    };
  }
}
