import { Fetch, FetchInput, Method } from "../../Fetch";
import { Company, toCompanyArray } from "../../types/company";

export interface FetchTagTypeRequest {
  tagType: String;
  signal?: AbortSignal;
}

export interface FetchTagTypeResponse {
  response?: Company;
  error: string;
}

export async function FetchTagType(
  request: FetchTagTypeRequest
): Promise<FetchTagTypeResponse> {
  const FetchTagTypeURL = `/me/tag_type/search?searchParam=${request.tagType}`;

  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchTagTypeURL,
      signal: request.signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      if (result) {
        const tagTypes = await toCompanyArray(result.result.result);
        if (tagTypes.length !== 1) {
          return {
            error: "not just one tag type returned",
          };
        }
        return {
          response: tagTypes[0],
          error: "",
        };
      }

      return {
        error: "Server side error",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
    };
  }
}
