import { Member, memberListSchema } from "./member";

import Joi from "joi";

export interface Workspace {
  id: string;
  name?: string;

  website?: string;
  image?: string | null;
  imageData?: string | null;
  industry: string;
  members: Member[];
}

export const workspaceSchema = Joi.object({
  id: Joi.string().required().uuid(),
  name: Joi.string().allow("", null).optional(),
  website: Joi.string().optional().allow("", null),
  image: Joi.string().allow("", null).optional(),
  industry: Joi.string().allow("", null).optional(),
  members: memberListSchema,
}).unknown();

export const workspaceListSchema = Joi.array().items(workspaceSchema);

export async function toWorkspaces(data: unknown): Promise<Workspace[]> {
  const validationResult = await workspaceListSchema.validate(data);
  if (validationResult.error) {
    throw new Error(validationResult.error.message);
  }

  return validationResult.value.map((data) => {
    return Object.assign({}, data) as Workspace;
  });
}
