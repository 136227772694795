import { Company as CompanyType } from "../../../utils/types/company";
import { Tag } from "../../../utils/types/tag";
import ErrorPage from "../../ErrorPage/ErrorPage";
import Filters from "../../FiltersComponent/Filters";
import { designElementDisplayData } from "../../design_elements/DesignElements/initializeData";
import { StyledGridDivSourcePage } from "./SourcePage.styles";
import Company from "../../companies/Company/Company";
import { useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";
import { useAppSelector } from "../../../store/hooks";
import NoResultsFoundPage from "../../NoResultsFoundPage/NoResultsFoundPage";

interface SourceListProps {
	source: CompanyType[];
	error?: string;
	noMoreData: boolean;
	isLoadingMore: boolean;
	tags: Tag[];
	onLoadMore(): void;
	elementsToDisplay: designElementDisplayData[];
	shouldDisplayFilters: boolean;
	pages: number;
	filteredSource: CompanyType[];
}

const SourceDisplay = ({
	error,
	source,
	noMoreData,
	onLoadMore,
	isLoadingMore,
	tags,
	shouldDisplayFilters,
	pages,
	filteredSource,
}: SourceListProps) => {
	const [pagesToDisplay, setPagesToDisplay] = useState(pages);
	const [innerWidth, setInnerWidth] = useState<number>(
		document.body.clientWidth
	);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const activeFilters = useAppSelector((state) => state.filters.activeFilters);

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [isLoadingMore]);

	const handleResize = () => {
		setInnerWidth(document.body.clientWidth);
	};

	const displayCompanies = () => {
		return filteredSource.map((company: CompanyType, index) => {
			if (index < (pages + 1) * 36)
				return <Company key={company.id} company={company} />;
		});
		// if (filteredSource.length > 0) {
		// 	return filteredSource.map((company: CompanyType, index) => {
		// 		if (index < (pages + 1) * 36)
		// 			return <Company key={company.id} company={company} />;
		// 	});
		// } else if (!window.location.search.includes("tagList=")) {
		// 	return source.map((company: CompanyType, index) => {
		// 		if (index < (pages + 1) * 36)
		// 			return <Company key={company.id} company={company} />;
		// 	});
		// } else {
		// 	return source.map((company: CompanyType, index) => {
		// 		if (index < (pages + 1) * 36)
		// 			return <Company key={company.id} company={company} />;
		// 	});
		// }
	};
	const loader = useRef(null);

	const loadMoreImages = async () => {
		onLoadMore();
	};

	const handleObserver = (entities: IntersectionObserverEntry[]) => {
		const target = entities[0];
		if (target.isIntersecting) {
			loadMoreImages();
		}
	};

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "20px",
			threshold: 1.0,
		};
		const observer = new IntersectionObserver(handleObserver, options);
		if (loader.current) {
			observer.observe(loader.current);
		}

		return () => {
			if (loader.current) {
				observer.unobserve(loader.current);
			}
		};
	}, []);

	if (error && error.length > 0) {
		return <ErrorPage />;
	}

	return (
		<>
			<div className={`${lightMode} companies`}>
			{!isLoadingMore && activeFilters.length > 0 && filteredSource.length === 0 && <NoResultsFoundPage />}
				<div
					className={`${lightMode} grid-container`}
					id="grid-container"
					style={shouldDisplayFilters ? { display: "flex" } : {}}>
					{isLoadingMore && <Loader />}
					<StyledGridDivSourcePage
						innerWidth={innerWidth}
						areFiltersOpen={shouldDisplayFilters}>
						{displayCompanies()}
					</StyledGridDivSourcePage>
					<Filters displaysFrom="source" />
				</div>
			</div>
			<div ref={loader} style={{ height: "10px" }}></div>
		</>
	);
};

export default SourceDisplay;
