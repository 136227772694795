import { Collection } from "../../../utils/types/collection";
import { GetAllCollections } from "../../../utils/backend/Collection/GetAllCollections";

interface getAllCollectionsInput {
  setError: (err: string) => void;
  setCollections: (data: Collection[]) => void;
}

export async function getAllCollections({
  setCollections,
  setError,
}: getAllCollectionsInput) {
  const controller = new AbortController();
  const signal = controller.signal;
  try {
    const result = await GetAllCollections(signal);
    if (result.error.length > 0) {
      setError(result.error);
      return;
    }

    setCollections(result.response);
  } catch (e) {
    setError(
      "Something unexpected happened, try to reload page or contact us."
    );
    console.error(e);
  }
}
