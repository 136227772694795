import "./CompanyItem.css";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { StyledScreenImage } from "../../common/ProductPage/ProductPage.styles";

const CompanyItem = (props: any) => {
	const [dropdownType, setDropdownType] = useState("");
	const [checkBoxActive, setCheckboxActive] = useState(false);
	const isOnElements = window.location.pathname.includes("/Design/Element");
	const areFiltersOpen = useAppSelector(
		(state) => state.filters.isFilterOpened
	);
	const [innerWidth, setInnerWidth] = useState<number>(
		document.documentElement.clientWidth
	);
	const handleResize = () => {
		setInnerWidth(document.documentElement.clientWidth);
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<div
			className={`${lightMode} 
				${
					props.onFlows === false
						? `${lightMode} item-${props.id} item company flows`
						: areFiltersOpen
						? `${lightMode} item-${props.id} item company with-filters`
						: `${lightMode} item-${props.id} item company`
				}
			`}
			id={props.id}
			onClick={props.handleClick}>
			{props.displayCheckbox && (
				<input
					type="checkbox"
					className={`${lightMode} checkbox elements`}
					onClick={(e) => {
						setCheckboxActive((prev) => !prev);
						e.stopPropagation();
						props.selectingScreens(props.id);
					}}
				/>
			)}
			<StyledScreenImage
				style={{
					backgroundImage: `url(${props.image})`,
				}}
				innerWidth={innerWidth}
				areFiltersOpen={areFiltersOpen}
				className={`${lightMode}
					${
						props.onFlows === false
							? "screen-image flows"
							: areFiltersOpen
							? "screen-image with-filters"
							: "screen-image"
					}
				`}
			/>

			<div className={`${lightMode} screen-info`}>
				{props.company && (
					<img
						src={props.company.imageS3URL}
						className={`${lightMode} flow-icon`}
					/>
				)}
				<div className={`${lightMode} screen-text design-element`}>
					<h2>{props.name}</h2>
					{props.company && (
						<h3 className={`${lightMode} screen-company`}>
							{props.company.name}
						</h3>
					)}
				</div>
			</div>
		</div>
	);
};
export default CompanyItem;
