import React, { useEffect, useRef } from "react";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";

const Sidebar = () => {
	const [active, setActive] = React.useState("");
	const navigate = useNavigate();

	useEffect(() => {
		if (window.location.pathname.includes("BoardSettings")) {
			setActive("board");
		} else if (window.location.pathname.includes("ProfileSettings")) {
			setActive("profile");
		}
	}, [window.location]);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			<div className={`${lightMode} sidebar-container`}>
				<div style={{ color: lightMode === "light" ? "#475467" : "#fff" }}>
					<FontAwesomeIcon
						style={{
							margin: 0,
							paddingRight: "12px",
							color: lightMode === "light" ? "black" : "white",
						}}
						icon={faGear}
					/>{" "}
					Settings
				</div>
				<div className={`${lightMode} element-container`}>
					<div
						onClick={() => navigate("/Navigation/BoardSettings")}
						className={`${lightMode} ${
							active === "board" ? "element active" : "element inactive"
						}`}>
						Board
					</div>
					<div
						onClick={() => navigate("/Navigation/ProfileSettings")}
						className={`
							${lightMode}
							${active === "profile" ? "element active" : "element inactive"}`}>
						Profile
					</div>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
