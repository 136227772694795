import "./Flow.css";
import defaultImg from "../../../icons/image-loader.png";
import { Flow as FlowType } from "../../../utils/types/flow";
import { StyledScreenImage } from "../../common/ProductPage/ProductPage.styles";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";

interface FlowProps {
	flow: FlowType;
	image?: string;
}

const Flow = ({ flow, image }: FlowProps) => {
	const [innerWidth, setInnerWidth] = useState<number>(
		document.body.clientWidth
	);
	const areFiltersOpen = useAppSelector(
		(state) => state.filters.isFilterOpened
	);
	const navigate = useNavigate();
	const handleResize = () => {
		setInnerWidth(document.body.clientWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const lightMode = useAppSelector((state) => state.navigation.lightMode);

	return (
		<a
			className={`${lightMode} flow-link`}
			onClick={() =>
				navigate(
					`/Navigation/Flows/FlowInfo?flow-id=${flow.id}&flow-name=${flow.name}`
				)
			}>
			<StyledScreenImage
				innerWidth={innerWidth}
				areFiltersOpen={areFiltersOpen}
				style={{
					backgroundImage: `url(${image ? image : defaultImg})`,
				}}
				className={`screen-image flow-image-${flow.id} flow`}
			/>
			<div className={`${lightMode} flow-info`}>
				<img src={flow.icon} alt="" className={`${lightMode} flow-icon`} />
				<div className={`${lightMode} flow-text`}>
					<h3 className={`${lightMode} flow-name`}>{flow.name}</h3>
					<h3 className={`${lightMode} flow-company`}>{flow.company}</h3>
				</div>
			</div>
		</a>
	);
};
export default Flow;
