import Joi from "joi";
import { Screen, screenListSchema } from "./screen";
import { Tag, tagListScheme } from "./tag";

export interface Flow {
	approved: boolean;
	contentList: Screen[];
	createdAt?: string;
	description?: string;
	id: string;
	name: string;
	tagTypeId: string;
	tagList: Tag[];
	updatedAt?: string;
	icon?: string;
	company?: string;
}

const flowSchema = Joi.object({
	id: Joi.string().required().uuid(),
	name: Joi.string().required(),
	contentList: screenListSchema,
	approved: Joi.boolean().required(),
	createdAt: Joi.string().required(),
	description: Joi.string().optional().allow("", null),
	tagList: tagListScheme,
	updatedAt: Joi.date(),
	icon: Joi.string(),
	company: Joi.string(),
}).unknown();

const flowListSchema = Joi.array().items(flowSchema);

export async function toFlowArray(data: any[]): Promise<Flow[]> {
	const validationResult = await flowListSchema.validate(data);
	if (validationResult.error) {
		throw new Error(validationResult.error.message);
	}

	return validationResult.value.map((d) => {
		return Object.assign({}, d) as Flow;
	});
}

export async function toFlow(data: any): Promise<Flow> {
	const validationResult = await flowSchema.validate(data);
	if (validationResult.error) {
		throw new Error(validationResult.error.message);
	}

	return Object.assign({}, data) as Flow;
}
